import { useState } from 'react'
import './style.css'
import Allpayemnt from './paymennttabs/allpayemnt'
import Processing  from './paymennttabs/processing'
import  Paid   from './paymennttabs/paid';
import Failed from './paymennttabs/Failed';



const Paymenthistory = ({singleUserPayment,singleUserPaymentloading,singleUser,singleuserwithdraws,singleuserloading,pages,currentPage}) => {
  
    const [activeTab, setActiveTab] = useState('All');
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
    const totalWithdrawn = singleuserwithdraws.reduce((total, withdrawal) => total + withdrawal.amount, 0);
    const totalBalance = singleUser?.wallet?.totalBalance || 0;
      console.log(" totalBalance",totalBalance);
    const aviablefund=totalWithdrawn+totalBalance;
      console.log("aviablefund",aviablefund);

  return (
    <div className='flex flex-col space-y-10'>
    <div className='flex flex-col lg:flex-row gap-3'>
    <div className='w-full lg:w-2/5 bg-lightgary rounded-[10px] px-3 py-3 border'>
    <div className='flex flex-col space-y-1'>
        <p className='text-base  font-semibold'>Total Payment in Account:</p>
        <p className='text-primary font-semibold text-lg'>USD {singleUser?.wallet?.totalBalance} </p>
        <p className='py-4'>Zero withdrawal fees for up to 2 Wire Transfer withdrawals a month</p>
    </div>

</div>
  <div className='w-full lg:w-3/5 bg-pure rounded-[10px] px-3 py-3 border'>
            <div className='flex flex-col space-y-1 w-11/12'>
                <p>Available Funds to Withdraw</p>
            </div>
            <div className='w-full bg-lightgray h-4   bg-primary bg-opacity-25  rounded-[10px] mt-6 my-3'>
            <div
                            className='h-full   bg-primary  rounded-[10px]'
                            style={{ width: `${(totalWithdrawn / aviablefund) * 100}%` }}
                        />
            </div>
            <p className='text-right'>USD {totalWithdrawn}</p>

           
        </div>

    </div>


    {/* <div className='py-6 bg-white rounded-[10px]'>
<div className='grid grid-cols-2 md:grid-cols-5 gap-3 px-3'>
<div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
<p className='text-sm font-semibold'>Pending Validation </p>
<h1 className='text-primary text-lg font-bold'></h1>
</div>    
<div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
<p className='text-sm font-semibold'>Commission on Hold</p>
<h1 className='text-primary text-lg font-bold'>N/A</h1>
</div>  
<div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
<p className='text-sm font-semibold'>Pending Advertiser Collection</p>
<h1 className='text-primary text-lg font-bold'>N/A</h1>
</div>  
<div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
<p className='text-sm font-semibold'>Payout In Process</p>
<h1 className='text-primary text-lg font-bold'>N/A</h1>
</div>  
<div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
<p className='text-sm font-semibold'>Commission</p>
<h1 className='text-primary text-lg font-bold'>N/A</h1>
</div>  

</div>
</div> */}


<div className='flex  w-full items-center gap-3 bg-pure rounded-[10px] text-sm lg:text-base   h-[49px] px-3 '>
            <div className={`tab ${activeTab === 'All' ? 'active' : ''}`}
        onClick={() => handleTabClick('All')}>
            All
            </div>
            <div    className={`tab ${activeTab === 'Processing' ? 'active' : ''}`}
        onClick={() => handleTabClick('Processing')} >
            Processing         
               </div>
            <div  
               className={`tab ${activeTab === 'Paid' ? 'active' : ''}`}
               onClick={() => handleTabClick('Paid')}
                >
            Paid      
            </div>
            <div 
            className={`tab ${activeTab === 'Failed' ? 'active' : ''}`}
            onClick={() => handleTabClick('Failed')}
            >
            Failed
            </div>
          </div>

          <div>
          {activeTab === 'All' && <Allpayemnt    singleUserPayment={singleUserPayment}   singleUserPaymentloading={singleUserPaymentloading} pages={pages}  currentPage={currentPage}  />}
          {activeTab === 'Processing' && <Processing  singleuserwithdraws={singleuserwithdraws} singleuserloading={singleuserloading}   />}
          {activeTab === 'Paid' && <Paid     singleuserwithdraws={singleuserwithdraws} singleuserloading={singleuserloading}  />}
          {activeTab === 'Failed' && <Failed singleuserwithdraws={singleuserwithdraws} singleuserloading={singleuserloading}  />}
          </div>
        
    </div>
  )
}

export default Paymenthistory