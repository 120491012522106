import React, { useEffect ,useState } from 'react'
import Layout from '../../components/global/layout'
import Addcategory from '../../components/category/addcategory'
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { ShimmerCard, ShimmerTitle } from 'react-shimmer-effects'; 
import { getTotalCategories } from '../../redux/actions/categoryAction';
import CurrentTime from '../../components/global/currenttime'


const Category = () => {
  const dispatch=useDispatch();
  const { categories, createLoading, updateLoading,getLoading,error}=useSelector((state)=>state.category)
     const navigate=useNavigate();
  const handletoNavigate=()=>{
    navigate('/subcategory')
  }
  useEffect(() => {
    dispatch(getTotalCategories());
}, [dispatch]);
      if (getLoading) {
    return (
        <Layout>
            <div className='min-h-screen h-full px-4 pt-4'>
                <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
                    <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
                </div>
                <div className='w-full flex flex-col lg:flex-row mt-5 gap-10'>
                    <div className="w-full lg:w-2/4 h-[610px] bg-pure shadow-md rounded-[10px] overflow-x-scroll">
                        <div className="font-semibold text-xl px-3 py-3 w-full border-b">
                            <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
                        </div>
                        <ul className="py-2 flex flex-col space-y-3">
                            {[...Array(5)].map((_, index) => (
                                <li key={index} className="flex flex-col space-y-2 border-b">
                                    <div className="flex flex-row justify-center items-center gap-3 py-3 px-5">
                                        <div className='w-[10%] flex justify-center'>
                                            <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                                        </div>
                                        <div className='w-4/5 h-12 rounded-[10px] relative'>
                                            <ShimmerTitle width='100%' height='100%' className='rounded-[10px] h-full w-full' />
                                        </div>
                                        <div className='w-[10%] flex justify-center'>
                                            <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='w-full lg:w-2/4 h-auto bg-pure shadow-md rounded-[10px]'>
                        <div className='font-semibold text-xl px-3 py-3 border-b'>
                            <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
                        </div>
                        <div className='flex flex-col space-y-2 mt-5 px-4'>
                            <h1 className='text-sm pt-4'>
                                <ShimmerTitle width='50%' height='20px' className='rounded-[10px] w-full' />
                            </h1>
                            <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full mt-2' />
                            <div className='flex flex-col space-y-3 items-center py-4'>
                                <p>
                                    <ShimmerTitle width='50%' height='20px' className='rounded-[10px] w-full' />
                                </p>
                                <div>
                                    <div
                                        className='w-[100px] h-[100px] flex justify-center items-center border text-5xl rounded-md shadow-md cursor-pointer overflow-hidden'
                                    >
                                        <ShimmerTitle width='100px' height='100px' className='rounded-md' />
                                    </div>
                                    <input
                                        type='file'
                                        className='hidden'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
  };
  return (
    <Layout>
          <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-xl font-semibold'>Category</h1>
             <CurrentTime/>        
               </div>
               <div className='text-sm bg-primary text-pure text-center  py-2 w-[150px] rounded-lg cursor-pointer shadow-md ' onClick={handletoNavigate}>
               Sub Category
               </div>
         </div>
           <Addcategory  categories={categories} createLoading={createLoading}   updateLoading={updateLoading}  getLoading={getLoading}   error={error} />
         </div>
  
  
         </Layout>
  )
}

export default Category
