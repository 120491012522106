import { createSlice } from '@reduxjs/toolkit';
const SingleFlipShareSlice = createSlice({
    name: 'singleflipshare',
    initialState: {
        flipsharesingle: [],     
        updateLoading : false ,    
        loading: false, 
        error: null,
    },
    reducers: {
        setFlipsharesingle(state, action) {
            state.flipsharesingle = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        updateFlipsharesingle(state, action) {
            if (state.flipsharesingle._id === action.payload._id) {
                state.flipsharesingle = action.payload;
            }
        },  
        setUpdateLoading (state , action) {
            state.updateLoading = action.payload;
        } , 
        setError(state, action) {
            state.error = action.payload; 
        },
    },
});
export const { setFlipsharesingle,updateFlipsharesingle, setUpdateLoading,setLoading, setError } = SingleFlipShareSlice.actions;
export default SingleFlipShareSlice.reducer;