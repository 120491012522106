import React from 'react'
const Flipcampaignstatstotal = () => {
  return (
    
    <div className='py-6 bg-white mt-5 rounded-[10px]'>
    <div className='grid grid-cols-2 md:grid-cols-5 gap-3 px-3'>
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total campaign</p>
        <h1 className='text-primary text-lg font-bold'>N/A</h1>
     </div>    
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total campaign shared</p>
        <h1 className='text-primary text-lg font-bold'>N/A</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Invested</p>
        <h1 className='text-primary text-lg font-bold'>N/A</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Shared Bouns</p>
        <h1 className='text-primary text-xl font-bold'>N/A</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Admin Profit</p>
        <h1 className='text-primary text-xl font-bold'>N/A</h1>
     </div>  

    </div>
    </div>
  )
}

export default Flipcampaignstatstotal