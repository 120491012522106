import { createSlice } from '@reduxjs/toolkit';
const SingleCampaignSlice = createSlice({
    name: 'campaignsingle',
    initialState: {
        campaignsingle: {},         
        loading: false, 
        updateLoading : false ,
        error: null,
    },
    reducers: {
        setCampaignSingle(state, action) {
            state.campaignsingle = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        updateCampaignSingle(state, action) {
            if (state.campaignsingle._id === action.payload._id) {
                state.campaignsingle = action.payload;
            }
        },  
        setUpdateLoading (state , action) {
            state.updateLoading = action.payload;
        } ,      
        setError(state, action) {
            state.error = action.payload; 
        },
    },
});

export const { setCampaignSingle,updateCampaignSingle, setLoading,setUpdateLoading, setError } = SingleCampaignSlice.actions;
export default SingleCampaignSlice.reducer;