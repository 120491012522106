import Axios from "../../config/api";
import { format } from 'date-fns';
import { setAllUsers, setLoading, setError,setBlockUser,setBlockUserLoading,setCurrentPage,setPages,setAllUserPageSize,setSerachAllUser,setDateRangeAllUser,setPageSize } from '../reducers/alluserReducer';

export const fetchUsers = (page = 1) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        let url = `/user?page=${page}`;
      
        const { data: { data: { docs,pages, page: currentPage} } } = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setAllUsers(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setLoading(false));

    } catch (err) {
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    } finally {
        dispatch(setLoading(false));
    }
};
export const fetchBlock = (id ,data, toast) => async (dispatch , getState) => {

    try {
        dispatch(setBlockUserLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc,message } } } = await Axios.post(`/user/block-unblock/${id}` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setBlockUser(doc));
        dispatch(setBlockUserLoading(false));
        toast.success(message);
    } catch (err) {
        dispatch(setBlockUserLoading(false));
            console.log('Create category error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const fetchSizeUser = (pageSize) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/user?pageSize=${pageSize}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setAllUserPageSize(docs));
        dispatch(setLoading(false));
        dispatch(setPageSize(pageSize));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};

export const fetchUserDateRange  = (datestart,dateend) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/user?from=${datestart}&to=${dateend}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setDateRangeAllUser(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};


export const fetchUserSearch = (keyword) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs } } } = await Axios.get(`/user?keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        dispatch(setSerachAllUser(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};

