

import Axios from "../../config/api";
import {addCategory,removeCategory,setCategories, setGetLoading,setCreateLoading,setDeleteLoading,updateCategory,setUpdateLoading} from "../reducers/categoryReducer";
export const createCategory = (data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setCreateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.post(`/category` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(addCategory(doc));
        dispatch(setCreateLoading(false));
        toast.success('Category created successfully.');
    } catch (err) {
        dispatch(setCreateLoading(false));
            console.log('Create category error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}
export const updateCat = (id , data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`/category/${id}` , data  , 
        { headers : { Authorization : `Bearer ${token}`} });
        dispatch(updateCategory(doc));
        dispatch(setUpdateLoading(false));
        toast.success('Updated Successfully.');
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update Category error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const deleteCategory = (id , toast) => async ( dispatch , getState) => {
    try {
        dispatch(setDeleteLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`/category/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(removeCategory(id));
        dispatch(setDeleteLoading(false));
        toast.success('Deleted Successfully.');
    } catch (err) {
        dispatch(setDeleteLoading(false));
        console.log('Delete Category error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const getTotalCategories = (toast) => async (dispatch) => {
    try {
        dispatch(setGetLoading(true));
        const { data: { data: { docs } } } = await Axios.get('/category/total');
        dispatch(setCategories(docs));
        dispatch(setGetLoading(false));
    } catch (err) {
        dispatch(setGetLoading(false));      
          console.error('Get Total categories error:', err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
  
};
