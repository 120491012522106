import Axios from "../../config/api";
import { setSingleCampaign, setLoading, setError } from "../../redux/reducers/singleusercompaignsReducer";
export const fetchSingleUserCampaign = (id) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/compaign/user/${id}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setSingleCampaign(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}