import { createSlice } from '@reduxjs/toolkit';

const flipshareperformancestatSlice = createSlice({
    name: 'flipshareperformancestats',
    initialState: {
        flipshareperformance: [],  
        loading: false,
        error: null,
        pages: 1,
        currentPage: 1,
        pageSize:10,
    },
    reducers: {
        setFlipshareperformance(state, action) {
            state.flipshareperformance = action.payload; 
        },
        setDateRangeShareStats(state, action){
            state.flipshareperformance = action.payload; 
        },
        setPageSizeShareStats(state, action){
            state.flipshareperformance = action.payload; 
        },
        setSerachShareStats(state, action){
            state.flipshareperformance = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        },
        setPageSize(state, action){
            state.pageSize = action.payload;

        }
     },
});

export const { setFlipshareperformance, setLoading, setError,setCurrentPage,setPages,setDateRangeShareStats,setSerachShareStats,setPageSizeShareStats,setPageSize } = flipshareperformancestatSlice.actions;
export default flipshareperformancestatSlice.reducer;
