import { createSlice } from '@reduxjs/toolkit';

const settingSlice = createSlice({
    name: 'settings',
    initialState: {
        userSettings: {}, 
        settingsLoading: false,
        addUserLoading: null, 
        settingsError: null,
    },
    reducers: {
        setUserSettings(state, action) {
            state.userSettings = action.payload; 
        },
        addUserSetting(state, action) {
            state.userSettings = action.payload; 
        },
        setSettingsLoading(state, action) {
            state.settingsLoading = action.payload;
        },
        setAddUserLoading(state, action) {
            const { type, isLoading } = action.payload;
            state.addUserLoading = isLoading ? type : null;
        },
        setSettingsError(state, action) {
            state.settingsError = action.payload;
        },
    },
});

export const {   setUserSettings,  addUserSetting,   setSettingsLoading, setAddUserLoading, setSettingsError } = settingSlice.actions;
export default settingSlice.reducer;
