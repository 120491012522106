import { createSlice } from '@reduxjs/toolkit';

const allnotificationSlice = createSlice({
    name: 'Allnotification',
    initialState: {
        allnotifications: [], 
        allnotificationsloading: false, 
        createnotificationsloading:false,
        deleteloading:false,
        error: null,
    },
    reducers: {
        setAllNotifications(state, action) { 
            state.allnotifications = action.payload;
        },
        addNotifications(state, action) {
            state.allnotifications.unshift(action.payload);
        },

        removeNotifications(state , action) {
            state.allnotifications = state.allnotifications.filter(item => item._id !== action.payload);
        } ,
        setLoading(state, action) {
            state.allnotificationsloading = action.payload; 
        },
        setCreateNotificationsLoading(state, action) {
            state.createnotificationsloading = action.payload; 
        },

        setDeleteLoading(state, action) {
            state.deleteloading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload;
        },
    },
});

export const { setAllNotifications,addNotifications,removeNotifications, setCreateNotificationsLoading,setDeleteLoading, setLoading, setError } = allnotificationSlice.actions;
export default allnotificationSlice.reducer;
