import React,{useEffect} from 'react'
import cross from '../../assets/svgs/removepopup.svg'
import {fetchSingleCampaign} from '../../redux/actions/singlecompaigmsAction'
import { useDispatch,useSelector } from 'react-redux'
import calender from '../../assets/svgs/calendar.svg'
import { ClipLoader } from 'react-spinners'



const Flipcampaignsperfompopup = ({onClose,campaignId }) => {
  const dispatch = useDispatch();
   const { campaignsingle, loading, error } = useSelector((state) => state.singlecampaign);


   const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  const startDate = formatDate(campaignsingle.startDate);
  const endDate = formatDate(campaignsingle.endDate);
  const dateRange = `${startDate} - ${endDate}`;

   useEffect(() => {
    if (campaignId) {
        dispatch(fetchSingleCampaign(campaignId));
    }
}, [dispatch, campaignId]);



  
  return (
    <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50 '>
    <div className='w-full h-screen flex justify-center items-center  '>
      <div className='w-4/5 bg-[#F2F6FC] h-auto lg:h-[327px] rounded-md flex justify-center items-center relative'>
      <div className='absolute top-1 right-0  lg:top-3 lg:right-2  cursor-pointer  w-10 h-10  items-center '>
        <img src={cross} alt='img' onClick={onClose}/>
      </div>


      {

        loading  ?  (

          <div className='flex justify-center items-center w-full h-full'>
            <ClipLoader size={35} color='#51087E' />
            </div>


        ):(
          <div className='bg-pure w-full  lg:w-11/12 h-auto lg:h-[243px] rounded-md flex flex-col space-y-10 px-3 py-3'>
            <div className='flex flex-col lg:flex-row gap-3 w-full'>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>User Name</label>
                <input type='text' value={campaignsingle.user ? campaignsingle.user.username : 'N/A'}  className='border border-primary outline-none   h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Campaign Name</label>
                <input type='text' value={campaignsingle?.name }  className='border border-primary outline-none   h-12 rounded-md text-sm  px-3' readOnly/>
             </div>
            
             <div className='w-full lg:w-1/3 flex flex-col space-y-2 '>
               <label className='text-dark text-[15px] font-semibold'>Start & End Date</label>
               <div className=' border border-primary rounded-md w-full h-12  relative'>
               <input type='text' value={dateRange}  className=' outline-none rounded-md w-full  h-full    text-sm  px-3  ' readOnly />
               <div className='absolute inset-y-0 right-3 flex items-center'>
        <img src={calender} alt='Calendar icon' />
    </div>
               </div>
             </div>
           
            </div>
           <div className='grid grid-cols-2  lg:grid-cols-5 gap-3'>
           <div className='border bg-grayText px-3 py-3 flex flex-col space-y-3 rounded-[10px]'>
             <p className='text-[13px] font-semibold'>Shared Date</p>
             <h3 className='text-primary font-semibold text-xl'>{startDate}</h3>
           </div>
           <div className='border bg-grayText px-3 py-3 flex flex-col space-y-3 rounded-[10px]'>
             <p className='text-[13px] font-semibold'>Total Clicks</p>
             <h3 className='text-primary font-semibold text-xl'>{campaignsingle?.totalClicks}</h3>
           </div>
           <div className='border bg-grayText px-3 py-3 flex flex-col space-y-3 rounded-[10px]'>
             <p className='text-[13px] font-semibold'>Conversions </p>
             <h3 className='text-primary font-semibold text-xl'>{campaignsingle?.totalShares}</h3>
           </div>
           <div className='border bg-grayText px-3 py-3 flex flex-col space-y-3 rounded-[10px]'>
             <p className='text-[13px] font-semibold'>Shared Bonus</p>
             <h3 className='text-primary font-semibold text-xl'>                                
             ${campaignsingle?.totalShares * 10 || 'N/A'}
</h3>
           </div>
          
           <div className='border bg-grayText px-3 py-3 flex flex-col space-y-3 rounded-[10px]'>
             <p className='text-[13px] font-semibold'>Admin Profit</p>
             <h3 className='text-primary font-semibold text-xl'>N/A</h3>
           </div>

           </div>
        </div>
        )
      }
        
      </div>
  </div>
</div>
  )
}

export default Flipcampaignsperfompopup
