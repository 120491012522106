import Axios from "../../config/api";
import {setAllNotifications,addNotifications,removeNotifications,setDeleteLoading,setCreateNotificationsLoading, setLoading, setError } from '../reducers/notificationReducer';

export const fetchNotification = () => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        const { data: { data: { docs } } } = await Axios.get(`/custom-notification`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setAllNotifications(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};


export const createNotification = (data, toast) => async (dispatch, getState) => {
    try {
      dispatch(setCreateNotificationsLoading(true));
      const { token } = getState().auth.user;
      const { data : { data : { doc } } }    = await Axios.post('/custom-notification', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(addNotifications(doc));
      dispatch(setCreateNotificationsLoading(false));
      toast.success('Send Notification successfully.');
    } catch (err) {
      dispatch(setCreateNotificationsLoading(false));
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
  };
  export const deleteNotification = (id , toast) => async ( dispatch , getState) => {
    try {
        dispatch(setDeleteLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`/custom-notification/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(removeNotifications(id));
        dispatch(setDeleteLoading(false));
        toast.success('Deleted Successfully.');
    } catch (err) {
        dispatch(setDeleteLoading(false));
        console.log('Delete Category error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}