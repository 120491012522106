import { createSlice } from '@reduxjs/toolkit';

const allticketSlice = createSlice({
    name: 'alltickets',
    initialState: {
        alltickets: [],
        singletickets: {}, 
        ticketmessage: [], 
        ticketmessageLoading: false,
        postmessage: {},
        postmessageLoading: false,
        allticketsLoading: false,  
        singleticketLoading: false,
        error: null,
    },
    reducers: {
        setAllTickets(state, action) {  
            state.alltickets = action.payload;
        },
        setSerachAllTickets(state, action){
            state.alltickets = action.payload; 
        },
        setDateRangeAllTickets(state, action){
            state.alltickets = action.payload; 
        },
        setSingleTickets(state, action){
            state.singletickets = action.payload;
        },
        setTicketMessage(state, action){
            state.ticketmessage = action.payload;
        },
        setPostMessage(state, action){
            state.postmessage = action.payload; 
        },
        setPostMessageLoading(state, action){
            state.postmessageLoading = action.payload;
        },
        setTicketMessageLoading(state, action) {
            state.ticketmessageLoading = action.payload === null ? false : action.payload;
          },
        setSingleTicketLoading(state, action){
            state.singleticketLoading = action.payload;
        },
        setLoading(state, action) {
            state.allticketsLoading = action.payload;  
        },
        setError(state, action) {
            state.error = action.payload;
        },
    },  
});

export const { setAllTickets, setSingleTickets, setSingleTicketLoading, setTicketMessage, setTicketMessageLoading, setLoading, setPostMessage, setPostMessageLoading, setError,setDateRangeAllTickets,setSerachAllTickets } = allticketSlice.actions;  
export default allticketSlice.reducer;
