import { createSlice } from '@reduxjs/toolkit';

const totalBillingPaymentSlice  = createSlice({
    name: 'totalbillingpayemnt',
    initialState: {
        totalpayemnt: [],
        loading: false,
        error: null,
        pageSize:'',



    },
    reducers: {
        setTotalpayemnt(state, action) {
            state.totalpayemnt = action.payload;
        }, 
        setLoading(state, action) {
            state.loading= action.payload;
        },
        setPageSize(state, action){
            state.pageSize = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },

    },
});
export const { setTotalpayemnt, setLoading, setError,setPageSize} = totalBillingPaymentSlice .actions;
export default totalBillingPaymentSlice .reducer;
