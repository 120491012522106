import Axios from "../../config/api";
import {setActiveUser,setLoading,setError,setActiveUserStats,setCurrentPage,setPages,setAllActivePageSize,setPageSize,setCountryActiveUser} from '../reducers/activeReducer';

export const fetchActiveUsers = (page = 1) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const response = await Axios.get(`/user/get-online-users?page=${page}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        const { data: { data: { docs,pages, page: currentPage,...userStats} } } = response; 
        dispatch(setActiveUser(docs));
        dispatch(setActiveUserStats(userStats));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        
        dispatch(setLoading(false));

    } catch (err) {
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    } 
};


export const fetchSizeActive = (pageSize) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/user/get-online-users?pageSize=${pageSize}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setAllActivePageSize(docs));
        dispatch(setPageSize(pageSize));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};


export const fetchCountryActive = (country) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/user/get-online-users?country=${country}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setCountryActiveUser(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};