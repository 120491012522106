import React from 'react'
import notification from '../../assets/svgs/notificatiomimage.svg'
import { MdDelete } from "react-icons/md";
import { baseURL } from '../../config/api';
import { useDispatch } from 'react-redux';
import {deleteNotification} from '../../redux/actions/notificationAction'
import { toast } from 'react-toastify';
import Img from '../../assets/png/img2.jpg'

const Notificationlist = ({allnotifications}) => {
  const dispatch=useDispatch()
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

   const handleDelete=(Id)=>{
    dispatch(deleteNotification(Id,toast));
   }
 
  
  return (
    <div className='w-full'>
       <div className='mt-5'>
          <h3  className='text-black text-opacity-65 font-semibold text-lg px-1'>Notification List</h3>
       </div>
   {/*  notification*/}
   <div className='flex flex-col space-y-5 mt-4'>
   {allnotifications.map((entry, index) => {

    const CreatedDate = formatDate(entry.createdAt);
       

    return(
      <div key={entry._id} className='bg-pure w-full flex flex-row py-4 px-6 gap-3 rounded-[10px] shadow-md' >
            <div className=''>
              <div className='w-[98px] h-[94px] overflow-hidden'>
                <img src={`${baseURL}/${entry.image}`}
                onError={(e) => { e.target.onerror = null; e.target.src = Img; }}
                 alt='img' className='w-full h-full object-cover'  />
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-col'>
                <div className='flex flex-col sm:flex-row justify-between'>
                  <div className='text-base font-semibold order-3 sm:order-1'>{entry.name}</div>
                  <div className='text-[15px] font-semibold order-2'>{entry.type}</div>
                  <div className='flex flex-row items-center justify-between sm:justify-normal sm:gap-10 order-1 sm:order-3'>
                    <span className='text-sm text-black text-opacity-65'>{CreatedDate}</span>
                    <span className='text-danger text-3xl cursor-pointer' onClick={()=>handleDelete(entry._id)} ><MdDelete /></span>
                  </div>
                </div>
                <div className='flex flex-col space-y-2'>
                  <h2 className='text-sm text-primary font-semibold'>Notification Message</h2>
                  <div className='max-w-80 sm:max-w-full'>
                    <p className='text-sm lg:text-md'>{entry.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
          
   })}
   </div>
  

    </div>
     
  )
}

export default Notificationlist
