import React from 'react'

const Flipcampaignscard = ({statistics}) => {

   // const totalStatistics =
   // statistics.totalCampaigns +
   // statistics.totalRunningCount +
   // statistics.totalExpiredCount +
   // statistics.totalDeletedCount ;
   // const calculatePercentage = (count) => ((count / totalStatistics) * 100).toFixed(2) || 0;

   const RunningCount = statistics.totalCampaigns > 0 
   ? (statistics.totalRunningCount / statistics.totalCampaigns) * 100 
   : 0;

   const ExpiredCount = statistics.totalCampaigns > 0 
   ? (statistics.totalExpiredCount / statistics.totalCampaigns) * 100 
   : 0;

   const TotalDeleted = statistics.totalCampaigns > 0 
   ? (statistics.totalDeletedCount / statistics.totalCampaigns) * 100 
   : 0;


   // const WeekActiveUsers = activeuserstats.lifetimeActiveUsers > 0 
   // ? (activeuserstats.activeUsersThisWeek / activeuserstats.lifetimeActiveUsers) * 100 
   // : 0;


   // const CurrentActiveUsers = activeuserstats.lifetimeActiveUsers > 0 
   // ? (currentActiveUsers / activeuserstats.lifetimeActiveUsers) * 100 
   // : 0;


  return (
    <div className='w-full '>
    <div className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-4 py-4'>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-[#0060F0] shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-[#0060F0] text-sm font-semibold'>All Flip Campaign</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{statistics.totalCampaigns}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#0060F0] '>100%</span>
           </div>

        </div>

     </div>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-[#6900C7] shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-[#6900C7] text-sm font-semibold'>Flip Campaigns Running</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{statistics.totalRunningCount}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#00AC69] '>{RunningCount}%</span>
           </div>

        </div>

     </div>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-primary shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-primary text-sm font-semibold'>Flip Campaigns Ended</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{statistics.totalExpiredCount}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#00AC69] '>{ExpiredCount}%</span>
           </div>

        </div>

     </div>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-[#00AC69] shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-[#00AC69] text-sm font-semibold'>Flip Campaigns Deleted</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{statistics.totalDeletedCount}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#00AC69] '>{TotalDeleted}%</span>
           </div>

        </div>

     </div>
    </div>
 </div>
  )
}

export default Flipcampaignscard
