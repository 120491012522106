import Axios from "../../config/api";
import { setUserSettings, addUserSetting, setSettingsLoading,setAddUserLoading, setSettingsError } from '../reducers/settingReducer';

export const fetchSetting = () => async (dispatch, getState) => {
    try {
        dispatch(setSettingsLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { doc } } } = await Axios.get(`/setting`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setUserSettings(doc));
        dispatch(setSettingsLoading(false));
    } catch (err) {
        dispatch(setSettingsLoading(false));
        dispatch(setSettingsError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};

export const addSetting = (data, type, toast) => async (dispatch, getState) => {
    try {
        dispatch(setAddUserLoading({ type, isLoading: true }));
        const { token } = getState().auth.user;
        const { data: { data: { doc } } } = await Axios.post(`/setting`, data, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(addUserSetting(doc));
        dispatch(fetchSetting());
        toast.success('Submitted successfully.');
    } catch (err) {
        dispatch(setSettingsError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    } finally {
        dispatch(setAddUserLoading({ type, isLoading: false }));
    }
};