import React, { useState,useRef } from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import Searchbox from '../../components/global/searchbox'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import useToggleOption from '../../hooks/usetoggleoption';
import thumb from '../../assets/svgs/icon _thumbs-up_.svg'
import eye from '../../assets/svgs/eye.svg'
import view from '../../assets/svgs/view.svg'
import remove from '../../assets/svgs/delete.svg'
import useClickOutside from '../../utils/clickOutside';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/global/loader'
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import {fetchCompaignsSearch} from '../../redux/actions/compaignsAction'
import { useDispatch } from 'react-redux';

const Dashboardtable = ({campaigns,loading}) => {
  const dispatch=useDispatch();
  const [keyword, setKeyword] = useState('');
    const tableRef = useRef(null);
    const navigate=useNavigate();
 
    const [isDropdownOpen, toggleDropdown] = useToggleOption(false);
    useClickOutside(tableRef, () => handleDropdownToggle(false));
    const handleDropdownToggle = (index) => {
      toggleDropdown(index);
    };
    const handleToView = (userId) => {
      navigate(`/flipcampaigns/${userId}`)
    }
    const handleToStats = (value) => {
      navigate(`/flipcampaigns/stats/${value}`)
    }
    const sortedcompings = campaigns
    ? [...campaigns] 
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 4)
    : [];


    const handleSearch = async () => {
      await dispatch(fetchCompaignsSearch(keyword));
    };
  
  return (
    <>

      {    loading  ?  
      (    <div className='w-full  py-6'>
        <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
          <table className='table-auto w-full'>
            <thead className='border-b'>
              <tr>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
              </tr>
            </thead>
            <tbody>
              {Array(4).fill(null).map((_, index) => (
                <tr key={index}>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>)
      :
      ( <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-10' ref={tableRef}>
        <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
          <div className='flex flex-col space-y-1'>
            <h1 className='text-primary font-semibold text-lg'>New Flip Campaign</h1>
            <p className='text-primary text-base'>Details and history</p>
          </div>

          <div className='flex flex-row gap-3 ' >
            <div>

              <img src={Arrow} alt='img' />
            </div>
            <div>
              <img src={Printer} alt='img' />
            </div>
          </div>

        </div>

       <div className='w-full flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-end sm:items-center px-6 py-3'>
          <div>
          <Searchbox 
          keyword={keyword} 
          onSearch={handleSearch} 
          onChange={(e) => setKeyword(e.target.value)} 
        />
          </div>
        </div>
        <div className='w-full px-6 py-6  '>
          {
            loading ? (
              <Loader />
            ) : (
              <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
                <table className="  table-auto  w-full  ">
                  <thead className='border-b ' >
                    <tr>
                      <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
                        Sr
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        User Name
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        Flip Campaign
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        Total Shared
                      </th>

                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
                        Total Clicks
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
                        Total Budget
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sortedcompings.length > 0 ? (
                        sortedcompings.map((item, index) => (
                          <tr key={item._id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.user ? item.user.username : 'N/A'}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.totalShares}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.totalClicks}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.compaignBudget}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <div className='border border-primary flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={() => handleDropdownToggle(index)}>
                                <span><img src={setting} alt='img' /></span>
                                <span><img src={arrow} alt='img' /></span>
                                {isDropdownOpen === index && (
                                  <div className='bg-white h-auto py-1 px-3 left-[-5px] top-10 text-[15px] font-semibold rounded-[10px] absolute z-50' ref={tableRef}>
                                    <div className='flex flex-col space-y-[6px] w-full'>
                                      <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1' onClick={() => handleToView(item._id)}>
                                        <span><img src={view} alt='img' /></span>
                                        <span>View</span>
                                      </div>
                                      <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1' onClick={() => handleToStats(item._id)} >
                                        <span><img src={eye} alt='img' /></span>
                                        <span>Stats</span>
                                      </div>
                                      <div className='flex flex-row items-center gap-1 text-[#FF1400] px-2 py-1'>
                                        <span><img src={remove} alt='img' /></span>
                                        <span>Delete</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" className="px-6 py-4 text-center text-gray-500">No campaigns found</td>
                        </tr>

                      )

                    }
                  </tbody>
                </table>
              </div>
            )
          }


        </div>


  




      </div>) 
      }
        
    </>
  )
}

export default Dashboardtable
