import React, { useState, useRef, useEffect } from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import Searchbox from '../../components/global/searchbox'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import useToggleOption from '../../hooks/usetoggleoption';
import approve from '../../assets/svgs/approve.svg'
import eye from '../../assets/svgs/eye.svg'
import view from '../../assets/svgs/view.svg'
import remove from '../../assets/svgs/delete.svg'
import useClickOutside from '../../utils/clickOutside';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/global/loader'
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { setCurrentPage } from '../../redux/reducers/compaignsReducer';
import { useDispatch } from 'react-redux';
import { fetchCompaigns,fetchSizeCompaigns,fetchCompaignsSearch} from '../../redux/actions/compaignsAction';


const Flipcampaignstable = ({ campaigns, loading,currentPage,pages,onOpen,pageSize }) => {
  const dispatch=useDispatch();
  const navigate = useNavigate()
  const tableRef = useRef(null);
  const [isDropdownOpen, toggleDropdown] = useToggleOption(false);
  const [keyword, setKeyword] = useState('');

  //page Size states 

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(pageSize); 

  const options = [10, 25, 50, 100];
   
 
  
  useClickOutside(tableRef, () => handleDropdownToggle(false));
  const handleDropdownToggle = (index) => {
    toggleDropdown(index);
  };
  const handleToView = (userId) => {
    navigate(`/flipcampaigns/${userId}`)
  }
  const handleToStats = (value) => {
    navigate(`/flipcampaigns/stats/${value}`)
  }



//pagenation
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
        dispatch(fetchCompaigns(newPage));
        dispatch(setCurrentPage(newPage));
    }
};
const startIndex =  1;
//pagesize
const pageSizeDropdown = () => {
  setIsOpenDropdown(!isOpenDropdown);
};
const handleSelect = (number) => {
  dispatch(fetchSizeCompaigns(number));
  setSelectedNumber(number); 
  setIsOpenDropdown(false); 
};


//serach

const handleSearch = async () => {
  await dispatch(fetchCompaignsSearch(keyword));
};



  return (
    <>

      <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' ref={tableRef}>
        <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
          <div className='flex flex-col space-y-1'>
            <h1 className='text-primary font-semibold text-lg'>Flip Campaign</h1>
            <p className='text-primary text-base'>Details and history</p>
          </div>

          <div className='flex flex-row gap-3 ' >
            <div>

              <img src={Arrow} alt='img' />
            </div>
            <div>
              <img src={Printer} alt='img' />
            </div>
          </div>

        </div>

       <div className='w-full flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
          
        <div className="flex flex-row gap-3 items-center">
        <div className='border border-primary flex flex-row justify-between relative rounded-[10px] py-1 w-[75px] items-center'>
                <div className='px-2' onClick={pageSizeDropdown}>
                    {selectedNumber} 
                </div>
                <div className='text-2xl text-primary' onClick={pageSizeDropdown}>
                    <MdKeyboardArrowDown />
                </div>
                
                {isOpenDropdown && (
                    <div className='absolute bg-white border border-gray-300 rounded shadow-lg w-full top-10'>
                        {options.map((number) => (
                            <div
                                key={number}
                                className='px-4 py-2 cursor-pointer hover:bg-primary hover:text-white'
                                onClick={() =>  handleSelect(number)}>
                                {number}
                            </div>
                        ))}
                    </div>
                )}
            </div>
  <div className='text-sm text-primary'>
  entries per page
  </div>
</div>

          <div>
            <Searchbox  
           keyword={keyword} 
          onSearch={handleSearch} 
          onChange={(e) => setKeyword(e.target.value)} 
             />
          </div>
        </div>
        <div className='w-full px-6 py-6  '>
          {
            loading ? (
              <Loader />
            ) : (
              <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
                <table className="  table-auto  w-full  ">
                  <thead className='border-b ' >
                    <tr>
                      <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
                        Sr
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        User Name
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        Flip Campaign
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        Total Shared
                      </th>

                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
                        Total Clicks
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
                        Total Budget
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      campaigns.length > 0 ? (
                        campaigns.map((item, index) => (
                          <tr key={item._id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.user ? item.user.username : 'N/A'}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.totalShares}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.totalClicks}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.compaignBudget}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                              <div className='border border-primary flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer ' onClick={() => handleDropdownToggle(index)}>
                                <span><img src={setting} alt='img' /></span>
                                <span><img src={arrow} alt='img' /></span>
                                {isDropdownOpen === index && (
                                  <div className='bg-white h-auto py-1 px-3 left-0 top-10 text-[15px] font-semibold rounded-[10px] absolute z-50' ref={tableRef}>
                                    <div className='flex flex-col space-y-[6px] w-full'>
                                      <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1' onClick={() => handleToView(item._id)}>
                                        <span><img src={view} alt='img' /></span>
                                        <span>View</span>
                                      </div>
                                      <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1' onClick={() => handleToStats(item._id)} >
                                        <span><img src={eye} alt='img' /></span>
                                        <span>Stats</span>
                                      </div>
                                      <div className='flex flex-row items-center  text-sucess gap-0  px-2 py-1' onClick={()=>onOpen(item._id)} >
                                        <span><img src={approve} alt='img' /></span>
                                        <span>{item.status === 'running' ? 'Approved' : 'Approve'}</span>
                                       
                                      </div>
                                
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" className="px-6 py-4 text-center text-gray-500">No users found</td>
                        </tr>

                      )

                    }
                  </tbody>
                </table>
              </div>
            )
          }


        </div>



        <div className="flex flex-col space-y-2  sm:space-y-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3   ">
  
  <div className='text-primary'>
  Showing {startIndex} to {campaigns.length} of {campaigns.length} entries  
   </div>
  
 
   <div className='flex flex-row items-center  rounded-md border-0 sm:border '>
   <div className='flex flex-row border rounded-md sm:border-0'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
  </div>
</div>
            
          </div>

       



      </div>
    </>
  )
}

export default Flipcampaignstable

