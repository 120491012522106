import React,{useEffect, useState} from 'react'
import cross from '../../assets/svgs/removepopup.svg'
import calender from '../../assets/svgs/calendar.svg'
import { useDispatch,useSelector } from 'react-redux';
import { fetchSingleWithdraw,updateWithdraw } from '../../redux/actions/singlewithdrawAction';
import {fetchWithdraw} from '../../redux/actions/withdrawAction';
import { toast } from 'react-toastify';
import { PulseLoader,ClipLoader } from 'react-spinners';


const Widthdrawpopup = ({onClose,withdrawId}) => {
  const dispatch=useDispatch();
const {singlewithdraws,singleloading,updateloading,error}=useSelector((state) => state.singlewithdraw);
const [status , setStatus] = useState(singlewithdraws?.status);
const [description, setDescription] = useState(singlewithdraws?.description);
    useEffect(() => {
      if (withdrawId) {
          dispatch(fetchSingleWithdraw(withdrawId));
      }
  }, [dispatch, withdrawId]);
  useEffect(() => {
    if (singlewithdraws) {
      setStatus(singlewithdraws?.status);
      setDescription(singlewithdraws?.description);
    }
  }, [singlewithdraws]);

  const getAccountType = (accountType) => {
    switch (accountType) {
      case 1:
        return 'Bank Account';
      case 2:
        return 'Mobile Money';
      case 3:
        return 'Paypal';
      case 4:
        return 'Cash App';
      case 5:
        return 'Zelle';
      default:
        return 'Unknown'; 
    }
  };

  const accountTypeDescription = getAccountType(singlewithdraws?.accountType);
  const handleSubmit = async () => {
    const updatedData = { status, description };
    console.log('Updated Data:', updatedData); 
    try {
        await dispatch(updateWithdraw(withdrawId, updatedData, toast));
        onClose(); 
        dispatch(fetchWithdraw());
    } catch (err) {
        console.error('Update error:', err);
    }
}


  return (
    <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50 '>
    <div className='w-full h-screen flex justify-center items-center  '>
      <div className='w-4/5  bg-pure   lg:bg-[#F2F6FC] h-auto lg:h-[600px] rounded-md flex flex-col   lg:items-center relative '>
      {
        singleloading   ?

        (

          <div className='flex justify-center items-center w-full h-full'>
            <ClipLoader size={35} color='#51087E' />
            </div>
       
        ):
        (
           <>
          <div className='  flex w-full    justify-between py-2 lg:py-3 px-2 lg:px-4  cursor-pointer  '>
      <div className='font-semibold capitalize text-lg'>
    withdraw
      </div>
      <div>
      <img src={cross} alt='img' onClick={onClose}/>
      </div>

  </div>

 
       <div className='bg-pure  w-full  lg:w-11/12 h-[500px] lg:h-[500px] rounded-md flex flex-col space-y-4 px-3 py-0 pb-4 lg:py-2  overflow-auto lg:overflow-hidden    '>
            <div className='flex flex-col lg:flex-row gap-3 w-full'>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>User Name</label>
                <input type='text'                   value={singlewithdraws?.userName || 'N/A'}   className='border border-primary outline-none   h-10 lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Account Type</label>
                <input type='text' value={accountTypeDescription}  className='border border-primary outline-none  h-10 lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Account Name</label>
                <input type='text' value={singlewithdraws?.accountName}   className='border border-primary outline-none  h-10 lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
            
             
            </div>
            <div className='flex flex-col lg:flex-row gap-3 w-full'>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Account Number</label>
                <input type='number' value={singlewithdraws?.accountNo}     className='border border-primary outline-none  h-10 lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Bank Name </label>
                <input type='text' value={singlewithdraws?.bankName}   className='border border-primary outline-none h-10  lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Bank Iban</label>
                <input type='text' value={singlewithdraws?.bankIBAN}  className='border border-primary outline-none   h-10 lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>
           
            </div>
       
            <div className='flex flex-col lg:flex-row gap-3 w-full'>
            <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Country</label>
                <input type='text' value={singlewithdraws?.country}   className='border border-primary outline-none  h-10  lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>

             <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Withdraw Amount</label>
                <input type='text'  value={singlewithdraws?.amount}  className='border border-primary outline-none  h-10  lg:h-12 rounded-md text-sm  px-3'  readOnly/>
             </div>

                
             <div className="w-full lg:w-1/3     flex flex-col space-y-2">
  <label className="text-dark text-[15px] font-semibold">Select Status</label>
  <select className="border border-primary outline-none h-10 lg:h-12 rounded-md text-sm px-3" value={status}
    onChange={(e) => setStatus(e.target.value)}>
    <option value="pending">Pending</option>
    <option value="declined">Declined</option>
    <option value="completed">Complete</option>
  </select>
              </div>

         

             </div>
             <div className='flex flex-col lg:flex-row gap-3 w-full'>
            <div className='w-full lg:w-2/4 flex flex-col space-y-2'>
               <label className='text-dark text-[15px] font-semibold '>Description</label>
               <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="border border-primary outline-none rounded-md text-sm px-3 py-2 resize-none h-24"
          placeholder="Enter description here..."
        />
             </div>

             </div>

             <div className='flex justify-start  lg:justify-end w-full'>
               <button className='btn-primary w-full lg:w-20'  onClick={handleSubmit}>
                {  updateloading  ?    
                <PulseLoader size={10} color='#fff'/>
            :
            'Submit' } 
               </button>

             </div>
             
         
        </div>
      </>

        )

      }
   
       
      </div>
  </div>
</div>
  )
}

export default Widthdrawpopup