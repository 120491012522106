import React,{useEffect, useState} from 'react'
import Layout from '../../components/global/layout'
import Notificationlist from '../../components/notification/notificationlist'
import Notificationpopup from '../../components/notification/notificationpopup'
import usePopuptoggle from '../../hooks/popuptoggle'
import { useDispatch,useSelector } from 'react-redux'
import {fetchNotification} from '../../redux/actions/notificationAction'
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import CurrentTime from '../../components/global/currenttime';


const Notification = () => {

  const dispatch=useDispatch();
  const {  allnotifications,allnotificationsloading,createnotificationsloading}=useSelector((state)=>state.notification)
    const {IsPopupOpen,openPopup,closePopup}=usePopuptoggle();


    useEffect(()=>{
      dispatch(fetchNotification());
    },[dispatch])

    if (allnotificationsloading) {
      return (
        <Layout>
          <div className='min-h-screen h-full px-4 pt-4'>
            <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
              <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
            </div>
            <div className='flex flex-col space-y-3'>
              {[...Array(5)].map((_, index) => (
                <div key={index} className='bg-pure w-full flex flex-row py-4 px-6 gap-3 rounded-[10px] shadow-md'>
                  <div className='w-[98px] h-[94px] overflow-hidden'>
                    <ShimmerTitle width='100%' height='100%' className='rounded-[10px]' />
                  </div>
                  <div className='w-full flex flex-col space-y-3'>
                    <div className='flex flex-col sm:flex-row justify-between'>
                      <ShimmerTitle width='60%' height='20px' className='rounded-[10px]' />
                      <ShimmerTitle width='30%' height='20px' className='rounded-[10px]' />
                      <div className='flex flex-row items-center justify-between sm:justify-normal sm:gap-10'>
                        <ShimmerTitle width='20%' height='15px' className='rounded-[10px]' />
                        <ShimmerTitle width='30px' height='30px' className='rounded-full' />
                      </div>
                    </div>
                    <div className='flex flex-col space-y-2'>
                      <ShimmerTitle width='40%' height='20px' className='rounded-[10px]' />
                      <ShimmerTitle width='100%' height='40px' className='rounded-[10px]' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Layout>
      );
    }
  return (
     <Layout>
     {IsPopupOpen &&(<Notificationpopup onClose={closePopup} createnotificationsloading={createnotificationsloading}/>)}
         <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-xl font-semibold'>Notification</h1>
            <CurrentTime/>      
               </div>
               <div className='text-sm bg-primary text-pure text-center  py-2 w-[150px] rounded-lg cursor-pointer shadow-md ' onClick={openPopup} >
               Send Notification
               </div>
         </div>
         <Notificationlist   allnotifications={allnotifications}/>
         </div>
     </Layout>
  )
}
export default Notification
