import React from 'react'

const Activeusercard = ({activeuserstats,currentActiveUsers}) => {
 



   const MonthActiveUsers = activeuserstats.lifetimeActiveUsers > 0 
    ? (activeuserstats.activeUsersThisMonth / activeuserstats.lifetimeActiveUsers) * 100 
    : 0;

    const WeekActiveUsers = activeuserstats.lifetimeActiveUsers > 0 
    ? (activeuserstats.activeUsersThisWeek / activeuserstats.lifetimeActiveUsers) * 100 
    : 0;


    const CurrentActiveUsers = activeuserstats.lifetimeActiveUsers > 0 
    ? (currentActiveUsers / activeuserstats.lifetimeActiveUsers) * 100 
    : 0;


  return (
    <div className='w-full '>
    <div className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-4 py-4'>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-[#0060F0] shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-[#0060F0] text-sm font-semibold capitalize'>Current active user</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{currentActiveUsers}
        
           </h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#0060F0] '>{CurrentActiveUsers} %</span>
           </div>

        </div>

     </div>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-[#6900C7] shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-[#6900C7] text-sm font-semibold capitalize'>this week active user</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{activeuserstats.activeUsersThisWeek}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#00AC69] '>{WeekActiveUsers}%</span>
           </div>

        </div>

     </div>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-primary shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-primary text-sm font-semibold capitalize'>this month active user</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{activeuserstats.activeUsersThisMonth}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#00AC69] '>{MonthActiveUsers} %</span>
           </div>

        </div>

     </div>
     <div className='bg-pure rounded-[10px]  border-l-[5px] border-[#00AC69] shadow-md'>
        <div className='flex flex-col px-5 py-4 '>
           <p className='text-[#00AC69] text-sm font-semibold capitalize'>lifetime user</p>
           <h2 className='text-xl leading-[50px] font-semibold'>{activeuserstats.lifetimeActiveUsers}</h2>
           <div className='flex flex-row gap-2'>
              <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span>  
               <span className='text-sm text-[#00AC69] '> 100%</span>
           </div>

        </div>

     </div>
    </div>
 </div>
  )
}

export default Activeusercard
