import React, { useEffect, useState,useRef } from 'react';
import Layout from '../../components/global/layout';
import Dasboardcard from '../../components/home/dasboardcard';
import Dasboardchart from '../../components/home/dasboardchart';
import Dashboardtable from '../../components/home/dashboardtable';
import Dashboardflipcompaigns from '../../components/home/dashboardflipcompaigns'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompaigns, fetchCompaignsDateRange } from '../../redux/actions/compaignsAction';
import CurrentTime from '../../components/global/currenttime';
import { fetchTotalDashboard } from '../../redux/actions/dashboardAction';
import { ShimmerTitle } from 'react-shimmer-effects';
import usePopuptoggle from '../../hooks/popuptoggle';
import DateRangePopup from '../../components/global/DateRangePopup';
import useToggle from '../../hooks/useToggle'
import useClickOutside from '../../utils/clickOutside';

const Home = () => {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const { campaigns, loading } = useSelector((state) => state.campaign);
    const { adminDashboard, adminLoading } = useSelector((state) => state.dashboard);
    const { IsPopupOpen, openPopup, closePopup} = usePopuptoggle();  



     //date range 
     const [showdropdown,handletoShow]=useToggle();
useClickOutside(dropdownRef, () => handletoShow(false));
    const [selectedOption, setSelectedOption] = useState('dateRange'); 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));
    const formatDateForAPI = (date) => {
        return date.toISOString().split('T')[0];
    };
    const formatDateForDisplay = (date) => {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };
    const handleDateRangeClick = () => {
        openPopup();
    };
    const handleApplyDateRange = () => {
        const formattedStartDate = formatDateForAPI(startDate);
        const formattedEndDate = formatDateForAPI(endDate);
        dispatch(fetchCompaignsDateRange(formattedStartDate, formattedEndDate));
        closePopup();
    };
    const handleSelection = (option) => {
        setSelectedOption(option);
        if (option === 'all') {
            dispatch(fetchCompaigns());
            handletoShow(false)
        }
        else if (option === 'dateRange') {
            openPopup(); 
            handletoShow(false)
        }
    };
    useEffect(() => {;
        dispatch(fetchCompaigns());
        dispatch(fetchTotalDashboard())
    }, [dispatch]);
      

    return (
        <>
            <Layout>
                <div className='min-h-screen h-full px-3'>
                    {loading ? (
                        <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
                            <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
                        </div>
                    ) : (
                        <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
                            <div className='flex flex-col py-4'>
                                <h1 className='text-lg font-semibold'>Dashboard</h1>
                                <CurrentTime />
                            </div>

                            <div  className='flex flex-row items-center bg-white gap-2 py-1 sm:py-2 px-3 w-[320px] sm:w-[384px] rounded-[10px] cursor-pointer relative ' >
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 2V5M16 2V5M20.75 17.6H3.25M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#51087E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z" fill="#51087E" />
                                    </svg>
                                </span>
                                            {
                                                selectedOption === 'all'  ?  (
                                                    <span className='text-[13px] lg:text-sm font-semibold'>
                                                    ALL
                                                    </span>
                                                ):
                                                (
                                        <span className='text-[13px] lg:text-sm font-semibold' onClick={handleDateRangeClick}>
                                        {formatDateForDisplay(startDate)} - {formatDateForDisplay(endDate)}
                                            </span>
                                                ) 
                                            }
                                <span className='ml-auto'  onClick={()=>handletoShow()} >
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.9401 6.71289L10.0501 11.6029C9.47256 12.1804 8.52756 12.1804 7.95006 11.6029L3.06006 6.71289" fill="black" />
                                    </svg>
                                </span>
                                {showdropdown  &&(
                                <div  ref={dropdownRef} className='border absolute w-full flex flex-col  bg-pure  top-9 sm:top-12 left-0 '>
                                <div className='text-[13px] lg:text-sm font-semibold w-full px-2 py-1 border-b   hover:bg-primary hover:text-white cursor-pointer'  onClick={() => handleSelection('dateRange')}>
                                    {`${formatDateForDisplay(startDate)} - ${formatDateForDisplay(endDate)}`}
                                </div>
                                <div className='text-[13px] lg:text-sm  font-semibold w-full px-2 py-1   hover:bg-primary hover:text-white cursor-pointer'  onClick={() => handleSelection('all')}>
                                    ALL
                                </div>
                                </div>
                            )}
                            </div>
                            {IsPopupOpen && (
                                <DateRangePopup 
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    onApply={handleApplyDateRange}
                                    onClose={closePopup}
                                />
                            )}
                 
                        </div>
                    )}

                    <Dasboardcard adminDashboard={adminDashboard} adminLoading={adminLoading} />
                    <Dasboardchart adminDashboard={adminDashboard} adminLoading={adminLoading} />
                    <Dashboardflipcompaigns campaigns={campaigns} loading={loading} />
                    <Dashboardtable campaigns={campaigns} loading={loading} />
                </div>
            </Layout>
        </>
    );
};

export default Home;
