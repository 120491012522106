import Axios from "../../config/api";
import { setTotalpayemnt, setLoading, setError, setPageSize } from '../reducers/totalbellingpaymentReducer.js';

export const fetchtotalpayment = (pageSize) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        
        let url = `/wallet-history?pageSize=${pageSize}`;
        const { data: { data: { docs, docsCount } } } = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });

        dispatch(setTotalpayemnt(docs));
        dispatch(setPageSize(docsCount)); 
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};
