import React,{useState,useEffect} from 'react'
import Imge from '../../assets/png/amico.png'
import Logo from '../../assets/svgs/relfliplogo.svg'
import { CiUser } from "react-icons/ci";
import PasswordSVG from '../../assets/svgs/PasswordSVG';
import { FaRegEyeSlash, FaRegEye  } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useToggle from '../../hooks/useToggle';
import { login } from '../../redux/actions/authActions';
import { PulseLoader } from 'react-spinners';

import './styles.css';
const Login = ({ setIsLoginPage }) => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [showPassword, togglePasswordVisibility] = useToggle(false);
    const { loading , user } = useSelector(state => state.auth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = e => {
        e.preventDefault();
        const data = { identifier: email, password: password };
           dispatch(login(data, navigate, toast));
    };
    useEffect(() => {
        setIsLoginPage(true);
        return () => setIsLoginPage(false);
    } , []);

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);
  return (
    <div className='w-full min-h-screen overflow-hidden  flex flex-row'>
    
    <div className=' w-[0px] md:w-[60%] xl:w-[70%] flex flex-col space-y-5 items-center '>
          <div className='w-full flex  justify-center  '>
                 <p className='text-primary text-[26px] font-semibold pt-6'>Reflip - Share, Earn, and Get Paid!</p>
               </div>
               <div className='w-1/2 ' >
                <p className='text-center text-[17px] '>Reflip is a revolutionary app designed to empower users by providing them with an opportunity to earn cash by simply sharing links to other social media platforms.</p>
              </div>
              <div className='w-3/5  flex justify-center  '>
                   <img src={Imge} alt='img'/>
              </div>
          </div>
   
       
          <div className='bg-[#D5D5D5] w-full md:w-[40%] xl:w-[30%]  relative flex items-center justify-center '>
          <div className='w-10/12 h-[90%] border border-white rounded-md backdrop-blur-lg bg-white/30 z-20 flex flex-col  space-y-2 '>
            <div className='w-full  flex justify-center'>
              <img src={Logo} alt='img' className='w-[50%] py-4'/>
            </div>
            <div className='flex flex-col items-center'>
              <h2 className='text-black text-[28px] font-semibold'>Hi , ADMIN</h2>
              <p className='text-primary'>Sign in to an Admin Account</p>

            </div>
            <div className='flex-1 pt-3'>
            <form className='flex flex-col gap-4 px-6' onSubmit={handleSubmit}>
              <div className='flex flex-col items-start gap-2'>
               <label className='text-dark font-semibold  '>Email / User Name</label>
               <div className='w-full h-[50px] relative'>
               <div className='absolute inset-y-0 left-3 flex items-center text-2xl text-dark text-opacity-40'>
              <CiUser /> 
                    </div>
               
               <input
                    type="email" 
                    id="email" 
               placeholder='Enter Email'
               value={email}
             onChange={e => setEmail(e.target.value)
             }
             required 
                className='w-full h-full rounded-[10px] px-10 outline-none'/>
               </div>
             
              </div>

              <div className='flex flex-col items-start gap-2'>
               <label className='text-dark font-semibold  '>Password</label>
               <div className='w-full h-[50px] relative'>
               <div className='absolute inset-y-0 left-3 flex items-center text-2xl text-dark text-opacity-40'>
              <PasswordSVG /> 
                    </div>
               
               <input
                         type={showPassword ? 'text' : 'password'}
                       id="password" 
               placeholder='8+ characters required'
                className='w-full h-full rounded-[10px] px-10 outline-none' 
                value={password}
                onChange={e => setPassword(e.target.value)}
                required 
                />
                   <div className='absolute inset-y-0 right-1 flex items-center text-2xl text-dark text-opacity-40'
                     onClick={() => togglePasswordVisibility()}>
                   {showPassword ? <FaRegEyeSlash />: <FaRegEye /> }
                    </div>
               </div>
            
             
              </div>

              <div className='flex items-center gap-2 text-dark font-semibold'>
                                <input id='remember' type="checkbox" />
                                <label htmlFor="remember">Remember me</label>
                            </div>
                            <button type='submit' className='bg-primary  py-2.5 text-pure uppercase font-semibold w-full text-center rounded-[10px] hover:bg-primaryHover' >
                               {  
                                loading ?   
                                <PulseLoader size={10} color='#fff'/>
                                 :'LOG IN'
                                 }
                                </button>
            </form>
            </div>
          </div>
            <div className='bg-primary absolute right-0 top-0 w-[101px] h-[101px]  rounded-full'>
            </div>
          
            <div className='bg-primary absolute left-2 bottom-0 w-32 h-32  rounded-full'>

            </div>

           </div>
    </div>
  )
}

export default Login