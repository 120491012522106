// src/components/Pay.js
import React, { useState } from 'react';
import Layout from '../../components/global/layout';

const Pay = () => {
  const itemName = 'FIREIMG';
  const itemPrice = 500;
  const [quantity, setQuantity] = useState(1);
  const [finalAmount, setFinalAmount] = useState(itemPrice);

  const decrement = () => {
    if (quantity <= 1) {
      setQuantity(1);
      setFinalAmount(itemPrice);
    } else {
      setQuantity(quantity - 1);
      setFinalAmount(finalAmount - itemPrice);
    }
  };

  const increment = () => {
    setQuantity(quantity + 1);
    setFinalAmount(finalAmount + itemPrice);
  };

  const checkout = async () => {
    try {
      const res = await fetch("http://localhost:8000/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          items: [
            {
              id: 1,
              quantity: quantity,
              price: itemPrice,
              name: itemName,
            },
          ],
        }),
      });
  
      const data = await res.json();
      if (data.url) {
        window.location = data.url;  
      } else {
        console.error('Error with checkout:', data.error);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };
  
  

  return (
    <Layout>
      <div className='w-full  flex flex-col space-y-2 px-3 py-4'>
        <div className='w-full'>
          <p>{itemName}</p>
        </div>
        <div>
          <p>Price: ${itemPrice}</p>
        </div>
        <div className='flex items-center space-x-4'>
          <button
            className='bg-red-500 text-white px-4 py-2 rounded'
            onClick={decrement}
          >
            Decrement
          </button>
          <span>Quantity: {quantity}</span>
          <button
            className='bg-green-500 text-white px-4 py-2 rounded'
            onClick={increment}
          >
            Increment
          </button>
        </div>
        <div>
          <p>Total Amount: ${finalAmount}</p>
        </div>
        <button
          className='bg-blue-500 text-white px-4 py-2 rounded'
          onClick={checkout}
        >
          Checkout
        </button>
      </div>
    </Layout>
  );
};

export default Pay;
