import React,{useEffect,useState} from 'react'
import { format, parseISO } from 'date-fns';
import calender from '../../assets/svgs/calendar.svg'

const Flipcampaignsperfomdaterange = ({campaigns,statistics}) => {
   const [dateRange, setDateRange] = useState({ minDate: '', maxDate: '' });

   const formatDate = (date) => {
     return format(date, 'dd/MM/yyyy');
   };
 
   useEffect(() => {
     if (campaigns && campaigns.length > 0) {
       const dates = campaigns.map(doc => new Date(doc.createdAt));
       if (dates.length > 0) {
         const min = new Date(Math.min(...dates));
         const max = new Date(Math.max(...dates));
         setDateRange({
           minDate: formatDate(min),
           maxDate: formatDate(max)
         });
       }
     }
   }, [campaigns]);
 
   const dateRangeString = `${dateRange.minDate} - ${dateRange.maxDate}`;


  return (
    <>
  <div className='w-full flex flex-col space-y-3 bg-pure px-3 py-6 rounded-[10px] my-5'>
      <h1>Date Range</h1>

      <div className='relative w-full md:w-2/4 lg:w-[330px] border border-primary h-10 rounded-lg flex items-center justify-between px-2'>
      <input
        type="text"
        className='rounded-[10px] w-full h-full outline-none px-2 text-dark'
        value={dateRangeString}
        readOnly
      />
      <div className='absolute inset-y-0 right-2 flex items-center'>
        <img src={calender} alt='Calendar' className='h-6 w-6' />
      </div>
    </div>
    </div>

    <div className='py-6 bg-white rounded-[10px]'>
    <div className='grid grid-cols-2 md:grid-cols-5 gap-3 px-3'>
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total campaign</p>
        <h1 className='text-primary text-lg font-bold'>{statistics.totalCampaigns}</h1>
     </div>    
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total campaign shared</p>
        <h1 className='text-primary text-lg font-bold'>{statistics.totalCampaignShares}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Invested</p>
        <h1 className='text-primary text-lg font-bold'>${statistics.totalInvested}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Shared Bouns</p>
        <h1 className='text-primary text-xl font-bold'>${statistics.totalSharedBouns}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Admin Profit</p>
        <h1 className='text-primary text-xl font-bold'>N/A</h1>
     </div>  

    </div>
    </div>
    </>
  )
}

export default Flipcampaignsperfomdaterange
