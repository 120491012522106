import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        adminDashboard: {}, 
        adminLoading: false, 
        error: null,
    },
    reducers: {
        setAdminDashboard(state, action) {
            state.adminDashboard = action.payload; 
        },
        setAdminLoading(state, action) {
            state.adminLoading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
    },
});

export const { setAdminDashboard, setAdminLoading, setError } = dashboardSlice.actions;
export default dashboardSlice.reducer;
