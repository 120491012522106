import Axios from "../../config/api";
import { format } from 'date-fns';
import { setWithdraw, setLoading, setError, setCurrentPage, setPages  } from '../reducers/withdrawReducer';

export const fetchWithdraw = (page = 1, startDate = null, endDate = null) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        let url = `/withdraw?page=${page}`;
        if (startDate && endDate) {
            const formattedStartDate = format(startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');
            url += `&from=${formattedStartDate}&to=${formattedEndDate}`;
        }
        const { data: { data: { docs, pages, page: currentPage } } } = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setWithdraw(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};
