import Axios from "../../config/api";
import { setAllTickets, setLoading, setSingleTickets,setSingleTicketLoading,setTicketMessageLoading,setTicketMessage , setPostMessage, setPostMessageLoading, setError,setDateRangeAllTickets,setSerachAllTickets } from '../reducers/ticketReducer';

export const fetchTickets = () => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        const { data: { data: { docs } } } = await Axios.get(`/ticket`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setAllTickets(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};


export const fetchTicketsDateRange  = (datestart,dateend) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/ticket?from=${datestart}&to=${dateend}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setDateRangeAllTickets(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};
export const fetchSingleTicket = (id) => async (dispatch, getState) => {
    try {
        dispatch(setSingleTicketLoading(true));
        const { token } = getState().auth.user;  
        const { data: { data: { doc } } } = await Axios.get(`/ticket/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setSingleTickets(doc));
        dispatch(setSingleTicketLoading(false));
    } catch (err) {
        dispatch(setSingleTicketLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};
export const fetchMassageTicket = (id) => async (dispatch, getState) => {
    try {
        dispatch(setTicketMessageLoading(true));
        const { token } = getState().auth.user;  
        const { data: { data: { docs } } } = await Axios.get(`/message/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setTicketMessage(docs));
        dispatch(setTicketMessageLoading(false));
    } catch (err) {
        dispatch(setTicketMessageLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }finally {
        dispatch(setTicketMessageLoading(false));
        console.log('ticketmessageLoading set to false');

      }
};
export const fetchSendMassage = (data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setPostMessageLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.post(`/message` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setPostMessage(doc));
        dispatch(setPostMessageLoading(false));
    } catch (err) {
        dispatch(setPostMessageLoading(false));
            console.log('msgs not send error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}



export const fetchTicketsSearch = (keyword) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs } } } = await Axios.get(`/ticket?keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        dispatch(setSerachAllTickets(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};