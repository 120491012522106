import React, { useState, useRef,useEffect } from 'react';
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg';
import Searchbox from '../../components/global/searchbox';
import arrow from '../../assets/svgs/tablearrow.svg';
import setting from '../../assets/svgs/tablesetting.svg';
import view from '../../assets/svgs/view.svg';
import remove from '../../assets/svgs/delete.svg';
import useClickOutside from '../../utils/clickOutside';
import useToggleOption from '../../hooks/usetoggleoption';
import Loader from '../../components/global/loader';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { setCurrentPage } from '../../redux/reducers/alluserReducer';
import { fetchUsers,fetchSizeUser,fetchUserSearch } from '../../redux/actions/alluserAction';

const Usertable = ({ allusers, allusersloading,onOpen,pages,currentPage,pageSize }) => {
     console.log('pagesize',pageSize )
  const dispatch =useDispatch();
  const tableRef = useRef(null);
  const [keyword, setKeyword] = useState('');
  //page size states 
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(pageSize); 

  const options = [10, 25, 50, 100];
   

//   



  const [isDropdownOpen, toggleDropdown] = useToggleOption(null);
  const navigate = useNavigate();
  useClickOutside(tableRef, () => handleDropdownToggle(null));
  const handleDropdownToggle = (index) => {
    toggleDropdown(index === isDropdownOpen ? null : index);
  };
  const handleViewClick = (userId) => {
    navigate(`/alluser/${userId}`);
  };
//pegnation
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
        dispatch(fetchUsers(newPage));
        dispatch(setCurrentPage(newPage));
    }
};
const startIndex =  1;
//page size

    
const pageSizeDropdown = () => {
  setIsOpenDropdown(!isOpenDropdown);
};

const handleSelect = (number) => {
  dispatch(fetchSizeUser(number));
  setSelectedNumber(number); 
  setIsOpenDropdown(false); 
  
};








//search
const handleSearch = async () => {
  await dispatch(fetchUserSearch(keyword));
};


  return (
    <>
      <div className='w-full bg-pure shadow-md rounded-[10px] my-7' ref={tableRef}>
        <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2'>
          <div className='flex flex-col space-y-1'>
            <h1 className='text-primary font-semibold text-lg'>All Users</h1>
            <p className='text-primary text-base'>Details and history</p>
          </div>

          <div className='flex flex-row gap-3'>
            <img src={Arrow} alt='Arrow' />
            <img src={Printer} alt='Printer' />
          </div>
        </div>

        <div className='w-full flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
          
              <div className="flex flex-row gap-3 items-center ">
        
              <div className='border border-primary flex flex-row justify-between relative rounded-[10px] py-1 w-[75px] items-center'>
                <div className='px-2' onClick={pageSizeDropdown}>
                    {selectedNumber} 
                </div>
                <div className='text-2xl text-primary' onClick={pageSizeDropdown}>
                    <MdKeyboardArrowDown />
                </div>
                
                {isOpenDropdown && (
                    <div className='absolute bg-white border border-gray-300 rounded shadow-lg w-full top-10'>
                        {options.map((number) => (
                            <div
                                key={number}
                                className='px-4 py-2 cursor-pointer hover:bg-primary hover:text-white'
                                onClick={() =>  handleSelect(number)}>
                                {number}
                            </div>
                        ))}
                    </div>
                )}
            </div>

    
          <div className='text-sm text-primary'>
          entries per page
          </div>
           </div> 

         <div>
            <Searchbox
                  keyword={keyword} 
                  onSearch={handleSearch} 
                  onChange={(e) => setKeyword(e.target.value)} 
              />
          </div>
        </div>

        <div className='w-full px-6 py-6'>
          {allusersloading ? (
            <Loader />
          ) : (
            <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
              <table className="table-auto w-full">
                <thead className='border-b'>
                  <tr>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Sr</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Name</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Contact No</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Email</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Flip Campaign</th>
                    <th className="text-base font-semibold px-6 py-4 text-center border-r">Flip Shared</th>
                    <th className="text-base font-semibold px-6 py-4 text-center border-r">Flip Referral</th>
                    <th className="text-base font-semibold px-6 py-4 text-center border-r">Earnings</th>
                    <th className="text-base font-semibold px-6 py-4 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allusers.length > 0 ? (
                    allusers.map((user, index) => {
                      return(
                        <tr key={user._id}      className={`${user.isActive ? '' : 'bg-red-900'}`}      >
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ${user.isActive ? '' : 'bg-red-200'} border-r border-gray-300`}>{ index + 1}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium  text-gray-900 ${user.isActive ? '' : 'bg-red-200'} border-r border-gray-300`}>{user.username}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium  text-gray-900 ${user.isActive ? '' : 'bg-red-200'}   border-r border-gray-300`}>{user.phone || 'N/A'}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ${user.isActive ? '' : 'bg-red-200'}  border-r border-gray-300`}>{user.email}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ${user.isActive ? '' : 'bg-red-200'}  border-r border-gray-300`}>{user.flipCompaignsCount}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium  text-gray-900 ${user.isActive ? '' : 'bg-red-200'}  border-r border-gray-300`}>{user.flipSharedCount}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium  text-gray-900 ${user.isActive ? '' : 'bg-red-200'}  border-r border-gray-300`}>{user.wallet.totalBalance}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium  text-gray-900 ${user.isActive ? '' : 'bg-red-200'}  border-r border-gray-300`}>{user.wallet.totalBalance}</td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ${user.isActive ? '' : 'bg-red-200'}`}>
                          <div
                            className='border border-primary flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative'
                            onClick={() => handleDropdownToggle(index)}
                          >
                            <span><img src={setting} alt='Settings' /></span>
                            <span><img src={arrow} alt='Toggle Dropdown' /></span>
                            {isDropdownOpen === index && (
                              <div
                                className='absolute top-5 w-[94px] h-auto -left-4 bg-pure rounded-md shadow-lg flex flex-col space-y-2 items-center py-3 z-50'
                                ref={tableRef}
                              >
                                <p className='text-primary cursor-pointer flex items-center font-semibold gap-1' onClick={() => handleViewClick(user._id)}>
                                  <span><img src={view} alt='View' /></span>
                                  <span>View</span>
                                </p>
                                <p className='text-danger cursor-pointer flex items-center font-semibold gap-1'  onClick={()=>onOpen(user._id )} > 
                                 {user.isActive  ?  'Block'  : 'unBlock'} 
                                </p>

                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      )
               
                  })
                  ) : (
                    <tr>
                      <td colSpan="9" className="px-6 py-4 text-center text-gray-500">No users found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-2  sm:space-y-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3   ">
  
  <div className='text-primary'>
  Showing {startIndex} to {allusers.length} of {allusers.length} entries   
   </div>
  
 
   <div className='flex flex-row items-center  rounded-md border-0 sm:border '>
   <div className='flex flex-row border rounded-md sm:border-0'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
  </div>
</div>
            
          </div>
       
      </div> 
      
    </> 
  );
};

export default Usertable;
