import { useState } from 'react';

const useToggleOptionPervstate = () => {
  const [showOption, setShowOption] = useState(false);

  const toggleOption = () => {
    setShowOption((prevState) => !prevState);
  };

  return [showOption, toggleOption];
};

export default useToggleOptionPervstate;
