import React,{useState,useEffect} from 'react'
import Layout from '../../components/global/layout'
import Usermagmenttable from '../../components/usermagment/usermagmenttable'
import Usermagmentpopup from '../../components/usermagment/usermagmentpopup'
import usePopuptoggle from '../../hooks/popuptoggle'
import { useDispatch,useSelector } from 'react-redux'
import {fetchAdmins} from '../../redux/actions/usermangmentAction'
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import CurrentTime from '../../components/global/currenttime'


const  Usermangment= () => {
  const dispatch=useDispatch();
  const {admins,adminManagementLoading}=useSelector((state)=>state.usermangment);
  const {IsPopupOpen,openPopup,closePopup}=usePopuptoggle();
    useEffect(()=>{
      dispatch(fetchAdmins())
    },[dispatch])


    if (adminManagementLoading) {
      return (
        <Layout>
        <div className='min-h-screen h-full px-4 pt-4 '>
        <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
      <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
    </div>
    
    
    
          <div className='w-full  py-6'>
            <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
              <table className='table-auto w-full'>
                <thead className='border-b'>
                  <tr>
                    <th className='shimmer px-6 py-4'></th>
                    <th className='shimmer px-6 py-4'></th>
                    <th className='shimmer px-6 py-4'></th>
                    <th className='shimmer px-6 py-4'></th>
                    <th className='shimmer px-6 py-4'></th>
                    <th className='shimmer px-6 py-4'></th>
                    
                  </tr>
                </thead>
                <tbody>
                  {Array(6).fill(null).map((_, index) => (
                    <tr key={index}>
                      <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle  />
                      </td>
                      <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle  />
                      </td>
                      <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle  />
                      </td>
                      <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle  />
                      </td>
                      <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle  />
                      </td>
                      <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle  />
                      </td>
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </Layout>
      );
    }
  return (
    <Layout>
    {IsPopupOpen &&  (<Usermagmentpopup onClose={closePopup} />)}
         <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-xl font-semibold'>User Management</h1>
              <CurrentTime/>        
               </div>
               <div className='text-sm bg-primary text-pure text-center  py-2 w-[150px] rounded-lg cursor-pointer shadow-md ' onClick={openPopup}  >
               Create New User
               </div>
         </div>
        <Usermagmenttable    admins={admins}    adminManagementLoading={adminManagementLoading}    />
         </div>
    </Layout>
   
  )
}

export default Usermangment
