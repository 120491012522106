import Axios from "../../config/api";
import { addSubCategory,removeSubCategory,updateSubCategory,setLoading,setCreateLoading,setUpdateLoading,setDeleteLoading,setSubCategory } from "../reducers/subcategoryReducer";

export const createSubCategory = (data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setCreateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.post(`/sub-category` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(addSubCategory(doc));
        dispatch(setCreateLoading(false));
        toast.success('subCategory created successfully.');
    } catch (err) {
        dispatch(setCreateLoading(false));
            console.log('Create category error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}
export const deleteSubCategory = (id, toast) => async (dispatch, getState) => {
    try {
        dispatch(setDeleteLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`/sub-category/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(removeSubCategory(id));
        dispatch(setDeleteLoading(false));
        toast.success('Deleted Successfully.');
    } catch (err) {
        dispatch(setDeleteLoading(false));
        console.log('Delete Category error', err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}
export const getTotalSubCategories = (toast) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { docs } } } = await Axios.get(`/sub-category/total`);
        dispatch(setSubCategory(docs));
        dispatch(setLoading(false));
        } catch (err) {
            dispatch(setLoading(false));
            console.log('Get Total categories error:' , err);
            toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
        }
    }