import Axios from "../../config/api";
import {  setAdmins,addAdmins, setAdminManagementLoading,setAddAdminManagementLoading, setremoveadminManagementLoading,removeAdminManagement, setError,setCurrentPage,setPages } from "../reducers/usermangmentReducer";
export const fetchAdmins = () => async (dispatch, getState) => {
    try {
        dispatch(setAdminManagementLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs,pages, page: currentPage } } } = await Axios.get(`/user?role=admin`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setAdmins(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setAdminManagementLoading(false));
    } catch (err) {
        dispatch(setAdminManagementLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};


export const fetchAddAdmins = (data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setAddAdminManagementLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.post(`/user/register` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(addAdmins(doc));
        dispatch(setAddAdminManagementLoading(false));
        toast.success('Admin created successfully.');
    } catch (err) {
        dispatch(setAddAdminManagementLoading(false));
            console.log('Admin error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const fetchRemoveAdmins = (id , toast) => async ( dispatch , getState) => {
    try {
        dispatch(setremoveadminManagementLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`/user/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(removeAdminManagement(id));
        dispatch(setremoveadminManagementLoading(false));
        toast.success('Deleted Successfully.');
    } catch (err) {
        dispatch(setremoveadminManagementLoading(false));
        console.log('Delete admin error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}