import Axios from "../../config/api";
import { setCampaignSingle,updateCampaignSingle,setUpdateLoading, setLoading, setError } from '../../redux/reducers/singlecompaigmsReducer';
export const fetchSingleCampaign = (id) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.get(`/compaign/${id}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setCampaignSingle(doc));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}


export const fetchupdateCampaignSingle = (id , data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`/compaign/${id}` , data  , 
        { headers : { Authorization : `Bearer ${token}`} });
        dispatch(updateCampaignSingle(doc));
        dispatch(setUpdateLoading(false));
        if (doc.status === 'running') {
            toast.success('Campaign approved.');
        } else {
            toast.success('Updated successfully.');
        }
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update  error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}