import { createSlice } from '@reduxjs/toolkit';

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        campaigns: [], 
        statistics: {},
        loading: false,        
        error: null, 
        pages: 1,
        currentPage: 1,
        pageSize:10,
        
    },
    reducers: {
        setCampaigns(state, action) {
            state.campaigns = action.payload; 
        },
        setStatistics(state, action) {
            state.statistics = action.payload; 
        },
        setPageSizeCompaigns(state, action){
            state.campaigns = action.payload; 
        },
        setDateRangeCompaigns(state, action){
            state.campaigns = action.payload; 
        },
        setSerachCompaigns(state, action){
            state.campaigns = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        },
        setPageSize(state, action){
            state.pageSize = action.payload;

        }
    },
});

export const {  setCampaigns, setStatistics, setLoading, setError,setCurrentPage,setPages,setPageSizeCompaigns,setDateRangeCompaigns,setSerachCompaigns,setPageSize } = campaignSlice.actions;
export default campaignSlice.reducer;
