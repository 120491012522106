import React,{useEffect} from 'react'
import Layout from '../../components/global/layout'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CurrentTime from '../../components/global/currenttime';
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import { fetchSingleFlipShare } from '../../redux/actions/singleflipshareAction';
import Flipshareviewform from '../../components/flipshareview/flipshareviewform';
import Btnback from '../../components/global/btnback';


const Flipshareview = () => {
    const { id } = useParams(); 
    const dispatch = useDispatch();
    const { flipsharesingle, loading,updateLoading,error } = useSelector((state) => state.singleflipshare);
    useEffect(()=>{
       dispatch(fetchSingleFlipShare(id));
    },[dispatch, id])
 
    if (loading) {
        return (
          <Layout>
            <div className="min-h-screen h-full px-4 pt-4 flex flex-col gap-4">
              <ShimmerTitle line={1} gap={20} variant="secondary" className="mb-4" />
              <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
                <ShimmerTitle line={2} gap={10} className="flex-1 mb-4 lg:mb-0" />
                <ShimmerThumbnail height={48} width={148} rounded className="lg:w-[148px] shadow-md" />
              </div>
              <div className="flex flex-col lg:flex-row w-full gap-3 h-auto lg:h-[158px]">
                <div className="flex items-center w-full lg:w-[70%]">
                  <ShimmerThumbnail height="100%" width="20%" rounded />
                  <div className="flex flex-col space-y-1 bg-pure w-[80%] h-full border rounded-[10px] px-3 pt-4 pb-4 lg:pt-6">
                    <ShimmerTitle line={3} gap={10} />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row w-full lg:w-[30%] gap-3">
                  <ShimmerThumbnail height="100%" width="100%" rounded />
                  <ShimmerThumbnail height="100%" width="100%" rounded />
                </div>
              </div>
              <div className="flex w-full items-center gap-3 bg-pure rounded-[10px] text-sm lg:text-base h-[49px] px-3">
                <ShimmerTitle line={1} className="flex-1" />
              </div>
            </div>
          </Layout>
        );
      }
      
      if (error) return <div>Error: {error}</div>;

  const earning =flipsharesingle?.compaign?.compaignBudget-flipsharesingle?.compaign?.budgetUsedInCompaign;

  return (
    <Layout>
             <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-lg font-semibold'>{flipsharesingle?.compaign?.name}</h1>
             <CurrentTime/>
                     </div>

                     <Btnback/>
            {/* <div className='flex flex-row bg-white gap-2 py-2 px-3  w-80 lg:w-auto rounded-[10px] cursor-pointer'>                 <span>                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                    <path d="M8 2V5M16 2V5M20.75 17.6H3.25M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#51087E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>                   <path d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z" fill="#51087E"/>                     </svg>                 </span>
                  <span className='text-[15px] font-semibold '>August 2, 2023 - August 2, 2023</span>
                  <span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.9401 6.71289L10.0501 11.6029C9.47256 12.1804 8.52756 12.1804 7.95006 11.6029L3.06006 6.71289" fill="black"/>
                     </svg>
                  </span>
               </div> */}
         </div>
         <div className='py-6 bg-white w-full rounded-[10px] mt-5'>
    <div className='grid grid-cols-2 md:grid-cols-6 gap-3 px-3'>
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Clicks </p>
        <h1 className='text-primary text-lg font-bold'>{flipsharesingle?.compaign?.totalClicks}</h1>
     </div>    
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Dead End Clicks</p>
        <h1 className='text-primary text-lg font-bold'>N/A</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
        <p className='text-sm font-semibold'>Total Conversions</p>
        <h1 className='text-primary text-lg font-bold'>{flipsharesingle?.compaign?.totalShares}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
        <p className='text-sm font-semibold'>Total Budget Used (USD)</p>
        <h1 className='text-primary text-lg font-bold'>{flipsharesingle?.compaign?.budgetUsedInCompaign}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
        <p className='text-sm font-semibold'>Total Estimated Earnings</p>
        <h1 className='text-primary text-lg font-bold '>{earning}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border'>
        <p className='text-sm font-semibold'>Admin Profit</p>
        <h1 className='text-primary text-lg font-bold '>N/A</h1>
     </div>  

    </div>
    </div>

    <Flipshareviewform  flipsharesingle={flipsharesingle}  updateLoading={updateLoading}/>


           
         </div>

    </Layout>
  )
}

export default Flipshareview