import { createSlice } from "@reduxjs/toolkit";

const singleUserCampaignSlice = createSlice({
    name: 'singleCampaign',
    initialState: {
        singlecampaign: {}, 
        loading: false,
        error: null
    },
    reducers: {
        setSingleCampaign(state, action) {
            state.singlecampaign = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
       
    }
});

export const { setSingleCampaign, setLoading, setError } = singleUserCampaignSlice.actions;
export default singleUserCampaignSlice.reducer;
