import React,{useState} from 'react'
import Dummy from '../../assets/svgs/imagedummy.svg'
import calender from '../../assets/svgs/calendar.svg'
import { baseURL } from '../../config/api'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { PulseLoader } from 'react-spinners';
import {fetchupdateSingleFlipShare} from '../../redux/actions/singleflipshareAction'
import Img from '../../assets/png/img2.jpg'

const Flipshareviewform = ({flipsharesingle,updateLoading}) => {
  const dispatch=useDispatch()
  const [description, setDescription] = useState(flipsharesingle?.compaign?.description || '');
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  const startDate = formatDate(flipsharesingle?.compaign?.startDate);
  const endDate = formatDate(flipsharesingle?.compaign?.endDate);
  const dateRange = `${startDate} - ${endDate}`;
  const handleUpdateDescription =  () => {
    const id = flipsharesingle._id;
    const updatedData = { description };
dispatch(fetchupdateSingleFlipShare(id, updatedData, toast));
  };
  return (
    <div className='w-full  mt-5'>
    <form className='w-full flex flex-col space-y-5'>
    <div className='flex flex-col  lg:flex-row gap-3   '>
    <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Campaign Name  </label>
       <input type='text' value={flipsharesingle?.compaign?.name} className='w-full py-4  rounded-[10px] outline-none px-3 border text-[13px] bg-[#FBFCFE] ' readOnly/>
    </div>
    <div className="w-full lg:w-1/2 flex flex-col space-y-2">
    <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Campaign Duration </label>
     <div className='w-full h-14 relative'>
     <input value={dateRange}  className='w-full h-full  rounded-[10px] outline-none px-3 border text-[13px] bg-[#FBFCFE] '/>
     <div className='absolute inset-y-0 right-3 flex items-center'>
        <img src={calender} alt='Calendar icon' />
    </div>
     </div>
   
</div>


    </div>
    
    <div  
        className='w-full  flex flex-col space-y-2 '>
    <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Description  </label>
    <textarea
    value={description}
   onChange={(e) => setDescription(e.target.value)}
      className='h-[151px] bg-[#FBFCFE] outline-none px-3  rounded-[10px] border text-black text-opacity-60 py-2 '>
    {flipsharesingle?.compaign?.description}
    </textarea>
    <div className='flex justify-end'>
          <button
          type='button' 
          onClick={handleUpdateDescription}
            className='bg-primary text-white px-4 py-2 rounded w-36'
          >
          {
            updateLoading  ?
            <PulseLoader size={10} color='#fff'/>
            :
            'Update'
          }
         
          </button>
        </div>
    </div>
    <div className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-4'>

    {flipsharesingle?.compaign?.images && Array.isArray(flipsharesingle?.compaign?.images) ? (
      flipsharesingle?.compaign?.images.map((image, index) => (
                <div key={index} className='h-44  rounded-[10px] overflow-hidden'>
                  <img
                    src={`${baseURL}/${image}`}
                    alt={`Campaign Image ${index + 1}`}
                    className='w-full h-full object-cover'
                    onError={(e) => { e.target.onerror = null; e.target.src = Img; }}

                  />
                </div>
              ))
            ) : (
              <p>No images available</p>
            )}
    </div>
    <div className='flex flex-col  lg:flex-row gap-3   '>
    <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Target Region  </label>
       <input type='text' value={flipsharesingle?.compaign?.targetCountry}  className='w-full py-4  rounded-[10px] outline-none px-3 border text-[13px] bg-[#FBFCFE] '/>
    </div>
    <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Select Budget  </label>
       <input type='text' value={flipsharesingle?.compaign?.compaignBudget}  className='w-full py-4  rounded-[10px] outline-none px-3 border text-[13px] bg-[#FBFCFE] '/>
    </div>
    </div>



    </form>
    </div>
  )
}

export default Flipshareviewform