import React,{useRef,useState} from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import Searchbox from '../../components/global/searchbox'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import useToggleOption from '../../hooks/usetoggleoption';
import eye from '../../assets/svgs/eye.svg'
import view from '../../assets/svgs/view.svg'
import remove from '../../assets/svgs/delete.svg'
import useClickOutside from '../../utils/clickOutside';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { setCurrentPage } from '../../redux/reducers/compaignsReducer';
import { fetchCompaigns,fetchCompaignsSearch,fetchSizeCompaigns } from '../../redux/actions/compaignsAction';
import { useDispatch } from 'react-redux';


const Flipcampaignsperfomtable = ({onopen,campaigns,loading ,pages,currentPage,pageSize}) => {
  const dispatch=useDispatch();
  const [keyword, setKeyword] = useState('');
    const tableRef = useRef(null);
    const [showOption,toggleOption]=useToggleOption();
    useClickOutside(tableRef, () => toggleOption(false));


    //page Size


    
  //page Size states 

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(pageSize); 

  const options = [10, 25, 50, 100];


 
    const handlePageChange = (newPage) => {
      if (newPage > 0 && newPage <= pages) {
          dispatch(fetchCompaigns(newPage));
          dispatch(setCurrentPage(newPage));
      }
  };
  const startIndex =  1;


  const handleSearch = async () => {
    await dispatch(fetchCompaignsSearch(keyword));
  };
  


  //pagesize
const pageSizeDropdown = () => {
  setIsOpenDropdown(!isOpenDropdown);
};
const handleSelect = (number) => {
  dispatch(fetchSizeCompaigns(number));
  setSelectedNumber(number); 
  setIsOpenDropdown(false); 
};


   

   


  return (
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
    <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
    <div className='flex flex-col space-y-1'>
    <h1 className='text-primary font-semibold text-lg'>Flip Campaigns Stats</h1>
    <p className='text-primary text-base'>Details and history</p>
    </div>

    <div className='flex flex-row gap-3 ' >
        <div>

        <img src={Arrow} alt='img'/>
        </div>
        <div>
         <img src={Printer} alt='img'/>
        </div> 
    </div>

    </div>

    <div className=' w-full flex flex-col gap-3 sm:gap-0  sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
    <div className='order-2 sm:order-1'>
    <div className='flex flex-row gap-2 items-center'>
    <div className='border border-primary flex flex-row justify-between relative rounded-[10px] py-1 w-[75px] items-center'>
                <div className='px-2' onClick={pageSizeDropdown}>
                    {selectedNumber} 
                </div>
                <div className='text-2xl text-primary' onClick={pageSizeDropdown}>
                    <MdKeyboardArrowDown />
                </div>
                
                {isOpenDropdown && (
                    <div className='absolute bg-white border border-gray-300 rounded shadow-lg w-full top-10'>
                        {options.map((number) => (
                            <div
                                key={number}
                                className='px-4 py-2 cursor-pointer hover:bg-primary hover:text-white'
                                onClick={() =>  handleSelect(number)}>
                                {number}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className='text-sm text-primary'>
          entries per page
          </div>



    </div>

    </div>
    <div className='order-1 sm:order-2'>
    <Searchbox 
      keyword={keyword} 
          onSearch={handleSearch} 
          onChange={(e) => setKeyword(e.target.value)} 
     />
    </div>

   
              
          
    </div>

      <div className='w-full px-6 py-6  '>
        <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
        <table className="  table-auto  w-full  ">
      <thead className='border-b ' >
          <tr>
              <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
              Sr
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              User Name
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Campaign Name
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Clicks              
                </th> 
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
              Conversions                 
              </th>
              
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
              Budget(USD)               
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
              Shared Bouns                 
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center   border-r">
              Admin Profit                 
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center">
              Action                 
              </th>
          </tr>
      </thead>
      <tbody>
              {campaigns.length > 0 ? (
                campaigns.map((campaign, index) => (
                  <tr key={campaign._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      {campaign.user ? campaign.user.username : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      {campaign.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      {campaign.totalClicks}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      {campaign.totalShares || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      ${campaign.budgetUsedInCompaign}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      ${campaign.totalShares * 10 || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                      N/A
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div className='border border-primary flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={() => toggleOption(index)}>
                        <span>
                          <img src={setting} alt='Settings' />
                        </span>
                        <span>
                          <img src={arrow} alt='Arrow' />
                        </span>
                        {showOption === index && (
                          <div className='bg-pure h-auto py-1 px-3 left-[-5px] top-10 text-[15px] font-semibold rounded-[10px] absolute z-50' ref={tableRef}>
                            <div className='flex flex-col space-y-[6px] w-full'>
                              <div className='flex flex-row items-center text-primary gap-1  px-2 py-1' onClick={() => onopen(campaign._id)}>
                                <span><img src={view} alt='View' /></span>
                                <span>View</span>
                              </div>
                           
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="px-6 py-4 text-center text-gray-500">No campaigns found</td>
                </tr>
              )}
            </tbody>

      </table>
        </div>
     
      </div>

      <div className="flex flex-col space-y-2  sm:space-y-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3   ">
  
  <div className='text-primary'>
  Showing {startIndex} to {campaigns.length} of {campaigns.length} entries  
   </div>
  
 
   <div className='flex flex-row items-center  rounded-md border-0 sm:border '>
   <div className='flex flex-row border rounded-md sm:border-0'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
  </div>
</div>
            
          </div>
    



   
  </div>
  )
}

export default Flipcampaignsperfomtable
