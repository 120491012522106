    import { createSlice } from '@reduxjs/toolkit';

    const alluserSlice = createSlice({
        name: 'Alluser',
        initialState: {
            allusers: [],
            blockuser:{},
            alluserpagesize:[],
            allusersloading: false,
            blockuserloading:false,
            error: null,
            pages: 1,
            currentPage: 1,
            pageSize:10,


        },
        reducers: {
            setAllUsers(state, action) {
                state.allusers = action.payload;
            },
            setBlockUser(state, action){
            state.blockuser=action.payload
            },
            setAllUserPageSize(state, action){
                state.allusers=action.payload
            },
            setSerachAllUser(state, action){
                state.allusers = action.payload; 
            },
            setDateRangeAllUser(state, action){
                state.allusers = action.payload; 
            },
            setLoading(state, action) {
                state.allusersloading = action.payload;
            },
            setBlockUserLoading(state, action){
                state.blockuserloading=action.payload
            },
            setError(state, action) {
                state.error = action.payload;
            },
            setCurrentPage(state, action) {
                state.currentPage = action.payload;
            },
            setPages(state, action) {
                state.pages = action.payload;
            },
            setPageSize(state, action){
                state.pageSize = action.payload;

            }

        },
    });

    export const { setAllUsers, setLoading, setError,setBlockUser,setBlockUserLoading,setCurrentPage,setPages,setAllUserPageSize,setSerachAllUser,setDateRangeAllUser,setPageSize} = alluserSlice.actions;
    export default alluserSlice.reducer;
