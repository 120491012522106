import React,{useState} from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import { setCurrentPage } from '../../redux/reducers/activeReducer';
import {fetchActiveUsers,fetchSizeActive,fetchActiveSearch} from '../../redux/actions/activeAction';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from 'react-redux';


const Activeusertable = ({activeuser,pages,currentPage,pageSize}) => {

  const dispatch=useDispatch();

    //page Size states 

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState(pageSize); 
  
    const options = [10, 25, 50, 100];


//pagenation
  const startIndex =  1;
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
        dispatch(fetchActiveUsers(newPage));
        dispatch(setCurrentPage(newPage));
    }
};



//pageSize
const pageSizeDropdown = () => {
  setIsOpenDropdown(!isOpenDropdown);
};
const handleSelect = (number) => {
  dispatch(fetchSizeActive(number));
  setSelectedNumber(number); 
  setIsOpenDropdown(false); 

};




  ///  duration format

  const formatDuration=(duration)=>{
   const seconds=Math.floor(duration);
   const minutes=Math.floor(seconds/60);
   const  hours=Math.floor(minutes/60);

    const  displayHours=  hours>0  ?  `${hours} hour${hours > 1  ? 's' :'' }` :  '';

    const   displayMinutes=  minutes % 60 >0  ?  `${minutes % 60} minute${minutes % 60 > 1  ? 's' :'' }` :  '';
    const displaySeconds=seconds % 60  >0   ? `${seconds % 60}  second${seconds % 60 > 1  ? 's' :'' }` :  ''

    return [displayHours, displayMinutes, displaySeconds].filter(part => part).join(', ');

  }


  ///  last ping format
  const formatLastPing = (lastPing) => {
    const now = new Date();
    const pingDate = new Date(lastPing);
    
    // Check if pingDate is valid
    if (isNaN(pingDate)) {
        return 'Invalid date';
    }

    const diffInSeconds = Math.floor((now - pingDate) / 1000);
    
    const minutes = 60;
    const hours = minutes * 60;
    const days = hours * 24;
    const months = days * 30;

    if (diffInSeconds < minutes) {
        return `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < hours) {
        const diffMinutes = Math.floor(diffInSeconds / minutes);
        return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < days) {
        const diffHours = Math.floor(diffInSeconds / hours);
        return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < months) {
        const diffDays = Math.floor(diffInSeconds / days);
        return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    } else {
        return pingDate.toLocaleDateString('en-US'); 
    }
};



  

  return (
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
        <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
          <div className='flex flex-col space-y-1'>
            <h1 className='text-primary font-semibold text-lg capitalize'>All active user</h1>
            <p className='text-primary text-base'>Details and history</p>
          </div>

          <div className='flex flex-row gap-3 ' >
            <div>

              <img src={Arrow} alt='img' />
            </div>
            <div>
              <img src={Printer} alt='img' />
            </div>
          </div>

        </div>

        <div className='w-full flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
         
          <div className="flex flex-row gap-3 items-center">
          <div className='border border-primary flex flex-row justify-between relative rounded-[10px] py-1 w-[75px] items-center'>
                  <div className='px-2' onClick={pageSizeDropdown}>
                      {selectedNumber} 
                  </div>
                  <div className='text-2xl text-primary' onClick={pageSizeDropdown}>
                      <MdKeyboardArrowDown />
                  </div>
                  
                  {isOpenDropdown && (
                      <div className='absolute bg-white border border-gray-300 rounded shadow-lg w-full top-10'>
                          {options.map((number) => (
                              <div
                                  key={number}
                                  className='px-4 py-2 cursor-pointer hover:bg-primary hover:text-white'
                                  onClick={() =>  handleSelect(number)}>
                                  {number}
                              </div>
                          ))}
                      </div>
                  )}
              </div>
    <div className='text-sm text-primary'>
    entries per page
    </div>
  </div>
  
          
          </div>
        <div className='w-full px-6 py-6  '>
   
              <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
                <table className="  table-auto  w-full  ">
                  <thead className='border-b ' >
                    <tr>
                      <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
                        Sr
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        User 
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        Active
                      </th>
                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                      Duration
                       </th>

                      <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
                        Country
                      </th>
                    
                    </tr>
                  </thead>

                  <tbody>
  { 
    activeuser.length > 0 ? (
      activeuser.map((user, index) => {
        return (
          <tr key={user._id}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{user.user?.username}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{formatLastPing(user.lastPing)}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{formatDuration(user.duration)}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{user?.country}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" className="px-6 py-4 text-center text-gray-500">No users active</td>
      </tr>
    )
  }
</tbody>
             
                </table>
              </div>
           


        </div>


        <div className="flex justify-between items-center px-6 py-3 ">
  
  <div className='text-primary'>
  Showing {startIndex} to {activeuser.length} of {activeuser.length} entries   
   </div>
  
   <div className='border flex flex-row rounded-md'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
</div>
            
          </div>





      </div>
  )
}

export default Activeusertable
