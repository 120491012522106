import React,{useEffect} from 'react'
import approve from '../../assets/svgs/approve.svg'
import Layout from '../../components/global/layout'
import Btnback from '../../components/global/btnback'
import Flipcampaignsviewform from '../../components/flipcampaignsview/flipcampaignsviewform'
// import Previouscampaign from '../../components/flipcampaignsview/previouscampaign'
import CurrentTime from '../../components/global/currenttime'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {fetchSingleCampaign,fetchupdateCampaignSingle} from '../../redux/actions/singlecompaigmsAction'
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import usePopuptoggle from '../../hooks/popuptoggle'
import ApproveCampaignPopup from '../../components/flipcampaigns/approvecampaingspopup'
import { toast } from 'react-toastify'


const Flipcampaignsview = () => {
   const { id } = useParams(); 
   const dispatch = useDispatch();
   const { IsPopupOpen, openPopup, closePopup} = usePopuptoggle();  

   const { campaignsingle, loading,updateLoading, error } = useSelector((state) => state.singlecampaign);
   useEffect(()=>{
      dispatch(fetchSingleCampaign(id));
   },[dispatch, id])



   const handleApproveUser = async (id) => {
    try {
      const data={status : "running"};
   await dispatch(fetchupdateCampaignSingle(id, data, toast));
   closePopup();
     dispatch(fetchSingleCampaign(id));
 } catch (error) {
   console.error("Error while blocking/unblocking user:", error);
 }
  };

   if (loading) {
      return (
        <Layout>
          <div className="min-h-screen h-full px-4 pt-4 flex flex-col gap-4">
            <ShimmerTitle line={1} gap={20} variant="secondary" className="mb-4" />
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
              <ShimmerTitle line={2} gap={10} className="flex-1 mb-4 lg:mb-0" />
              <ShimmerThumbnail height={48} width={148} rounded className="lg:w-[148px] shadow-md" />
            </div>
            <div className="flex flex-col lg:flex-row w-full gap-3 h-auto lg:h-[158px]">
              <div className="flex items-center w-full lg:w-[70%]">
                <ShimmerThumbnail height="100%" width="20%" rounded />
                <div className="flex flex-col space-y-1 bg-pure w-[80%] h-full border rounded-[10px] px-3 pt-4 pb-4 lg:pt-6">
                  <ShimmerTitle line={3} gap={10} />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row w-full lg:w-[30%] gap-3">
                <ShimmerThumbnail height="100%" width="100%" rounded />
                <ShimmerThumbnail height="100%" width="100%" rounded />
              </div>
            </div>
            <div className="flex w-full items-center gap-3 bg-pure rounded-[10px] text-sm lg:text-base h-[49px] px-3">
              <ShimmerTitle line={1} className="flex-1" />
            </div>
          </div>
        </Layout>
      );
    }
    
    if (error) return <div>Error: {error}</div>;

   const totalEstimatedEarnings = campaignsingle.compaignBudget - campaignsingle.budgetUsedInCompaign;
  return (
    <Layout>
      
      { IsPopupOpen && ( <ApproveCampaignPopup 
              onClose={closePopup}
              onApprove={handleApproveUser}
                />)}

          <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center w-full '>
             <div className='flex flex-col py-4'>
             <h1 className='text-xl font-semibold'>{campaignsingle.name}</h1>
             <CurrentTime/>
              </div>

               
        
              <div className='flex flex-row gap-2  w-full lg:w-auto'>
                <button 
                className={`${campaignsingle.status === 'running' ? 'bg-gray-400' : 'bg-green-500'} text-white  w-2/4 sm:w-1/3 lg:w-24 rounded-[10px] ` } 
                onClick={openPopup}
                disabled={campaignsingle.status === 'running'}

                > 
                {campaignsingle.status === 'running' ? 'Approved' : 'Approve' }
                </button>


                <div  >
              <Btnback/>
              </div>

              </div>
        
         </div>

         <div className='py-6 bg-white rounded-[10px] mt-5'>
    <div className='grid grid-cols-2 md:grid-cols-5 gap-3 px-3'>
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Clicks </p>
        <h1 className='text-primary text-lg font-bold'>{campaignsingle.totalClicks}</h1>
     </div>    
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Dead End Clicks</p>
        <h1 className='text-primary text-lg font-bold'>N/A</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Conversions</p>
        <h1 className='text-primary text-lg font-bold'>{campaignsingle.totalShares}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Budget Used (USD)</p>
        <h1 className='text-primary text-xl font-bold'>{campaignsingle.budgetUsedInCompaign}</h1>
     </div>  
     <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
        <p className='text-sm font-semibold'>Total Estimated Earnings</p>
        <h1 className='text-primary text-xl font-bold '>{totalEstimatedEarnings}</h1>
     </div>  

    </div>
    </div>

      <Flipcampaignsviewform campaignsingle={campaignsingle}  updateLoading={updateLoading}   onOpen={openPopup}/>
      {/* <Previouscampaign/> */}
        
         </div>
    </Layout>
 
  )
}

export default Flipcampaignsview
