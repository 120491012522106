import Axios from "../../config/api";
import { setAdminDashboard, setAdminLoading, setError } from '../reducers/dashboardReducer';

export const fetchTotalDashboard = () => async (dispatch, getState) => {
    try {
        dispatch(setAdminLoading(true));
        const { token } = getState().auth.user;
        const response = await Axios.get('/user/admin-dashboard-data', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const { data } = response.data;
        dispatch(setAdminDashboard(data));
        console.log('Admin Dashboard Data:', data); 
    } catch (err) {
        console.error('Fetch error:', err); 
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    } finally {
        dispatch(setAdminLoading(false)); 
    }
};
