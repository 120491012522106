import React from 'react';
import Printer from '../../../assets/svgs/printer.svg';
import Arrow from '../../../assets/svgs/longaroow.svg';
import Searchbox from '../../global/searchbox';

const Processing = ({ singleuserwithdraws, singleuserloading }) => {
  return (
    <>
      <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5'>
        <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2'>
          <div className='flex flex-col space-y-1'>
            <h1 className='text-primary font-semibold text-lg'>All Processing</h1>
            <p className='text-primary text-base'>Details and history</p>
          </div>

          <div className='flex flex-row gap-3'>
            <div>
              <img src={Arrow} alt='img' />
            </div>
            <div>
              <img src={Printer} alt='img' />
            </div>
          </div>
        </div>

        <div className='w-full flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
          <div className='order-2 sm:order-1'>
            <p className='text-primary'>entries per page</p>
          </div>
          <div className='order-1 sm:order-2'>
            <Searchbox />
          </div>
        </div>

        <div className='w-full px-6 py-6'>
          <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
            <table className='table-auto w-full'>
              <thead className='border-b'>
                <tr>
                  <th scope="col" className="text-base font-semibold px-6 py-4 text-left border-r">Sr</th>
                  <th scope="col" className="text-base font-semibold px-6 py-4 text-left border-r">Request Date</th>
                  <th scope="col" className="text-base font-semibold px-6 py-4 text-left border-r">Description</th>
                  <th scope="col" className="text-base font-semibold px-6 py-4 text-left border-r">Payment ID</th>
                  <th scope="col" className="text-base font-semibold px-6 py-4 text-center border-r">Amount</th>
                  <th scope="col" className="text-base font-semibold px-6 py-4 text-center border-r">Status</th>
                </tr>
              </thead>
              <tbody>
                {singleuserwithdraws.filter(item => item.status === 'pending').length > 0 ? (
                  singleuserwithdraws.filter(item => item.status === 'pending').map((item, index) => (
                    <tr key={item._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                        {new Date(item.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                        {item.description || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                        {item._id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300 text-center">
                        USD {item.amount}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300 text-center">
                        <span className="text-yellow-500">{item.status}</span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center text-gray-500">No processing withdrawals found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Processing;
