import React,{useRef} from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import Searchbox from '../../components/global/searchbox'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import eye from '../../assets/svgs/eye.svg'
import view from '../../assets/svgs/view.svg'
import remove from '../../assets/svgs/delete.svg'


const Flipcampaignstatstable = ({campaignsingle}) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
      };
      const created = formatDate(campaignsingle.createdAt);
       const earning=campaignsingle.compaignBudget-campaignsingle.budgetUsedInCompaign;
       const sharedBonus = 'N/A'; 
       const username=campaignsingle.user?  campaignsingle.user.username:'N/A';

       const rows = [
        {
        
          date: created,
          userName: username,
          clicks: campaignsingle.totalClicks,
          conversions: campaignsingle.totalShares, 
          budget: campaignsingle.budgetUsedInCompaign,
          sharedBonus: sharedBonus,
          estimatedEarnings: earning, 
          status: campaignsingle.status
        }
      ];



  return (
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
    <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
    <div className='flex flex-col space-y-1'>
    <h1 className='text-primary font-semibold text-lg'> Stats</h1>
    <p className='text-primary text-base'>Details and history</p>
    </div>

    <div className='flex flex-row gap-3 ' >
        <div>

        <img src={Arrow} alt='img'/>
        </div>
        <div>
         <img src={Printer} alt='img'/>
        </div> 
    </div>

    </div>

    <div className=' w-full flex flex-col gap-3 sm:gap-0  sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
    <div className='order-2 sm:order-1'>
        <p className='text-primary'>entries per page</p> 
    </div>
    <div className='order-1 sm:order-2'>
    <Searchbox/>
    </div>

   
              
          
    </div>

      <div className='w-full px-6 py-6  '>
        <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
        <table className="  table-auto  w-full  ">
      <thead className='border-b ' >
          <tr>
              <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
              Sr
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Date
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              User Name
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Clicks              
                </th> 
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
              Conversions                 
              </th>
              
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
              Budget(USD)               
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
              Shared Bouns                 
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center   border-r">
              Estimated Earnings
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center">
              status              
              </th>
          </tr>
      </thead>
      <tbody>
  {rows.map((row, index) => (
    <tr key={index}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.date}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.userName}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.clicks}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.conversions}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.budget}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.sharedBonus}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{row.estimatedEarnings}</td>
      <td className={`px-6 py-4 whitespace-nowrap text-sm  font-semibold ${row.status === 'expired' ? 'text-danger' : 'text-sucess'}`}>
        {row.status}
      </td>
    </tr>
  ))}
</tbody>


      </table>
        </div>
     
      </div>

   

   
  </div>
  )
}

export default Flipcampaignstatstable