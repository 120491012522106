import React,{useState,useEffect} from 'react'
import Layout from '../../components/global/layout'
import person from '../../assets/svgs/userprofile.svg'
import wallet from '../../assets/svgs/wallet.svg'
import Profile from '../../components/profileinformation/profile'
import Anlyticsdashboard from '../../components/profileinformation/anlyticsdashboard'
import Addcampaigns from '../../components/profileinformation/addcampaigns'
import Paymenthistory from '../../components/profileinformation/paymenthistory'
import CurrentTime from '../../components/global/currenttime'
import './profileinformation.css'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {fetchSingleUser} from '../../redux/actions/singleuserAction'
import {fetchSingleUserCampaign} from '../../redux/actions/singleusercompaignsAction'
import {fetchSingleUserWithdraw} from '../../redux/actions/singlewithdrawAction'
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import { baseURL } from '../../config/api'
import { fetchUserSinglePayment } from '../../redux/actions/paymentbeilingAction';
import { toast } from 'react-toastify'
import Img from '../../assets/png/img2.jpg' 
import Confrimpopup from '../../components/alluser/confrimpopup'
import usePopuptoggle from '../../hooks/popuptoggle'
import { fetchBlock } from '../../redux/actions/alluserAction'

const  Profileinformation = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const { IsPopupOpen, openPopup, closePopup} = usePopuptoggle();  


  const { id } = useParams(); 
  const dispatch = useDispatch();
  const { singleUser, loading, error } = useSelector((state) => state.singleuser);
  const { singleUserPaymentloading, singleUserPayment,pages,currentPage} = useSelector((state) => state.paymentbilling);
  const { singleuserwithdraws, singleuserloading} = useSelector((state) => state.singlewithdraw);

   
  const {singlecampaign, loading: loadingCampaign, error: errorCampaign}=useSelector((state) => state.singleusercampaign);

 
  useEffect(() => {
    dispatch(fetchSingleUser(id));
    dispatch(fetchSingleUserCampaign(id));
    dispatch(fetchUserSinglePayment(id));
    dispatch(fetchSingleUserWithdraw(id));
}, [dispatch, id]);

const handleBlockUser = async () => {
  try {
    const newIsActive = singleUser.isActive ? false : true;
    await dispatch(fetchBlock(id, { isActive: newIsActive }, toast));
    closePopup();
    dispatch(fetchSingleUser(id));
  } catch (error) {
    console.error("Error while blocking/unblocking user:", error);
  }
};



if (loading) {
  return (
    <Layout>
      <div className="min-h-screen h-full px-4 pt-4 flex flex-col gap-4">
        <ShimmerTitle line={1} gap={20} variant="secondary" className="mb-4" />
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <ShimmerTitle line={2} gap={10} className="flex-1 mb-4 lg:mb-0" />
          <ShimmerThumbnail height={48} width={148} rounded className="lg:w-[148px] shadow-md" />
        </div>
        <div className="flex flex-col lg:flex-row w-full gap-3 h-auto lg:h-[158px]">
          <div className="flex items-center w-full lg:w-[70%]">
            <ShimmerThumbnail height="100%" width="20%" rounded />
            <div className="flex flex-col space-y-1 bg-pure w-[80%] h-full border rounded-[10px] px-3 pt-4 pb-4 lg:pt-6">
              <ShimmerTitle line={3} gap={10} />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row w-full lg:w-[30%] gap-3">
            <ShimmerThumbnail height="100%" width="100%" rounded />
            <ShimmerThumbnail height="100%" width="100%" rounded />
          </div>
        </div>
        <div className="flex w-full items-center gap-3 bg-pure rounded-[10px] text-sm lg:text-base h-[49px] px-3">
          <ShimmerTitle line={1} className="flex-1" />
        </div>
      </div>
    </Layout>
  );
}

if (error) return <div>Error: {error}</div>;


const handleTabClick = (tab) => {
  if (!singleUser.isActive) return; 
  setActiveTab(tab);
};
  return (
    <Layout>

{ IsPopupOpen && ( <Confrimpopup 
              onClose={closePopup}
              onConfirm={handleBlockUser}
           
                />)}
         <div className='min-h-screen h-full px-4 pt-4  flex flex-col gap-4  '>
         
         <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center '>
             <div className='flex flex-col py-4'>
             <h1 className='text-lg font-semibold'>{singleUser.username}</h1>
             <CurrentTime/>
                      </div>
            <div className='text-sm  font-semibold  bg-[#FF1400] text-pure text-center  py-2 w-[150px] rounded-lg cursor-pointer shadow-md'    onClick={openPopup}>               
            {singleUser.isActive  ?  'Block'  : 'unBlock'} 
               </div>
         </div>

         <div className='flex flex-col lg:flex-row w-full gap-3 h-auto lg:h-[158px]'>
          <div className='flex  flex-col gap-2  sm:gap-0 sm:flex-row sm:items-center w-full lg:w-[70%]'>
          <div className='w-[20%] h-full flex items-center justify-center '>
             <img 
             src={`${baseURL}/${singleUser.image}`} 
              alt='img'
             className=' w-[60px]  h-[60px] sm:w-24 sm:h-24 md:w-32 md:h-32  lg:w-24 lg:h-24 xl:w-40 xl:h-40 rounded-full  object-cover'
             onError={(e) => { e.target.onerror = null; e.target.src = Img; }}

             />
           </div>
           <div className='flex flex-col space-y-1 bg-pure w-full sm:w-[80%] h-full border rounded-[10px] px-3   pt-4 pb-4 lg:pt-6'>
             <p className='text-base font-semibold'>{singleUser.username}</p>
             <p className='text-primary text-[15px] font-semibold'>{singleUser.email}</p>
             <p className='text-sm'>{singlecampaign.description}</p>
           </div>


          </div>

          <div className='flex flex-col sm:flex-row w-full lg:w-[30%] gap-3 '>
          <div className='w-full sm:w-[50%] h-full bg-white  flex flex-col space-y-2 items-center rounded-[10px] pt-2 pb-2 lg:pt-5 lg:pb-0 border '>
              <img src={wallet} alt='img'/>
              <p className='font-semibold text-base lg:text-lg'>My Wallet</p>
              <p  className='font-semibold text-lg lg:text-xl'> ${singleUser?.wallet?.totalBalance}</p>
           </div>
           <div className='w-full sm:w-[50%]  h-full bg-white  flex flex-col space-y-2 items-center rounded-[10px] pt-2 pb-2 lg:pt-5 border '>
              <img src={wallet} alt='img'/>
              <p className='font-semibold text-base lg:text-lg'>Referral Link </p>
              <p  className='font-semibold text-xs  '>{singleUser.referralCode}</p>
           </div>
                 
          </div>


         
         </div>
          
         <div className="flex flex-wrap bg-pure rounded-[10px] text-[13px] sm:text-sm lg:text-base h-auto md:h-[49px] px-3 ">
  <div
    className={`w-1/2 sm:w-auto tab ${activeTab === 'profile' ? 'active' : ''}`}
    onClick={() => handleTabClick('profile')}
  >
    Profile Information
  </div>
  <div
    className={`w-1/2 sm:w-auto tab ${activeTab === 'campaigns' ? 'active' : ''}`}
    onClick={() => handleTabClick('campaigns')}
  >
    Add Campaigns
  </div>
  <div
    className={`w-1/2 sm:w-auto tab ${activeTab === 'payment' ? 'active' : ''}`}
    onClick={() => handleTabClick('payment')}
  >
    Payment History
  </div>
  <div
    className={`w-1/2 sm:w-auto tab ${activeTab === 'analytics' ? 'active' : ''}`}
    onClick={() => handleTabClick('analytics')}
  >
    Analytics Dashboard
  </div>
</div>


          <div className="tab-content">
        {activeTab === 'profile' && <Profile singleUser={singleUser}/>}
        {activeTab === 'campaigns' && <Addcampaigns singlecampaign={singlecampaign} loadingCampaign={loadingCampaign}/>}
        {activeTab === 'payment' && <Paymenthistory    singleUserPayment={singleUserPayment}   singleUserPaymentloading={singleUserPaymentloading}  singleUser={singleUser}   singleuserwithdraws={singleuserwithdraws} singleuserloading={singleuserloading}     pages={pages} currentPage={currentPage}/>}
        {activeTab === 'analytics' && <Anlyticsdashboard  singlecampaign={singlecampaign} loadingCampaign={loadingCampaign}/>}
      </div>

         </div>


         
    </Layout>
    
  )
}

export default Profileinformation