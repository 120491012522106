import { createSlice } from '@reduxjs/toolkit';

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
        singlebanner:[],
        singleBannerLoading: false,
        loading: false,
        createLoading: false,
        updateLoading : {} ,
        deleteLoading: {}
    },
    reducers: {
      addBanner(state, action) {
            state.banners.unshift(action.payload);
        },  
        setsingleBanner(state,action){
            state.singlebanner = action.payload;
        },
        removeBanner(state, action) {
            state.banners = state.banners.filter(banner => banner._id !== action.payload);
        },
        updateBanner (state , action) {
            const index = state.banners.findIndex(item => item._id === action.payload._id);
            state.banners[index] = action.payload;
        },
        setBanners(state, action) {
            state.banners = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
      setCreateLoading(state, action) {
            state.createLoading = action.payload;
        },  
        setUpdateLoading(state, action) {
            state.updateLoading  = { ...state.updateLoading, [action.payload.id]: action.payload.loading };
        },  
        setDeleteLoading(state, action) {
            state.deleteLoading = { ...state.deleteLoading, [action.payload.id]: action.payload.loading };
        },
        setSingleBannerLoading(state, action) {
            state.singleBannerLoading = action.payload;
        },  
    },
});

export const {
    addBanner,
    removeBanner,
    updateBanner,
    setBanners,
    setLoading,
    setsingleBanner,
    setSingleBannerLoading,
    setCreateLoading,
    setUpdateLoading,
    setDeleteLoading,
    
} = bannerSlice.actions;

export default bannerSlice.reducer;