import React, { useEffect } from 'react';
import deletecategory from '../../assets/svgs/deletecategory.svg';
import { getTotalSubCategories,deleteSubCategory } from '../../redux/actions/subcategoryAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../global/loader';
import { toast } from 'react-toastify';

const Parentsubcategory = ({ parentId }) => {
    console.log('Clicked parent ID:', parentId);
    const dispatch = useDispatch();
    const { subCategories, loading } = useSelector(state => state.subcategory);

    useEffect(() => {
        dispatch(getTotalSubCategories());
    }, [dispatch]);

    const filteredSubCategories = subCategories.filter(sub => sub.parentCategory && sub.parentCategory._id === parentId);

    if (loading) {
        return <Loader />;
    }

    if (filteredSubCategories.length === 0) {
        return <div className="text-center py-4  text-primary">No subcategories found</div>;
    }

   
    const handleSubdelete = (id) => {
        dispatch(deleteSubCategory(id, toast));
    };

    return (
        <div className="w-full flex justify-center">
            <div className="w-4/5 border border-primary rounded-[10px] h-auto">
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 p-3 md:p-5">
                    {filteredSubCategories.map(sub => (
                        <div className="h-[45px] flex justify-between items-center border border-primary px-3 rounded-[10px]" key={sub._id}>
                            <p className='text-primary text-sm lg:text-base'>{sub.name}</p>
                            <img
                                src={deletecategory}
                                alt="delete"
                                className="w-8 h-8 cursor-pointer"
                                onClick={() => handleSubdelete(sub._id)}                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Parentsubcategory;
