import React, { useState,useRef } from 'react'
import cross from '../../assets/svgs/removepopup.svg'
import { FaSearch } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import useToggle from '../../hooks/useToggle'
import useClickOutside from '../../utils/clickOutside';
import cloud from '../../assets/svgs/feather_upload-cloud.svg'
import {convertToDataURL} from '../../utils/convertToDataURL'
import {createNotification,deleteNotification} from '../../redux/actions/notificationAction'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import './style.css'


const Notificationpopup = ({onClose,createnotificationsloading}) => {
  const dispatch=useDispatch();

  const listMenuRef = useRef();
  const [showSelect,toggleSelect]=useToggle();
  useClickOutside(listMenuRef, () => toggleSelect(false))


  const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('all');
    const [country,setCountry]=useState('PK')
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [image, setImage] = useState('');
    const [file,setFilename]=useState('');
  
    
    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        setFilename(file.name); 
        const imageUrl = await convertToDataURL(file);
        setImage(imageUrl); 
      }
    };
  

 
    const handleSelectType = (selectedType) => {
      setType(selectedType);
      toggleSelect(false); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = { name, type, country, description, image,imageUrl };
    console.log(data);
    try { 
      await dispatch(createNotification(data, toast));  
      onClose(); 
    } catch (error) {
      console.error('Error creating notification:', error);
      toast.error('Failed to create notification. Please try again.');
    }
  };

  

  

  return (
    <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50 '>
        <div className='w-full h-screen flex justify-center items-center  '>
        
  <div className='w-full sm:w-[540px] h-[700px] lg:h-auto bg-pure px-3 py-3 rounded-[10px] overflow-x-auto  sm:overscroll-x-none'>
  <div className='flex flex-col space-y-4'>
  <div className='flex flex-row items-center py-1 justify-between'>
     <div className='text-xl font-semibold'>Create Notification</div>
      <div className=' w-10 h-10 rounded-full flex justify-center cursor-pointer '>
        <img src={cross} alt='img' className='w-5' onClick={onClose}/>
      </div>
   </div>
   <div className='flex flex-col space-y-2'>
      <h1  className='text-base text-dark text-opacity-80'>Notification Title</h1>
                            <input
                                type='text'
                                placeholder='title'
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                className='border border-primary w-full outline-none py-2 px-2 rounded-md'
                            />
   </div>
   <div className='flex flex-col space-y-2'>
   <h1  className='text-base text-dark text-opacity-80'>Notification Date</h1>
   <input type='date'  className='border border-primary w-full outline-none py-2 px-2 rounded-md text-dark text-opacity-55'     />
   </div>
   <div className='flex flex-col space-y-2'>
   <h1  className='text-base text-dark text-opacity-80 relative'>Notification Type</h1>
   <div className='flex flex-row w-full border border-primary rounded-md relative'>
                                <div className='w-[90%] relative'>
                                    <input
                                        type='text'
                                        placeholder='select'
                                        value={type} 
                                        readOnly
                                        className='w-full outline-none py-2 px-2 text-dark rounded-[10px]'
                                    />
                                    <div className='absolute top-2 right-2 text-2xl cursor-pointer' onClick={toggleSelect}>
                                        <MdKeyboardArrowDown />
                                    </div>
                                </div>
                                <div className='w-[10%] border-l border-primary flex justify-center items-center text-xl'>
                                    <FaSearch />
                                </div>
                                {showSelect && (
                                    <div className='absolute w-72 py-3 right-0 top-12 rounded-[10px] bg-pure shadow-md text-dark' ref={listMenuRef}>
                                        <ul className='cursor-pointer'>
                                            <li className='text-primary text-sm border-b py-2 px-3' onClick={() => handleSelectType('all')}>All User</li>
                                            <li className='text-primary text-sm border-b py-2 px-3' onClick={() => handleSelectType('campaign')}>Flip Campaign</li>
                                            <li className='text-primary text-sm border-b py-2 px-3' onClick={() => handleSelectType('shared')}>Flip Shared</li>
                                            <li className='text-primary text-sm border-b py-2 px-3' onClick={() => handleSelectType('referral')}>Flip Referral</li>
                                        </ul>
                                    </div>
                                )}
                            </div>

  
  
   </div>
   <div className='flex flex-col space-y-2'>
   <h1  className='text-base text-dark text-opacity-80 relative'>Notification Message</h1>
   <textarea name='NotifcMessage' 
     value={description} 
     onChange={(e) => setDescription(e.target.value)}
    className='h-40 bg-pure outline-none px-3 rounded-md border border-primary text-black text-opacity-60 py-2' placeholder='Message' />
   </div>

   <div className='flex flex-col space-y-2'>
      <h1  className='text-base text-dark text-opacity-80'>Image URL</h1>
      <input type='text'
           value={imageUrl} 
           onChange={(e) => setImageUrl(e.target.value)}
      placeholder='https://www.lipsum.com/feed/html' className='border border-primary w-full outline-none py-2 px-2 rounded-md'/>
   </div>

   <div className="flex flex-col space-y-2">

    <div className='flex flex-row justify-between items-center px-2 w-full bg-pure rounded-[10px]  border border-primary py-[1px]'>
    <div className='flex flex-row items-center gap-2'>
       <div>
        <img src={cloud} alt='img' />
       </div>
       <div className='flex flex-col'>
       <h1 className=''>{file}</h1>
       <p className='text-[13px] text-black text-opacity-60'>JPG, OR PNG, file size no more than 10MB</p>
       </div>
    </div>

    <label htmlFor="myfile" className="custom-file-upload flex justify-center items-center">
       <h1 className='text-primary'> Select file</h1>
      <input
        type="file"
        id="myfile"
        name="myfile"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </label>
    </div>
   

</div>

 <div className="flex flex-row justify-center py-2 space-y-2">
    <button className=' w-full sm:w-[321px] bg-primary text-white shadow-md py-2 rounded-md' onClick={handleSubmit} >{
      createnotificationsloading  ?

      <PulseLoader size={10} color='#fff' />:
                    
                                'Send'
    }  
    </button>
 </div>
  </div>



  
  </div>
</div>
     

    </div>
  )
}

export default Notificationpopup
