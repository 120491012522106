import { format } from 'date-fns';
import Axios from "../../config/api";
import { setCampaigns, setStatistics, setLoading, setError,setCurrentPage,setPages,setPageSizeCompaigns,setDateRangeCompaigns,setSerachCompaigns,setPageSize } from '../../redux/reducers/compaignsReducer';
export const fetchCompaigns = (page = 1,) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        let url = `/compaign?page=${page}`;
        const response = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });
        
        const { data: { data: { docs, pages, page: currentPage, ...statistics } } } = response;
        
        dispatch(setCampaigns(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setStatistics(statistics)); 
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};
export const fetchSizeCompaigns = (pageSize) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/compaign?pageSize=${pageSize}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setPageSizeCompaigns(docs));
        dispatch(setLoading(false));
        dispatch(setPageSize(pageSize));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};


export const fetchCompaignsDateRange  = (datestart,dateend) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/compaign?from=${datestart}&to=${dateend}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setDateRangeCompaigns(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};



export const fetchCompaignsSearch = (keyword) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs } } } = await Axios.get(`/compaign?keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        dispatch(setSerachCompaigns(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};
