import { createSlice } from '@reduxjs/toolkit';

const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState: {
        withdraw: [],  
        loading: false,
        error: null,
        pages: 1,
        currentPage: 1,
    },
    reducers: {
        setWithdraw(state, action) {
            state.withdraw = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        }
    },
});
export const { setWithdraw, setLoading, setError, setCurrentPage, setPages } = withdrawSlice.actions;
export default withdrawSlice.reducer;