import React from 'react'
import Printer from '../../../assets/svgs/printer.svg';
import Arrow from '../../../assets/svgs/longaroow.svg'
import arrow from '../../../assets/svgs/arrow.svg'
import setting from '../../../assets/svgs/tablesetting.svg'
import calender from '../../../assets/svgs/calendar.svg'
import Loader from '../../global/loader';
// import Searchbox from '../../global/searchbox';
import { setCurrentPage } from '../../../redux/reducers/paymentbeilingReducer';
import { fetchUserSinglePayment } from '../../../redux/actions/paymentbeilingAction';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from 'react-redux';


const Allpayemnt = ({singleUserPayment,singleUserPaymentloading,currentPage,pages}) => {
  const dispatch=useDispatch();
  const createdDates = singleUserPayment.map(doc => new Date(doc.createdAt));
  const minDate = new Date(Math.min(...createdDates));
  const maxDate = new Date(Math.max(...createdDates));
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

const dateRange = createdDates.length > 0 && !isNaN(minDate) && !isNaN(maxDate) 
? `${formatDate(minDate)} - ${formatDate(maxDate)}`
: 'No date available'; 


//pagenation
const handlePageChange = (newPage) => {
  if (newPage > 0 && newPage <= pages) {
      dispatch(fetchUserSinglePayment(newPage));
      dispatch(setCurrentPage(newPage));
  }
};
const startIndex =  1;
//pagesize

  return (
    <div className='flex flex-col space-y-6'>

<div className='bg-pure w-full   grid grid-cols-1 lg:grid-cols-3 gap-5  px-3 py-4 rounded-[10px]'>
    <div className='flex flex-col space-y-4 '>
   <label className='font-semibold'>Request Date</label>
       <div className='relative border border-primary rounded-[10px]  h-[50px]'>
       <input type='text'
         value={dateRange}
        className='  outline-none py-1 px-2 w-full h-full   rounded-[10px]   text-black '/>
           <div className='absolute inset-y-0 right-3 flex items-center'>
        <img src={calender} alt='Calendar icon' />
    </div>
       </div>
 
    </div>
    {/* <div className='flex flex-col space-y-4 '>
   <label className='font-semibold'>Payment Date</label>
   <div className='relative border border-primary rounded-[10px]  h-[50px]'>
       <input type='text'
         value={dateRange}
        className='  outline-none py-1 px-2 w-full h-full   rounded-[10px]   text-black '/>
           <div className='absolute inset-y-0 right-3 flex items-center'>
        <img src={calender} alt='Calendar icon' />
    </div>
       </div>
    </div> */}
    {/* <div className='flex flex-col space-y-4 '>
   <label className='font-semibold'>Status </label>
        <input type='text' placeholder='' 
        className='border border-primary rounded-[10px] w-full  outline-none py-1 px-2  h-[50px]  text-black text-opacity-25'/>
    </div> */}
    </div>
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
<div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
<div className='flex flex-col space-y-1'>
<h1 className='text-primary font-semibold text-lg'>All Payments</h1>
<p className='text-primary text-base'>Details and history</p>
</div>

<div className='flex flex-row gap-3 ' >
    <div>

    <img src={Arrow} alt='img'/>
    </div>
    <div>
     <img src={Printer} alt='img'/>
    </div> 
</div>

</div>

<div className=' w-full flex flex-col gap-3 sm:gap-0  sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
<div className='order-2 sm:order-1'>
    <p className='text-primary'>entries per page</p> 
</div>
<div className='order-1 sm:order-2'>
{/* <Searchbox/> */}
</div>


          
      
</div>

  <div className='w-full px-6 py-6  '>

 { singleUserPaymentloading ? (
              <Loader />
            ) :
            (


              <>

              <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
    <table className="  table-auto  w-full  ">
  <thead className='border-b ' >
      <tr>
          <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
          Sr
          </th>
          <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
          Request Date
          </th>
          <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
          Campaign Туре         
           </th>
          <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
          Payment ID   
                   </th> 
          <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
          Amount                 
          </th>
          <th scope="col" className="text-base font-semibold  px-6 py-4 text-center   border-r">
          Status
          </th>
      </tr>
  </thead>
  <tbody>
   
  {singleUserPayment.length > 0 ? (
    singleUserPayment.map((item, index) => (
      <tr key={item._id}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
          {index + 1}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
          {new Date(item.createdAt).toLocaleDateString()}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
          {item?.description}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
          {item._id}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium border-r border-gray-300">
          <span className={item.action === '+' ? 'text-green-500' : 'text-red-500'}>
            {item.action === '+' ? `+ USD ${item.amount}` : `- USD ${item.amount}`}
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
          {item.action === '+' ? 'Debit' : 'Credit'}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
        No payment found
      </td>
    </tr>
  )}


 

   
  

  </tbody>

  </table>
    </div>


    <div className="flex justify-between items-center px-6 py-3 ">
  
  <div className='text-primary'>
  Showing {startIndex} to {singleUserPayment.length} of {singleUserPayment.length} entries   
   </div>
  
   <div className='border flex flex-row rounded-md'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
</div>
            
          </div>
              </>
    )
            }
    
 
  </div>




</div>

    </div>
 
  )
}

export default Allpayemnt