import React, { useState } from 'react';

const usePopuptoggle = () => {
    const [IsPopupOpen, setIsPopupOpen] = useState(false);
    const [campaignId, setCampaignId] = useState(null);
    const [isActive, setIsActive] = useState(true); 

    const openPopup = (id, activeStatus) => {
        setCampaignId(id);
        setIsActive(activeStatus); 
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setCampaignId(null);
        setIsActive(true); 
    };

    return {
        IsPopupOpen,
        openPopup,
        closePopup,
        campaignId,
        isActive,
    };
};

export default usePopuptoggle;
