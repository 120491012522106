import React,{useState} from 'react'
import cross from '../../assets/svgs/removepopup.svg'
import {fetchAddAdmins} from '../../redux/actions/usermangmentAction';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';



const Usermagmentpopup = ({onClose}) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('admin');
  const [country, setCountry] = useState('Pakistan');

  const dispatch = useDispatch();
  const {addadminManagementLoading}=useSelector((state)=>state.usermangment);


 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { username, email, password, role, country };
    console.log(data);

    try {
      await dispatch(fetchAddAdmins(data,toast));  
      onClose();  
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to create admin. Please try again.');
    }
  };



   
  return (
    <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50 '>
      <div className='w-full h-screen flex justify-center items-center  '>
          <div className='w-4/5 bg-[#F2F6FC] h-auto lg:h-[327px] rounded-md flex justify-center items-center relative'>
          <div className='absolute top-1 right-0  lg:top-3 lg:right-2  cursor-pointer  w-10 h-10  items-center '>
        <img src={cross} alt='img' onClick={onClose}/>
      </div>
          <div className='bg-pure w-full lg:w-11/12 h-auto lg:h-[243px] rounded-md flex flex-col space-y-5 px-3 py-3'>
      <div className='flex flex-col lg:flex-row gap-3 w-full'>
        <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
          <label className='text-dark text-[15px] font-semibold'>User Name</label>
          <input
            type='text'
            placeholder='name'
            className='border border-primary outline-none py-3 rounded-md text-sm px-3'
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
          <label className='text-dark text-[15px] font-semibold'>Email</label>
          <input
            type='email'
            placeholder='email@example.com'
            className='border border-primary outline-none py-3 rounded-md text-sm px-3'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className='w-full lg:w-1/3 flex flex-col space-y-2'>
          <label className='text-dark text-[15px] font-semibold'>Password</label>
          <input
            type='password'
            placeholder='Password'
            className='border border-primary outline-none py-3 rounded-md text-sm px-3'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className='flex flex-col lg:flex-row lg:items-center justify-between gap-3 w-full'>
        <div className='w-full lg:w-1/3 flex flex-col space-y-2 relative'>
          <label className='text-dark text-[15px] font-semibold'>Select Role</label>
          <input
            type='text'
            value={role}
            className='border border-primary outline-none py-3 rounded-md text-sm px-3'
            readOnly
          />
        </div>
        <div className='w-full lg:w-1/3 h-1 space-y-2 relative hidden lg:block'>
        </div>
        <div className='w-full lg:w-1/3 flex flex-row space-y-2 justify-end relative'>
          <button
            className='bg-primary text-pure font-medium hover:bg-opacity-85 w-full py-3 rounded-md shadow-md mt-0 lg:mt-10'
            onClick={handleSubmit}
          >
         {addadminManagementLoading ? <PulseLoader size={10} color='#fff' /> : 'Submit'}   

          </button>
        </div>
      </div>
    </div>
          </div>
      </div>
    </div>
  )
}

export default Usermagmentpopup
