import React,{useRef,useEffect,useState} from "react";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import useToggle from "../../hooks/useToggle";
import useClickOutside from "../../utils/clickOutside";
import { RxCross2 } from "react-icons/rx";
import {createSubCategory} from '../../redux/actions/subcategoryAction'
import { toast } from "react-toastify";
import { useDispatch,useSelector } from "react-redux";
import { PulseLoader } from 'react-spinners';
import { ShimmerCard, ShimmerTitle } from 'react-shimmer-effects'; 
import Layout from "../global/layout";



const Addsubcategory = ({categories,}) => {
  const dispatch=useDispatch();
  const {createLoading}=useSelector((state)=>state.subcategory);
    const [items, setItems] = useState([{ id: Date.now(), name: ''  }]);    
    const [isDropdownOpen, toggleDropdown] = useToggle(false);
    const tableRef = useRef(null);
    useClickOutside(tableRef , ()=>toggleDropdown(false));
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [parentCategoryId, setParentCategoryId] = useState(null);
    
  
    const handleCategorySelect = (category) => {
      setSelectedCategory(category.name);
      setParentCategoryId(category._id);
      toggleDropdown(false);
    };
    const handleInputChange = (index, value) => {
      const updatedItems = [...items];
      updatedItems[index].name = value;
      setItems(updatedItems);
    };
    const handleAddItem = (index) => {
      const newItem = { id: Date.now(), name: '' };
        const updatedItems = [...items];
        updatedItems.splice(index + 1, 0, newItem);
        setItems(updatedItems);
      };
  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };   

  const handleSubmit = () => {
    items.forEach(item => {
      if (item.name.trim()) { 
        dispatch(createSubCategory({ name: item.name, parentCategory: parentCategoryId }, toast));
        setItems([{name: null}]);
      }
    });
  };

 
 
 

  return (
    <div className='w-full lg:w-2/4 h-[464px] bg-pure shadow-md  rounded-[10px] mt-5 overflow-auto'>

       <div className='font-semibold  text-xl px-3 py-3  border-b '>
       Select Category
         </div>
         <div className="flex flex-col space-y-4">
         <div className='flex flex-col space-y-2   px-4'>
          <h1 className='text-sm pt-4 '>Category Name</h1>
          <div className="w-full h-12 border border-primary rounded-[10px] relative" onClick={() => toggleDropdown(!isDropdownOpen)} >
          <input type='text'
             value={selectedCategory}
           placeholder='category 1' className='w-full outline-none rounded-[10px]   px-3  h-full  ' readOnly />
               <div className="absolute inset-y-0 right-0 flex items-center pr-3">
               <IoIosArrowDown />
             </div>
             {isDropdownOpen  &&(
                <div className="border w-full absolute h-56 z-10 bg-white overflow-auto  " ref={tableRef}>
                <ul>
                {categories.map((category, index) => (
                                <li
                                    key={index}
                                    className="py-3 px-3 text-lg border-b hover:bg-black hover:bg-opacity-10 cursor-pointer"
                                    onClick={() => handleCategorySelect(category)}
                                >
                                    {category.name}
                                </li>
                               
                            ))}
                            </ul>
             </div>
             )}
           
          </div>
          
         </div>
         <div className="flex flex-col space-y-4">
         {items.map((item, index) => (
        <div key={item.id} className="flex flex-row w-full px-4 mb-2">
          <input
            type="text"
            placeholder="Category"
            value={item.name}
            onChange={(e) => handleInputChange(index, e.target.value)}
            className="border border-primary w-[90%] h-12 rounded-[10px] px-3 outline-none"
          />
          <div className="w-[10%] h-12 flex justify-center items-center">
            <div
              className={`w-[50px] h-full rounded-full shadow-sm border flex justify-center items-center text-2xl cursor-pointer ${
                index === items.length - 1 ? 'text-primary' : 'text-red-500'
              }`}
              onClick={() => (index === items.length - 1 ? handleAddItem(index) : handleDeleteItem(index))}
            >
         
              {index === items.length - 1 ? <FaPlus /> : <RxCross2 />}
            </div>
          </div>
        </div>
      ))}
    </div>
         </div>
      

          <div className='flex justify-center w-full    mt-5'>
          <button
            onClick={handleSubmit}
           className='btn-primary w-11/12 sm:w-[321px] text-center shadow-md'
          >
  { createLoading ? 
  <PulseLoader size={10} color='#fff'/>:'Submit'
  }
    </button>
          </div>

       


       </div>
  )
}

export default Addsubcategory