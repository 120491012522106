import React from 'react'
const Profile = ({singleUser}) => {
  return (
    <div className='flex flex-col gap-4  px-3'>
        <div className='w-full flex flex-col space-y-3 pr-0 lg:pr-3'>
            <p className='text-dark text-opacity-70 font-semibold'>Profile Name</p>
            <input type='text' value={singleUser.username} className=' w-full lg:w-1/2 h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        {/* <div className='w-full flex flex-col lg:flex-row  gap-3 '>
        <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>First Name</p>
            <input type='text' placeholder='Muhammad' className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Last Name  </p>
            <input type='text' placeholder='Ahmad' className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        </div> */}

        <div className='w-full flex flex-col lg:flex-row  gap-3'>
        <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Contact No</p>
            <input type='number'  value={singleUser.phone} className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Email</p>
            <input type='email' value={singleUser.email}  className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        {/* <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Viber  </p>
            <input type='number' value={singleUser.phone} className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div> */}
        </div>
        <div className='w-full flex flex-col lg:flex-row  gap-3'>
       
        {/* <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Password  </p>
            <input type='password' value={singleUser.password} className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div> */}
        </div>
{/* 
        <div className='w-full flex flex-col lg:flex-row gap-3'>
        <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Language</p>
            <input type='text' placeholder='English' className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        <div className='w-full lg:w-1/2 flex flex-col space-y-3 '>
            <p className='text-dark text-opacity-70 font-semibold'>Reporting Currency  </p>
            <input type='text' placeholder='USD' className='w-full h-[60px] rounded-[10px] px-3 border outline-none '/>
        </div>
        </div> */}
    </div>
  )
}

export default Profile