import React,{useEffect,useState} from 'react'
import Layout from '../../components/global/layout'
import Flipssetting from '../../components/profitsetting/flipssetting';
import {fetchSetting} from '../../redux/actions/settingAction'
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import CurrentTime from '../../components/global/currenttime'


const Profitsetting = () => {

 
  const dispatch=useDispatch();
  const {userSettings,settingsLoading,settingsError,addUserLoading}=useSelector((state)=>state.setting)
 useEffect(()=>{
  dispatch(fetchSetting())
},[dispatch])
useEffect(() => {
  if (settingsError) {
      toast.error(settingsError);
  }
}, [settingsError]);



if (settingsLoading) {
  return (
    <Layout>
      <div className='min-h-screen h-full px-4 pt-4'>
        <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
          <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
        </div>

        <div className='w-full mt-5'>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-8'>

            <div className='bg-pure rounded-[10px] shadow-md px-4 py-6'>
              <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full mb-4' />
              <ShimmerTitle width='100%' height='30px' className='rounded-[10px] w-full' />
              <div className='flex justify-center mt-6'>
                <ShimmerTitle width='80%' height='40px' className='rounded-[10px]' />
              </div>
            </div>

            <div className='bg-pure rounded-[10px] shadow-md px-4 py-6'>
              <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full mb-4' />
              <ShimmerTitle width='100%' height='30px' className='rounded-[10px] w-full' />
              <div className='flex justify-center mt-6'>
                <ShimmerTitle width='80%' height='40px' className='rounded-[10px]' />
              </div>
            </div>

            <div className='bg-pure rounded-[10px] shadow-md px-4 py-6'>
              <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full mb-4' />
              <ShimmerTitle width='100%' height='30px' className='rounded-[10px] w-full' />
              <div className='flex justify-center mt-6'>
                <ShimmerTitle width='80%' height='40px' className='rounded-[10px]' />
              </div>
            </div>

            
            <div className='bg-pure rounded-[10px] shadow-md px-4 py-6'>
              <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full mb-4' />
              <ShimmerTitle width='100%' height='30px' className='rounded-[10px] w-full' />
              <div className='flex justify-center mt-6'>
                <ShimmerTitle width='80%' height='40px' className='rounded-[10px]' />
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}

  return (
    <Layout>
            <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-lg font-semibold'>Profit Setting</h1>
             <CurrentTime/>     
                  </div>
        
         </div> 
         <Flipssetting userSettings={userSettings} addUserLoading={addUserLoading}/>
     
      
         </div> 
    </Layout>
  )
}

export default Profitsetting
