import React, { useEffect, useState } from 'react';

const CurrentTime = () => {
  const [currentDate, setCurrentDate] = useState('');
  useEffect(() => {
    const formatDate = () => {
      const date = new Date();
      const dayName = date.toLocaleString('en-US', { weekday: 'long' });
      const monthName = date.toLocaleString('en-US', { month: 'long' });
      const day = date.getDate();
      const year = date.getFullYear();
      const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      const timeZoneOffset = date.getTimezoneOffset() / 60;
      const timeZone = `GMT${timeZoneOffset > 0 ? '-' : '+'}${Math.abs(timeZoneOffset)}`;
      return `${dayName} · ${monthName} ${day}, ${year} · ${time} ${timeZone}`;
    };
    setCurrentDate(formatDate());
  }, []);
  return (
    <div className='text-[15px] text-primary font-semibold'>
      {currentDate}
    </div>
  );
};
export default CurrentTime;
