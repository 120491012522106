import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import Layout from '../../components/global/layout';

const Success = () => {
  return (
    <Layout>
    <div className="w-full flex justify-center items-center mt-10">
      <div className="w-96 h-auto border border-primary flex flex-col items-center pb-10 rounded-[10px]">
        <p className="py-3 text-2xl">Payment Successful!</p>
        <p>Your payment was successful. Thank you for your purchase!</p>
        <div className="w-full py-4 px-6">
          <Link to="/" className="bg-primary text-white w-full py-3 rounded-[10px] text-center">
            Go to Home
          </Link>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default Success;
