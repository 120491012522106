import React,{useEffect,useState,useRef} from 'react'
import Layout from '../../components/global/layout';
import CurrentTime from '../../components/global/currenttime';
import Activeusercard from '../../components/activeuser/activeusercard';
import Activeusertable from '../../components/activeuser/activeusertable'
import {fetchActiveUsers,fetchCountryActive} from '../../redux/actions/activeAction';
import { useDispatch,useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import {ShimmerTitle } from 'react-shimmer-effects';
import EarthSvg from '../../assets/svgs/EarthSVg';
import { getNames, getCode } from 'country-list';
import useToggle from '../../hooks/useToggle';
import useClickOutside from '../../utils/clickOutside';

const Activeuser = () => {
  const dispatch=useDispatch();
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, toggleDropdown] = useToggle(false);
  useClickOutside(dropdownRef, () => toggleDropdown(false));
const {activeuser,loading,error,activeuserstats,pages,currentPage,pageSize}=useSelector((state)=>state.activeUser);
const [currentActiveUsers, setCurrentActiveUsers] = useState(0);
console.log("current active",currentActiveUsers);

const socket = io('https://reflip.raabtastore.com');

useEffect(() => {
    socket.on('connect', () => {
        console.log('Connected to the server');
        socket.emit('ping');
    });
    socket.on('currentActiveUsers', (data) => {
        setCurrentActiveUsers(data);
        console.log(`Current active users: ${data}`);
    });
    socket.on('disconnect', () => {
        console.log('Disconnected from the server');
    });
    return () => {
        socket.off('connect');
        socket.off('currentActiveUsers');
        socket.off('disconnect');
        socket.disconnect();
    };
}, [socket]);



useEffect(()=>{
  dispatch(fetchActiveUsers());
},[dispatch])


const countries = getNames().map((country) => ({
  name: country,
  code: getCode(country)
}));

const handleSelect = (code, name) => {
  dispatch(fetchCountryActive(code));
  setSearchTerm(name); 
  toggleDropdown(false);

};
const filteredCountries = countries.filter(country =>
  country.name.toLowerCase().includes(searchTerm.toLowerCase())
);


if (loading) {
  return (
    <Layout>
    <div className='min-h-screen h-full px-4 pt-4  '>
    <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
  <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
</div>

<div className='py-6 bg-white rounded-[10px]'>
  <div className='grid grid-cols-2 md:grid-cols-4 gap-3 px-3'>
    <div className='bg-lightgray rounded-[10px] px-3 py-4 border'>
      <ShimmerTitle width='100%' height='16px' className='mb-2' />
      <ShimmerTitle width='100%' height='24px' />
    </div>
    <div className='bg-lightgray rounded-[10px] px-3 py-4 border'>
      <ShimmerTitle width='100%' height='16px' className='mb-2' />
      <ShimmerTitle width='100%' height='24px' />
    </div>
    <div className='bg-lightgray rounded-[10px] px-3 py-4 border'>
      <ShimmerTitle width='100%' height='16px' className='mb-2' />
      <ShimmerTitle width='100%' height='24px' />
    </div>
    <div className='bg-lightgray rounded-[10px] px-3 py-4 border'>
      <ShimmerTitle width='100%' height='16px' className='mb-2' />
      <ShimmerTitle width='100%' height='24px' />
    </div>
 
  </div>
</div>

      <div className='w-full  py-6'>
        <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
          <table className='table-auto w-full'>
            <thead className='border-b'>
              <tr>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
                <th className='shimmer px-6 py-4'></th>
              </tr>
            </thead>
            <tbody>
              {Array(10).fill(null).map((_, index) => (
                <tr key={index}>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                  <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                    <ShimmerTitle  />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </Layout>
  );
}



  return (
    <Layout>
      <div className='min-h-screen h-full px-4 pt-4     '>
      <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
      <div className='flex flex-col py-4'>
            <h1 className='text-lg font-semibold'>Real-Time Activity Report</h1>
            <CurrentTime/>
             </div>

                             <div 
                                className='flex flex-row items-center bg-white gap-2 py-1 sm:py-2 px-3 w-[320px] sm:w-[384px] rounded-[10px] cursor-pointer relative'             onClick={() => toggleDropdown()} >
                                <span>
                                    <EarthSvg/>
                                </span>

                                <input type='text' placeholder='Select a Country' className='w-full outline-none  '        value={searchTerm}          onChange={(e) => setSearchTerm(e.target.value)}/>

                            
                           {isOpen && (
                <div ref={dropdownRef} className='absolute w-full h-44 overflow-auto bg-white top-14 sm:top-12 left-0'>
                    <ul>
                        {filteredCountries.map((country) => (
                            <li 
                                key={country.code} 
                                className='py-2 px-2 hover:bg-primary hover:text-white text-base' 
                                onClick={() => {
                                    handleSelect(country.code, country.name);
                                    toggleDropdown(); 
                                }}
                            >
                                {country.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
                        
                              
                               
                            </div> 
                           



       </div>
 
       <Activeusercard   activeuserstats={activeuserstats} currentActiveUsers={currentActiveUsers}/>
       <Activeusertable   activeuser={activeuser} pages={pages} currentPage={currentPage} pageSize={pageSize}/>
        
      </div>
    </Layout>
  )
}

export default Activeuser;
