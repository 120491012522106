import React from 'react';
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';

const DashboardCard = ({ adminDashboard, adminLoading }) => {

  
 

  const totalCampaigns =
  adminDashboard.compaigns +
  adminDashboard.flipShares +
  adminDashboard.users +
  adminDashboard.adminProfit ;

  const calculatePercentage = (count) => ((count / totalCampaigns) * 100).toFixed(2) || 0;
  
  return (
    <div className='w-full'>
      {adminLoading ? (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-4'>
      
          <div className='bg-white rounded-[10px] border shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              <ShimmerTitle width='100%' height='24px' />
            </div>
          </div>
          <div className='bg-white rounded-[10px] border shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              <ShimmerTitle width='100%' height='24px' />
            </div>
          </div>
          <div className='bg-white rounded-[10px] border shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              <ShimmerTitle width='100%' height='24px' />
            </div>
          </div>
          <div className='bg-white rounded-[10px] border shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              <ShimmerTitle width='100%' height='24px' />
            </div>
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-4'>
          <div className='bg-pure rounded-[10px] border-l-[5px] border-[#0060F0] shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <p className='text-[#0060F0] text-sm font-semibold'>All Flip Campaign</p>
              <h2 className='text-xl leading-[50px] font-semibold'>{adminDashboard.compaigns}</h2>
              <div className='flex flex-row gap-2'>
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span className='text-sm text-[#0060F0]'>{calculatePercentage(adminDashboard.compaigns)}%</span>
              </div>
            </div>
          </div>
          {/* Repeat the same structure for the remaining cards */}
          <div className='bg-pure rounded-[10px] border-l-[5px] border-[#6900C7] shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <p className='text-[#6900C7] text-sm font-semibold'>All Flip Shared</p>
              <h2 className='text-xl leading-[50px] font-semibold'>{adminDashboard.flipShares}</h2>
              <div className='flex flex-row gap-2'>
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span className='text-sm text-[#6900C7]'>{calculatePercentage(adminDashboard.flipShares)}%</span>
              </div>
            </div>
          </div>
          <div className='bg-pure rounded-[10px] border-l-[5px] border-primary shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <p className='text-primary text-sm font-semibold'>All Users</p>
              <h2 className='text-xl leading-[50px] font-semibold'>{adminDashboard.users}</h2>
              <div className='flex flex-row gap-2'>
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span className='text-sm text-[#00AC69]'>{calculatePercentage(adminDashboard.users)}%</span>
              </div>
            </div>
          </div>
          <div className='bg-pure rounded-[10px] border-l-[5px] border-[#00AC69] shadow-md'>
            <div className='flex flex-col px-5 py-4'>
              <p className='text-[#00AC69] text-sm font-semibold'>Earnings</p>
              <h2 className='text-xl leading-[50px] font-semibold'>{adminDashboard.adminProfit}</h2>
              <div className='flex flex-row gap-2'>
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.4998 8.33333V5H14.1665" stroke="#00AC69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span className='text-sm text-[#00AC69]'>{calculatePercentage(adminDashboard.adminProfit)}%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
