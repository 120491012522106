import Axios from "../../config/api";
import { format } from 'date-fns';

import { setAllPayments,setSinglePayment,setSinglePaymentLoading, setLoading, setError,setSingleUserPayment,setSingleUserPaymentLoading,setCurrentPage,setPages,setDateRangeAllPayments,setSerachAllPayments,setPageSizeAllPayments,setPageSize   } from '../reducers/paymentbeilingReducer';

export const fetchPayment = (page=1) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        let url = `/wallet-history?page=${page}`;
        const { data: { data: { docs, pages, page: currentPage  } } } = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });

        dispatch(setAllPayments(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};



export const fetchSinglePayment = (id) => async (dispatch , getState) => {
    try {
        dispatch(setSinglePaymentLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.get(`/wallet-history/${id}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setSinglePayment(doc));
        dispatch(setSinglePaymentLoading(false));
    } catch (err) {
        dispatch(setSinglePaymentLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}



export const fetchUserSinglePayment = (id,page=1) => async (dispatch , getState) => {
    try {
        dispatch(setSingleUserPaymentLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs, pages, page: currentPage  } } } = await Axios.get(`/wallet-history/user/${id}?page=${page}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setSingleUserPayment(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setSingleUserPaymentLoading(false));
    } catch (err) {
        dispatch(setSingleUserPaymentLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}

export const fetchPaymentDateRange  = (datestart,dateend) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/wallet-history?from=${datestart}&to=${dateend}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setDateRangeAllPayments(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};



export const fetchAllPaymentsSearch = (keyword) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs } } } = await Axios.get(`/wallet-history?keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        dispatch(setSerachAllPayments(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};

export const fetchPaymentSize = (pageSize) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/wallet-history?pageSize=${pageSize}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setPageSizeAllPayments(docs));
        dispatch(setLoading(false));
        dispatch(setPageSize(pageSize));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};

