
import React,{useEffect,useState} from 'react';
import {fetchSingleCampaign} from '../../redux/actions/singlecompaigmsAction';
import { useDispatch,useSelector } from 'react-redux';
import { PulseLoader,ClipLoader } from 'react-spinners';



const ApproveCampaignPopup = ({ onClose, onApprove ,Id}) => {
    const [isLoading, setIsLoading] = useState(false); 

   const dispatch=useDispatch();
  const {campaignsingle}=useSelector((state)=>state.singlecampaign);
     useEffect(()=>{
       if(Id){
           dispatch(fetchSingleCampaign(Id))
       }
     },[dispatch])


     const handleConfirm = async () => {
        setIsLoading(true); 
        await onApprove(); 
        setIsLoading(false); 
    
      }

   


  return (
    <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50'>
      <div className='w-full h-screen flex justify-center items-center'>
        <div className='w-11/12 sm:w-1/2 lg:w-1/3 bg-[#F2F6FC] h-[200px] rounded-md flex flex-col justify-center items-center relative'>
          <div className='flex flex-col items-center justify-center text-center p-4'>
            <h2 className="text-lg font-semibold">
              Confirm Campaign Approval
            </h2>
            <p>Are you sure you want to approve this campaign?</p>
            <div className="flex justify-end mt-4">
              <button 
                className="mr-2 px-4 py-2 bg-gray-300 rounded" 
                onClick={onClose}
              >
                Cancel
              </button>
              <button 
        className={`px-4 py-2 ${campaignsingle.status === 'running' ? 'bg-gray-400' : 'bg-green-500'} text-white rounded`} 
        onClick={handleConfirm}
        disabled={campaignsingle.status === 'running'}
    >
     {isLoading ? <PulseLoader size={10} color="#ffffff" /> :      (campaignsingle.status === 'running' ? 'Approved' : 'Approve') }
    </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApproveCampaignPopup;
