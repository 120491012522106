import React,{useState,useEffect} from 'react'
import './style.css'
import cloud from '../../assets/svgs/feather_upload-cloud.svg'
import { useDispatch } from 'react-redux'
import { addSetting } from '../../redux/actions/settingAction'
import { toast } from 'react-toastify'
import { convertToDataURL } from "../../utils/convertToDataURL";
import { PulseLoader } from 'react-spinners';

const Companysetupform = ({userSettings,addUserLoading}) => {
  const [description, setDescription] = useState(userSettings?.description || '');
  const [companyName, setCompanyName] = useState(userSettings?.companyName || '');
  const [tagLine, setTagLine] = useState(userSettings?.tagLine || '');
  const [logo, setlogo] = useState(userSettings?.logo || '');
    const [fileName,setFileName]=useState('');
    
    
  const dispatch = useDispatch();

  useEffect(() => {
    setCompanyName(userSettings?.companyName || '');
    setDescription(userSettings?.description || '');
    setTagLine(userSettings?.tagLine || '');
    setFileName(userSettings?.logo || '');
  }, [userSettings]);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); 
      const imageUrl = await convertToDataURL(file);
      setlogo(imageUrl); 
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
        description,
        companyName,
        tagLine,
        logo
    };

       dispatch(addSetting(data, 'update', toast));
};
  return (
    <div className='w-full  mt-5'>
    <form className='w-full flex flex-col space-y-5' onSubmit={handleSubmit}>
    <div className='flex flex-col  lg:flex-row gap-3   '>
    <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Campaign Name  </label>
       <input type='text'
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        className='w-full py-4  rounded-[10px] outline-none px-3 text-[13px] h-12 bg-[#FBFCFE]  '/>
    </div>
    <div className="w-full lg:w-1/2 flex flex-col space-y-2">
    <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>logo  </label>
    <div className='flex flex-row justify-between items-center px-2 w-full bg-[#FBFCFE] rounded-[10px]  py-[1px] h-auto sm:h-12'>
    <div className='flex flex-row items-center gap-2'>
       <div>
        <img src={cloud} alt='img' />
       </div>
       <div className='flex flex-col'>
       <h1 className=' text-sm'>{fileName}</h1>
       <p className='text-[13px] text-black text-opacity-60'>JPG, OR PNG, file size no more than 10MB</p>
       </div>
    </div>
    <label htmlFor="myfile" className="custom-file-upload flex justify-center items-center">
       <h1 className='text-primary text-xs sm:text-base'> Select file</h1>
      <input
        type="file"
        id="myfile"
        name="myfile"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </label>
    </div>
   

    </div>


    </div>
    <div className='w-full  flex flex-col space-y-2 '>
    <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Description  </label>
    <textarea 
        value={description}  
        onChange={handleDescriptionChange}
     className='h-[151px] bg-[#FBFCFE] outline-none px-3  rounded-[10px] border text-black text-opacity-60 py-2 '/>
    

    </div>
    <div className='flex flex-col  lg:flex-row gap-3   '>
    {/* <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Color  </label>
       <input type='text' placeholder='Color 1 ' className='w-full py-4  rounded-[10px] outline-none px-3 border text-[13px] bg-[#FBFCFE] '/>
    </div> */}
    <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Tag Line  </label>
       <input type='text'
         value={tagLine}
         onChange={(e) => setTagLine(e.target.value)}
          className='w-full h-12  rounded-[10px] outline-none px-3 border text-[13px] bg-[#FBFCFE] '/>
    </div>
    </div>
    <div className='flex flex-col items-center   md:flex-row justify-center   gap-3 mt-2   '>
      <button className='bg-primary text-white w-full  sm:w-[321px]  py-3 rounded-[10px]'>
      {addUserLoading==="update"
            ? <PulseLoader size={10} color='#fff' />
            : 'Submit'
        }
      </button>
    </div>


    </form>
    </div>
  )
}

export default Companysetupform
