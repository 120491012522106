import { createSlice } from '@reduxjs/toolkit';

const flipshareSlice = createSlice({
    name: 'flipshare',
    initialState: {
        flipshare: [],  
        loading: false,
        error: null,
        pages: 1,
        currentPage: 1,
        pageSize:10,
    },
    reducers: {
        setFlipshares(state, action) {
            state.flipshare = action.payload; 
        },
        setSerachFlipshares(state, action){
            state.flipshare = action.payload; 
        },
        setPagesizeFlipshares(state, action){
            state.flipshare = action.payload; 
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        setDateRangeFlipshare(state, action){
            state.flipshare = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        },
        setPageSize(state, action){
            state.pageSize = action.payload;
        }
    },
});
export const { setFlipshares, setLoading, setError, setCurrentPage, setPages,setDateRangeFlipshare,setSerachFlipshares,setPagesizeFlipshares,setPageSize } = flipshareSlice.actions;
export default flipshareSlice.reducer;
