import { createSlice } from '@reduxjs/toolkit';

const userManagementSlice = createSlice({
    name: 'AdminManagement',
    initialState: {
        admins: [],
        adminManagementLoading: false,
        addadminManagementLoading:false,
        removeadminManagementLoading:false,
        error: null,
        pages: 1,
        currentPage: 1,
    },
    reducers: {
        setAdmins(state, action) {
            state.admins = action.payload;
        },
        addAdmins(state , action) {
            state.admins.unshift(action.payload)
        } ,
        setAdminManagementLoading(state, action) {
            state.adminManagementLoading = action.payload;
        },
        setAddAdminManagementLoading(state, action) {
            state.addadminManagementLoading = action.payload;
        },
        removeAdminManagement (state , action) {
            state.admins = state.admins.filter(item => item._id !== action.payload);
        } ,
        setremoveadminManagementLoading(state, action) {
            state.removeadminManagementLoading= action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        }
    },  
});
export const { setAdmins,addAdmins, setAdminManagementLoading,setAddAdminManagementLoading, setremoveadminManagementLoading,removeAdminManagement, setError,setCurrentPage, setPages } = userManagementSlice.actions;
export default userManagementSlice.reducer;