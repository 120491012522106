import React,{useEffect,useState} from 'react'
import Layout from '../../components/global/layout'
import Companysetupform from '../../components/companysetup/companysetupform'
import { useSelector,useDispatch } from 'react-redux'
import {fetchSetting}  from  '../../redux/actions/settingAction'
import { ShimmerCard, ShimmerTitle } from 'react-shimmer-effects'; 
import CurrentTime from '../../components/global/currenttime'

const Companysetup = () => {
  const  dispatch=useDispatch();
  const {userSettings,settingsLoading,addUserLoading,settingsError}=useSelector((state)=>state.setting);
  useEffect(()=>{
    dispatch(fetchSetting())
  },[dispatch])
  if (settingsLoading) {
    return (
      <Layout>
        <div className='min-h-screen h-full px-4 pt-4'>
          <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
            <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
          </div>
          
          <div className='w-full mt-5 bg-white px-4 py-3 rounded-[10px]'>
            <form className='w-full flex flex-col space-y-5'>
              <div className='flex flex-col lg:flex-row gap-3'>
                
                <div className='w-full lg:w-1/2 flex flex-col space-y-2'>
                  <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
                  <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full' />
                </div>
                
                <div className="w-full lg:w-1/2 flex flex-col space-y-2">
                  <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
                  <ShimmerTitle width='100%' height='60px' className='rounded-[10px] w-full' />
                </div>
              </div>
              
              <div className='w-full flex flex-col space-y-2'>
                <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
                <ShimmerTitle width='100%' height='80px' className='rounded-[10px] w-full' />
              </div>
              
              <div className='w-full flex flex-col space-y-2'>
                <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
                <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full' />
              </div>
              
              <div className='flex flex-col items-center md:flex-row justify-center gap-3 mt-2'>
                <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full sm:w-[321px]' />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }

  
  return (
    <Layout>
      <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-lg font-semibold'>Company Setup</h1>
                <CurrentTime/>        
               </div>      
         </div>
         <Companysetupform   userSettings={userSettings}  addUserLoading={addUserLoading}/>
         </div>
    </Layout>
  )
}

export default  Companysetup
