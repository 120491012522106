import React,{useState} from 'react';
import Search from '../../assets/svgs/search.svg';

const Searchbox = ({ keyword, onSearch, onChange }) => {
  const [inputValue, setInputValue] = useState(keyword);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  return (
    <form onSubmit={handleSubmit} className="w-[276px] flex flex-row bg-aliceBlue rounded-lg">
      <div className="w-[90%]">
        <input
          type="text"
          placeholder="Search"
          value={inputValue}
          onChange={handleInputChange}
          className="w-full h-full bg-aliceBlue outline-none rounded-tl-lg py-2 rounded-bl-lg px-3 text-[15px]"
        />
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <button type="submit" aria-label="Search">
          <img src={Search} alt="Search" className="w-4" />
        </button>
      </div>
    </form>
  );
};

export default Searchbox;