
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DateRangePopup = ({ startDate, endDate, setStartDate, setEndDate, onApply, onClose }) => {
    return (
        <div className="fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50">
            <div className="w-full h-screen flex justify-center items-center ">
            <div className='bg-pure w-11/12 sm:w-2/4 lg:w-1/3 flex flex-col justify-center h-52 gap-3   rounded-md'>
            <div className='flex justify-center '>  
               <h3 className='text-lg font-semibold'>Select Date Range</h3>
               </div>
            <div className='flex flex-row w-full gap-3  px-3 '>
    <div className='w-1/2 flex flex-col gap-1 '>
        <label className='font-semibold' htmlFor='start-date'>Start Date</label>
       <div className='relative w-full h-8 border border-primary border-opacity-50 rounded-md px-2 flex items-center'>
       <DatePicker 
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className='w-full h-full outline-none'
            id='start-date'
        />

<div className='absolute right-0 top-1/2 transform -translate-y-1/2'>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M8 2V5M16 2V5M20.75 17.6H3.25M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#51087E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z" fill="#51087E"/>
                    </svg>
    </div>

       </div>
     
    </div>

    <div className='w-1/2 flex flex-col gap-1'>
        <label className='font-semibold' htmlFor='end-date'>End Date</label>
        <div className='relative w-full h-8 border border-primary border-opacity-50 rounded-md px-2 flex items-center'>
        <DatePicker 
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate} 
            className='w-full outline-none'
            id='end-date'
        />


<div className='absolute right-0 top-1/2 transform -translate-y-1/2'>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M8 2V5M16 2V5M20.75 17.6H3.25M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#51087E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z" fill="#51087E"/>
                    </svg>
    </div>

        </div>
      
    </div>
</div>

            <div className='w-full flex justify-center '>
            
            <button onClick={onApply} className='ml-2 bg-blue-500 text-white px-3 py-1 rounded'>
                    Apply
                </button>
                <button onClick={onClose} className='ml-2 bg-red-500 text-white px-3 py-1 rounded'>
                    Close
                </button>

            </div>

            </div>
           
               
             
            </div>
        </div>
    );
};

export default DateRangePopup;
