import { createSlice } from "@reduxjs/toolkit";
const singleUserSlice = createSlice({
    name: 'singleUser',
    initialState: {
        singleUser: {}, 
        loading: false, 
        error: null 
    },
    reducers: {
        setSingleUser(state, action) {
            state.singleUser = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
       
    }
});
export const { setSingleUser, setLoading, setError} = singleUserSlice.actions;
export default singleUserSlice.reducer;
