import React,{useEffect,useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { fetchBlock } from "../../redux/actions/alluserAction";
import { toast } from 'react-toastify';
import { fetchSingleUser } from '../../redux/actions/singleuserAction';
import { PulseLoader,ClipLoader } from 'react-spinners';

const Confrimpopup = ({ onClose, BlockId,onConfirm }) => {
   const dispatch=useDispatch();
   const { singleUser,loading} = useSelector(state => state.singleuser);
   const [isLoading, setIsLoading] = useState(false); 
  useEffect(()=>{
      if(BlockId){
        dispatch(fetchSingleUser(BlockId))
      }
  },[dispatch,BlockId]);

  
  const handleConfirm = async () => {
    setIsLoading(true); 
    await onConfirm(); 
    setIsLoading(false); 

  }

    return (
        <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-50'>
            <div className='w-full h-screen flex justify-center items-center'>

            
                <div className=' w-11/12 sm:w-1/2 lg:w-1/3 bg-[#F2F6FC] h-[200px] rounded-md flex flex-col justify-center items-center relative'>
                {
                loading  ?  (
                 
                  <div className='flex justify-center items-center w-full h-full'>
            <ClipLoader size={35} color='#51087E' />
            </div>

                ):(

                  <div className='flex flex-col items-center justify-center'>
                 <h2 className="text-lg font-semibold">
                        {singleUser.isActive ? 'Confirm Block User' : 'Confirm Unblock User'}
                    </h2>
                    <p>Are you sure you want to {singleUser.isActive ? 'block' : 'unblock'} this user?</p>
                    <div className="flex justify-end mt-4">
                        <button className="mr-2 px-4 py-2 bg-gray-300 rounded" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="px-4 py-2 bg-red-500 text-white rounded"   onClick={handleConfirm}  >
                        {isLoading ? <PulseLoader size={10} color="#ffffff" /> : (singleUser.isActive ? 'Block' : 'Unblock')}
                        </button>
                    </div>

                 </div>
                  
                )

              }
                
               
                  
                </div>
            </div>
        </div>
    );
};

export default Confrimpopup;
