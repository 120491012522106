import Axios from "../../config/api";
import { addBanner,removeBanner,updateBanner,setBanners,setLoading,setCreateLoading,setUpdateLoading,setDeleteLoading,setsingleBanner,
  setSingleBannerLoading} from "../reducers/bannerReducer";



export const createBanner = (data, toast) => async (dispatch, getState) => {
  try {
    dispatch(setCreateLoading(true));
    const { token } = getState().auth.user;
    const { data : { data : { doc } } }    = await Axios.post('/banner', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(addBanner(doc));
    dispatch(setCreateLoading(false));
    toast.success('Banner added successfully.');
  } catch (err) {
    dispatch(setCreateLoading(false));
    toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
  }
};
export const updatingBannners = (id , data , toast) => async (dispatch , getState) => {
  try {
      dispatch(setUpdateLoading({ id, loading: true}));
      const { token } = getState().auth.user;
      const { data : { data : { doc } } } = await Axios.put(`/banner/${id}` , data  , 
      { headers : { Authorization : `Bearer ${token}`} });
      dispatch(updateBanner(doc));
      dispatch(setUpdateLoading({ id, loading: false}));
      toast.success('Updated Successfully.');
  } catch (err) {
      dispatch(setUpdateLoading({ id, loading: false}));
      console.log('Update Category error' , err);
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
  }
}

export const getTotalBanners = (toast) => async (dispatch) => {
    
    try {
        dispatch(setLoading(true));
        const { data: { data: { docs } } } = await Axios.get('/banner/all');
        dispatch(setBanners(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));      
          console.error('Get Total banners error:', err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
};

export const getSingleBanners = (id, toast) => async (dispatch) => {
  try {
    dispatch(setSingleBannerLoading(true));
    const { data: { data: { doc } } } = await Axios.get(`/banner/${id}`);
    dispatch(setsingleBanner(doc));
    dispatch(setSingleBannerLoading(false));
  } catch (err) {
    dispatch(setSingleBannerLoading(false));
    console.error('Get Single Banner error:', err);
    toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
  }
};



export const removeSidebanner = (id , toast) => async (dispatch, getState ) => {
  try {
    dispatch(setDeleteLoading({ id, loading: true }));
      const { token } = getState().auth.user;
      await Axios.delete(`/banner/${id}` , 
          { headers : { Authorization : `Bearer ${token}`} }
      );
      dispatch(removeBanner(id));
      dispatch(setDeleteLoading({ id, loading: false }));
      toast.success('Deleted Successfully.');
  } catch (err) {
    dispatch(setDeleteLoading({ id, loading: false }));     
     console.log('Delete Sidebanner error' , err);
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
  }
}
