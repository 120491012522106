import React from 'react'
import Sidebar from './sidebar'
import Header from './header'

const Layout = ({children }) => {
  return (
    <div className='relative '>
       <Header/>
       <Sidebar />
       
       <div className='w-full  space pb-2  lg:pt-[var(--header-height)]'>
        {children }
     
       </div>
    
       
 </div>

    
  )
}

export default Layout



  