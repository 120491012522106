import React,{useRef,useState} from 'react'
import Searchbox from '../../components/global/searchbox'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import useClickOutside from '../../utils/clickOutside';
import useToggleOption from '../../hooks/usetoggleoption';
import {fetchRemoveAdmins} from '../../redux/actions/usermangmentAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { setCurrentPage } from '../../redux/reducers/usermangmentReducer';
import {fetchAdmins} from '../../redux/actions/usermangmentAction'


const Usermagmenttable = ({admins}) => {

  const dispatch=useDispatch();
    const tableRef = useRef(null);
    const [showOption,toggleOption]=useToggleOption();
    useClickOutside(tableRef, () => toggleOption(false));
   const handledelete=(Id)=>{
      dispatch(fetchRemoveAdmins(Id,toast))
   }


//    const handlePageChange = (newPage) => {
//     if (newPage > 0 && newPage <= pages) {
//         dispatch(fetchAdmins(newPage));
//         dispatch(setCurrentPage(newPage));
//     }
// };

//   const startIndex =  1;

  return (
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
    <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
    <div className='flex flex-col space-y-1'>
    <h1 className='text-primary font-semibold text-lg'>Admin Panel Users</h1>
    </div>
    </div>

    <div className=' w-full flex flex-col gap-3 sm:gap-0  sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
 
   
              
          
    </div>

      <div className='w-full px-6 py-6   '>
        <div className=' border rounded-[10px] pb-[3px]  w-full   overflow-auto custom-scrollbar z-40  '>
        <table className="  table-auto  w-full   ">
      <thead className='border-b ' >
          <tr>
              <th scope="col" className=" text-sm lg:text-base  font-semibold  px-6 py-4 text-left border-r   ">
              Sr. No.
              </th>
              <th scope="col" className="text-sm lg:text-base  font-semibold  px-6 py-4 text-left border-r">
              User Name
              </th>
              <th scope="col" className="text-sm lg:text-base  font-semibold  px-6 py-4 text-left border-r">
              Email            
                </th> 
              <th scope="col" className="text-sm lg:text-base  font-semibold  px-6 py-4 text-center border-r">
              Password               
              </th>
              <th scope="col" className="text-sm  lg:text-base  font-semibold  px-6 py-4 text-center">
              Action                 
              </th>
          </tr>
      </thead>
      <tbody>
          {
            admins.length > 0 ? (
                 
              admins.map((item, index) => (
            <tr className='relative' key={item._id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300"> {index + 1}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.username}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{item.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">N/A</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                <div className='border border-primary flex flex-row justify-between items-center rounded-[10px] py-1 px-2 max-w-[94px] cursor-pointer m-auto' onClick={() => toggleOption(index)}>
                  <span>
                    <img src={arrow} alt='img' />
                  </span>
                  <span>
                    <img src={setting} alt='img' />
                  </span>
                  {showOption === index &&
                    <div
                      className='bg-pure w-[94px] h-auto top-14 py-0 right-20 text-[15px] font-semibold rounded-[10px] absolute z-50 shadow-lg'
                      ref={tableRef}
                    >
                      <div className='flex flex-col space-y-[6px] w-full'>
                    
                        <div className='flex flex-row items-center justify-center text-primary gap-1 px-2 py-2' onClick={()=>handledelete(item._id)}> 
                          <span>Remove</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </td>
            </tr>
          ))

            ): (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center text-gray-500">No user found</td>
                </tr>
              )
          }
        </tbody>

      </table>
        </div>
     
      </div>

{/*    
      <div className="flex justify-between items-center px-6 py-3 ">
  
  <div className='text-primary'>
  Showing {startIndex} to {admins.length} of {admins.length} entries   
   </div>
  
   <div className='border flex flex-row rounded-md'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
</div>
            
          </div> */}


   

   
  </div>
  )
}

export default Usermagmenttable
