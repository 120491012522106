import React,{useState,useEffect} from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import Searchbox from '../global/searchbox';
import Loader from '../global/loader';
import { format, parseISO } from 'date-fns';
import calender from '../../assets/svgs/calendar.svg'

const Anlyticsdashboard = ({singlecampaign,loadingCampaign}) => {


  
  const [dateRange, setDateRange] = useState({ minDate: '', maxDate: '' });

  const formatDate = (date) => {
    return format(date, 'dd/MM/yyyy');
  };
  
  useEffect(() => {
    if (singlecampaign && singlecampaign.length > 0) {
      const dates = singlecampaign.map(doc => new Date(doc.createdAt));
      if (dates.length > 0) {
        const min = new Date(Math.min(...dates));
        const max = new Date(Math.max(...dates));
        setDateRange({
          minDate: formatDate(min),
          maxDate: formatDate(max)
        });
      }
    }
  }, [singlecampaign]);
  const dateRangeString = `${dateRange.minDate} - ${dateRange.maxDate}`;


  const rows = singlecampaign;
  const totalClicks = rows.reduce((acc, campaign) => acc + campaign.totalClicks, 0);
  const totalShares = rows.reduce((acc, campaign) => acc + campaign.totalShares, 0);
  const totalBudgetUsed = rows.reduce((acc, campaign) => acc + campaign.compaignBudget, 0);
  const totalEarnings = rows.reduce((acc, campaign) => acc + (campaign.compaignBudget - campaign.budgetUsedInCompaign), 0);
  return (
    <div className='flex flex-col space-y-6'>
    <div className='bg-pure w-full flex flex-row gap-5  px-3 py-4 rounded-[10px]'>
    <div className='flex flex-col gap-2 '>
                     
                     <label className='font-semibold'>Date Range</label>
                     <div className='relative w-full md:w-2/4 lg:w-[330px] border border-primary h-10 rounded-lg flex items-center justify-between px-2'>
                 <input
                   type="text"
                   className='rounded-[10px] w-full h-full outline-none px-2 text-dark'
                   value={dateRangeString}
                   readOnly
                 />
                 <div className='absolute inset-y-0 right-2 flex items-center'>
                   <img src={calender} alt='Calendar' className='h-6 w-6' />
                 </div>
               </div>
           
                     </div>
                  
</div>
 <div className='py-6 bg-white rounded-[10px]'>
  <div className='grid grid-cols-2 md:grid-cols-5 gap-3 px-3'>
  <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
    <p className='text-sm font-semibold'>Total Clicks </p>
    <h1 className='text-primary text-lg font-bold'>{totalClicks}</h1>
  </div>    
  <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
    <p className='text-sm font-semibold'>Dead End Clicks</p>
    <h1 className='text-primary text-lg font-bold'>0</h1>
  </div>  
  <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
    <p className='text-sm font-semibold'>Total Conversions</p>
    <h1 className='text-primary text-lg font-bold'>{totalShares}</h1>
  </div>  
  <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
    <p className='text-sm font-semibold'>Total Budget Used (USD)</p>
    <h1 className='text-primary text-lg font-bold'>${totalBudgetUsed}</h1>
  </div>  
  <div className='bg-lightgary  rounded-[10px] px-3 py-4 border '>
    <p className='text-sm font-semibold'>Total Estimated Earnings</p>
    <h1 className='text-primary text-lg font-bold'>${totalEarnings}</h1>
  </div>  

  </div>
  </div>


<div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
<div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
<div className='flex flex-col space-y-1'>
<h1 className='text-primary font-semibold text-lg'>Flip Campaigns Stats</h1>
<p className='text-primary text-base'>Details and history</p>
</div>

<div className='flex flex-row gap-3 ' >
    <div>

    <img src={Arrow} alt='img'/>
    </div>
    <div>
     <img src={Printer} alt='img'/>
    </div> 
</div>

</div>



<div className='w-full px-6 py-6  '>

{

 loadingCampaign ?

 (
     <Loader/>
 ):(
   <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
     <table className="  table-auto  w-full  ">
   <thead className='border-b ' >
       <tr>
           <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
           Sr
           </th>
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
           Date
           </th>
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
           Campaign Name
           </th>
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
           Clicks              
             </th> 
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
           Conversions                 
           </th>
           
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
           Budget(USD)               
           </th>
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r">
           Estimated Earnings                
           </th>
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-center   border-r">
           Conversion Rate (%)                
           </th>
           <th scope="col" className="text-base font-semibold  px-6 py-4 text-center">
           Status
           </th>
       </tr>
   </thead>
   <tbody>
   

   {rows.length > 0 ? rows.map((campaign, index) => (
             <tr key={campaign._id}>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{new Date(campaign.startDate).toLocaleDateString()}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{campaign.name}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{campaign.totalClicks}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{campaign.totalShares}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">${campaign.compaignBudget}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">${campaign.budgetUsedInCompaign}</td>
               <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">N/A</td>
               <td className={`px-6 py-4 whitespace-nowrap text-sm font-semibold ${campaign.status === 'expired' ? 'text-danger' : 'text-sucess'}`}>
  {campaign.status}
</td>
             </tr>
           )) : (
             <tr>
               <td colSpan="9" className="px-6 py-4 text-center">No campaigns available</td>
             </tr>
           )}


 

   
   

   </tbody>

   </table>
     </div>
 )
   
}

   
 
   </div>




</div>






</div>
  )
}

export default Anlyticsdashboard