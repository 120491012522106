import React,{useState} from 'react'
import Login from './pages/auth/login';
import Home from './pages/home'
import Alluser from './pages/alluser';
import Flipcampaigns from './pages/flipcampaigns';
import Flipshared from './pages/flipshared';
import Flipreferrals from './pages/flipreferrals';
import Flipcampaignsperfom from './pages/flipcampaignsperfom'
import Flipsharedstats from './pages/flipsharedstats';
import Paymentbilling from './pages/payment&billing/index'
import Category from './pages/category'
import Support from './pages/support';
import Profitsetting from './pages/profitsetting'
import Companysetup from './pages/companysetup';
import Banners from './pages/banners';
import Sidebanner from './components/banner/sidebanner';
import Notification from './pages/notification'
import Usermangment from './pages/usermangment';
import Flipcampaignsview from './pages/flipcampaignsview'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute.js';
import { useSelector } from "react-redux";
import Footer from './components/global/footer/index.js';
import Profileinformation from './pages/profileinformation/index.js';
import Flipcampaingstats from './pages/flipcampaingstats/index.js';
import Flipshareview from './pages/flipshareview/index.js'
import Subcategory from './pages/subcategory/index.js';
import Pay from './pages/pay/index.js'
import Success from './pages/pay/success';
import Cancel from './pages/pay/cancel.js';
import Allhistory from './pages/allhistory/index.js';
import Activeuser from './pages/activeuser';

import { BrowserRouter as Router , Routes , Route,Navigate } from "react-router-dom";
const App = () => {
  const [isLoginPage , setIsLoginPage] = useState(false);
  const user = useSelector((state) => state.auth.user); 


  return (

  <div className=''>
    <ToastContainer 
    style={{fontSize: 15}}
    position="top-center"
    autoClose={3000}
    hideProgressBar
    closeOnClick
    pauseOnHover
/>

    <Router>
      <Routes>
      <Route path='/' element={
                    user ? <Navigate to="/dashboard" replace /> : <Login setIsLoginPage={setIsLoginPage} />
                } />
                <Route path='/login' element={
                    user ? <Navigate to="/dashboard" replace /> : <Login setIsLoginPage={setIsLoginPage} />
                } />
      <Route path='/dashboard'  element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    }/>
                     <Route 
                    path='/login' 
                    element={<Login setIsLoginPage={setIsLoginPage} />} 
                    />
      <Route path='/alluser' element={<Alluser/>}/>
      <Route path='/alluser/:id' element={<Profileinformation />} />
      <Route path='/flipcampaigns' element={<Flipcampaigns/>}/>
      <Route path='/flipcampaigns/:id' element={<Flipcampaignsview/>}/>
      <Route path='/flipcampaigns/stats/:id' element={<Flipcampaingstats/>}/>
      <Route path='/flipshared' element={<Flipshared/>}/>
      <Route path='/flipshared/:id' element={<Flipshareview/>}/>
       <Route path='/flipreferrals' element={<Flipreferrals/>}/>
       <Route path='/perfomancestats' element={<Flipcampaignsperfom/>}/>
       <Route path='/sharedstats'  element={<Flipsharedstats/>}/>
       <Route path='/paymentbilling' element={<Paymentbilling/>}/>
       <Route path='/Category' element={<Category/>}/>
       <Route path='/Support' element={<Support/>}/>
       <Route path='/Profitsetting' element={<Profitsetting/>} />
       <Route path='/companysetup' element={<Companysetup/>} />
       <Route path='/banners' element={<Banners/>}/>
       <Route path='/sidebanner' element={<Sidebanner/>}/>
       <Route path='/notification' element={<Notification/>}/>
       <Route path='/usermangment' element={<Usermangment/>}/>
        <Route path='/subcategory' element={<Subcategory/>}/>
        <Route path='/pay' element={<Pay/>}/>
        <Route path='/success' element={<Success />} />
        <Route path='/cancel' element={<Cancel/>} />
        <Route path='/allhistory' element={<Allhistory/>} />
        <Route path='/activeuser' element={<Activeuser/>} />



      </Routes>
      { !isLoginPage &&  <Footer />}

    </Router>
    </div>
  )
}

export default App
