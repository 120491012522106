import { createSlice } from '@reduxjs/toolkit';
const categorySlice = createSlice({
    name : 'category' , 
    initialState : {
        categories : [] ,
        getLoading : false ,
        createLoading : false , 
        updateLoading : false ,
        deleteLoading : false ,
        error: null,

    } , 
    reducers : {
        addCategory (state , action) {
            state.categories.unshift(action.payload)
        } ,
        removeCategory (state , action) {
            state.categories = state.categories.filter(item => item._id !== action.payload);
        } ,
        setCategories (state , action) {
            state.categories = action.payload;
        } ,
        updateCategory (state , action) {
            const index = state.categories.findIndex(item => item._id === action.payload._id);
            state.categories[index] = action.payload;
        } ,
        setGetLoading(state , action) {
            state.getLoading = action.payload;
        } ,
        setUpdateLoading (state , action) {
            state.updateLoading = action.payload;
        } ,
        setDeleteLoading (state , action) {
            state.deleteLoading = action.payload;
        } ,
        setCreateLoading (state , action) {
            state.createLoading = action.payload;
        } , 
        setError(state, action) {
            state.error = action.payload;
        },
    }
});

export const {addCategory,removeCategory,setCategories, setGetLoading,setCreateLoading,setDeleteLoading,updateCategory,setUpdateLoading} = categorySlice.actions;
export default categorySlice.reducer;



