import React,{useState,useRef,useEffect} from 'react'
import bannerimage from '../../assets/svgs/frontbanner.svg'
import view from '../../assets/svgs/view.svg'
import deletes from '../../assets/svgs/delete.svg'
import { baseURL } from '../../config/api';
import {removeSidebanner,createBanner,getSingleBanners} from '../../redux/actions/bannerAction'
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {convertToDataURL} from '../../utils/convertToDataURL'
import { PulseLoader } from 'react-spinners';
import useToggleOption from '../../hooks/usetoggleoption';
import useClickOutside from '../../utils/clickOutside';
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'


const Frontbanner = ({banners,createLoading,singlebanner}) => {
  const [loading, setLoading] = useState(true);

const dispatch=useDispatch();
const tableRef = useRef(null);
const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');
const [country,SetCountry]=useState("PK");
const [type,setType]=useState("front");
const [image,setImage]=useState(bannerimage);
const [url, setUrl] = useState('');


const [isDropdownOpen, toggleDropdown] = useToggleOption(false);
useClickOutside(tableRef, () => handleDropdownToggle(false));
const [selectedBannerId, setSelectedBannerId] = useState(null);
// const [isEditClicked, setIsEditClicked] = useState(false);
const handleDropdownToggle = (index) => {
  toggleDropdown(index);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};



const handleEdit = (bannerId) => {
  setSelectedBannerId(bannerId);
  handleDropdownToggle(null); 
  dispatch(getSingleBanners(bannerId, toast));
};
useEffect(() => {
  if (singlebanner && singlebanner._id === selectedBannerId) {
    console.log("Single banner data:", singlebanner);
    setImage(`${baseURL}/${singlebanner.image}`);
    setStartDate(formatDate(singlebanner?.startDate));
    setEndDate(formatDate(singlebanner?.endDate));
    setUrl(singlebanner.url);
  }
}, [singlebanner, selectedBannerId]);





  



  const formatDashDate = (date) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }
    const [month, day, year] = date.split('-'); 
    
    if (!month || !day || !year) {
      console.error('Invalid date format:', date);
      return ''; 
    }
    return `${year}-${month}-${day}`;
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
   const frontbanners=banners.filter(entry => entry.type === 'front');
  const handleDelete=(Id)=>{
    dispatch(removeSidebanner(Id,toast))
  }

  const handleFileChange = async  (event) => {
    const file = event.target.files[0];
    if (file) {
        const imageUrl = await convertToDataURL(file);
        setImage(imageUrl);
    }
    else{
      setImage(bannerimage)
    }
   
};
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!startDate || !endDate) {
    toast.error('Please fill in all required fields.');
    return;
  }
  const formatDashStartDate = formatDashDate(startDate);
  const formatDashEndDate = formatDashDate(endDate);

  const data = { startDate: formatDashStartDate, endDate: formatDashEndDate, url,  type, country, image    };
    console.log(  'see the all data :',data)
  try { 
    await dispatch(createBanner(data, toast));
    setImage(bannerimage);
    setUrl('');
    setStartDate('');
    setEndDate('');

  } catch (error) {
    console.error('Error adding banner:', error);
    toast.error('Failed to add banner. Please try again.');
  }
};


  return (
  <div className='flex flex-col space-y-7'>
     <div className='w-full bg-pure flex flex-col space-y-8   mt-5  px-4 py-5 rounded-[10px] '>
    <div className='flex  flex-col md:flex-row gap-5 '>
    <div className='w-full md:w-2/5 '>
      <label className='text-center text-[#2F80ED]' for='input-file'>
      
      <img src={image} alt='img' />
      </label>
      <input type="file" name="picture" accept='image/jpeg, image/png ,image/jpg' className='hidden'  id='input-file' onChange={handleFileChange}/>
           </div>
       <div className='w-full md:w-3/5 flex flex-col space-y-3 '>
       <div className='flex flex-col space-y-2 w-full'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Description  </label>
    <textarea  className='h-[50px]  outline-none px-3  rounded-[10px] border border-primary text-black  py-2 '>
    Lorem Ipsum has been the industry's standard 
    </textarea>

       </div>

       <div className='flex flex-col space-y-2 w-full'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Duration </label>
       <div className='mb-0'>
     {startDate   ?   (
      <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>
            Start Date
          </label>
     )
     :(
      ""
     ) }   
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className='border border-primary rounded-[10px] w-full outline-none py-3 px-2 my-2 text-dark'
          />
        </div>
        {startDate   ?   (
          <div>
          <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className='border border-primary rounded-[10px] w-full outline-none py-3 px-2 my-2 text-dark'
          />
        </div>

        ):(
          ""
        )}
       
       </div>

       <div className='flex flex-col space-y-2 w-full'>
       <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Url </label>
       <input type="text"  

placeholder='https://www.lipsum.com/feed/html' 
value={url}
onChange={(e) => setUrl(e.target.value)}
className='border border-primary    rounded-[10px] w-full   outline-none py-3 px-2 my-2  text-black' 
        />
      
       </div>
      

      </div>
    </div>
      
      <div className='flex flex-row justify-center '>
         <button className='bg-primary text-pure font-medium hover:bg-opacity-85   w-full md:w-[321px] py-3 rounded-md shadow-md' onClick={handleSubmit} >
         {createLoading  ? (
                                <PulseLoader size={10} color='#fff' />
                            ) : (
                                'Submit'
                            )}
         </button>
      </div>
      
    </div>
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
    <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
    <div className='flex flex-col space-y-1'>
    <h1 className='text-primary font-semibold text-lg'>Front Banners</h1>
    <p className='text-primary text-base'>Details and history</p>
    </div>

 

    </div>

   

      <div className='w-full px-6 py-6  '>
        <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto  custom-scrollbar     '>
        <table className="  table-auto  w-full  ">
      <thead className='border-b ' >
          <tr>
              <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
              Sr
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Banner Image
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Duration
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Url             
                </th> 
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center border-r cursor-pointer">
              Action            
              </th>
              
           
          </tr>
      </thead>
      <tbody>
      {frontbanners.map((entry, index) => {
        const startDate = formatDate(entry.startDate);
        const endDate = formatDate(entry.endDate);
        const dateRange = `${startDate} - ${endDate}`;

        return (
          <tr key={entry._id}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
              {index + 1}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
              <img src={`${baseURL}/${entry.image}`} alt='img' className='h-24 w-24 object-cover' />
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
              {dateRange}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
              {entry.url}
            </td>
            <td className="px-6 py-4 whitespace-nowrap font-medium text-[#FF1400] border-r text-3xl   border-gray-300">

            <div className='border border-primary flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={() => handleDropdownToggle(index)}>
                                <span><img src={setting} alt='img' /></span>
                                <span><img src={arrow} alt='img' /></span>
                                {isDropdownOpen === index && (
                                  <div className='bg-white h-auto py-1 px-3   left-0 top-10 text-[15px] font-semibold rounded-[10px] absolute z-50' ref={tableRef}>
                                    <div className='flex flex-col space-y-[6px] w-full'>
                                      <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1 cursor-pointer'  onClick={()=>handleEdit(entry._id)} >
                                        <span><img src={view} alt='img' /></span>
                                        <span>Edit</span>
                                      </div>
                                     
                                      <div className='flex flex-row items-center gap-1 text-[#FF1400] px-2 py-1' onClick={()=>handleDelete(entry._id)}>
                                        <span><img src={deletes} alt='img' /></span>
                                        <span>Delete</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
          
            </td>
          </tr>
        );
      })}
       
       

      

       
      

      </tbody>

      </table>
        </div>
     
      </div>

   

   
  </div>

  </div>

  



  )
}

export default Frontbanner
