
const EarthSvg = () => {
  return (
<svg width="24" height="24" viewBox="0 0 512 512" version="1.1" >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icon" fill="#51087E" transform="translate(42.666667, 42.666667)">
            <path d="M213.333333,3.55271368e-14 C331.15408,3.55271368e-14 426.666667,95.5125867 426.666667,213.333333 C426.666667,331.15408 331.15408,426.666667 213.333333,426.666667 C95.5125867,426.666667 3.55271368e-14,331.15408 3.55271368e-14,213.333333 C3.55271368e-14,95.5125867 95.5125867,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,42.6666667 C209.750636,42.6666667 206.193723,42.7770611 202.66581,42.994634 L202.666667,91.0419601 L106.666667,170.666667 L58.5047006,141.436482 C48.3410689,163.286656 42.6666667,187.647331 42.6666667,213.333333 C42.6666667,280.812182 81.8284453,339.144307 138.664049,366.841756 L138.666667,313.749333 L96.8405175,271.925462 L84.8227238,211.836494 L101.5154,178.451142 L162.069333,202.666667 L233.251151,202.666667 L245.697346,289.790033 L198.893251,383.397727 C203.653732,383.79656 208.469775,384 213.333333,384 C273.542462,384 326.46952,352.821748 356.85013,305.72962 L322.730667,192 L291.444982,192 L265.650607,127.514062 L294.529796,69.755682 L342.398824,101.663555 C311.105166,65.5267525 264.888879,42.6666667 213.333333,42.6666667 Z M119.061333,219.925333 L126.314667,256.128 L170.666667,300.493989 L170.666667,351.978667 L178.816,351.978667 L212.608,284.416 L205.482667,234.645333 L155.908125,234.666667 L119.061333,219.925333 Z M306.944,116.48 L300.693333,128.96 L313.109333,160 L346.539703,160 L359.36,202.773333 L343.936,141.162667 L306.944,116.48 Z M170.645333,74.6666667 L138.965333,74.6666667 L100.010667,129.194667 L103.786667,131.477333 L170.645333,76.0106667 L170.645333,74.6666667 Z" id="Combined-Shape">

</path>
        </g>
    </g>
</svg>

  )

}

export default EarthSvg

