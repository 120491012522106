import React, { useState, useEffect,useRef } from 'react';
import Layout from '../global/layout';
import Btnback from '../global/btnback';
import bannerimage from '../../assets/svgs/sidebanner.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getTotalBanners, createBanner, removeSidebanner, updatingBannners } from '../../redux/actions/bannerAction';
import { convertToDataURL } from '../../utils/convertToDataURL';
import { baseURL } from '../../config/api';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { ShimmerCard, ShimmerTitle } from 'react-shimmer-effects'; 


const Sidebanner = () => {
  const [loading, setLoading] = useState(true);

  const fileInputRef = useRef();

const handleEditClick = () => {
  fileInputRef.current.click();
};
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [country, setCountry] = useState('PK');
  const [type, setType] = useState('side');
  const [image, setImage] = useState(bannerimage);
  const [updateImage, setUpdateImage] = useState('');
  const [url, setUrl] = useState('');
  // const [updateBannerId, setUpdateBannerId] = useState(null);

  const dispatch = useDispatch();
  const { banners, createLoading, deleteLoading, updateLoading } = useSelector((state) => state.banner);

  useEffect(() => {
    dispatch(getTotalBanners());
  }, [dispatch]);

  const sideBanners = banners.filter((entry) => entry.type === 'side');

  const handleStartDateChange = (event) => setStartDate(event.target.value);
  const handleEndDateChange = (event) => setEndDate(event.target.value);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = await convertToDataURL(file);
      setImage(imageUrl);
    } else {
      setImage(bannerimage);
    }
  };

  const handleFileUpdate = async (event, id) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = await convertToDataURL(file);
      setUpdateImage(imageUrl);
      dispatch(updatingBannners(id, { image: imageUrl }, toast));
    }
  };
  

  const handleDelete = (id) => {
    dispatch(removeSidebanner(id, toast));
  };

  const formatDashDate = (date) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) return date;
    const [month, day, year] = date.split('-');
    if (!month || !day || !year) return '';
    return `${year}-${month}-${day}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      toast.error('Please fill in all required fields.');
      return;
    }
    const formatDashStartDate = formatDashDate(startDate);
    const formatDashEndDate = formatDashDate(endDate);
    const data = { startDate: formatDashStartDate, endDate: formatDashEndDate, url, type, country, image };

    try {
      await dispatch(createBanner(data, toast));
      setImage(bannerimage);
      setUrl('');
      setStartDate('');
      setEndDate('');
    } catch (error) {
      toast.error('Failed to add banner. Please try again.');
    }
  };



  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); 
  }, []);


  if (loading) {
    return (
      <Layout>
        <div className='min-h-screen h-full px-4 pt-4'>
          <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
            <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
          </div>
          <div className='flex flex-col space-y-7'>
            <div className='w-full bg-pure flex flex-col space-y-8 mt-5 px-4 py-5 rounded-[10px]'>
              <div className='flex flex-col md:flex-row gap-5'>
                <div className='w-full md:w-2/5'>
                  <ShimmerTitle width='100%' height='200px' className='rounded-[10px]' />
                </div>
                <div className='w-full md:w-3/5 flex flex-col space-y-3'>
                  <div className='flex flex-col space-y-2 w-full'>
                    <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                  </div>
                  <div className='flex flex-col space-y-2 w-full'>
                    <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                  </div>
                </div>
              </div>
              <div className='flex flex-row justify-center'>
                <ShimmerTitle width='100%' height='40px' className='rounded-md' />
              </div>
            </div>

            <div className='w-full py-6'>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                {[...Array(3)].map((_, index) => (
                  <div key={index} className='flex flex-col space-y-3 bg-pure shadow-md rounded-[10px] py-4'>
                    <div className='flex flex-row justify-between items-center px-3'>
                      <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                      <ShimmerTitle width='100px' height='40px' className='rounded-md' />
                    </div>
                    <div className='flex flex-col space-y-2 px-6 py-1'>
                      <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                      <ShimmerTitle width='100%' height='200px' className='rounded-[10px]' />
                    </div>
                    <div className='flex justify-center'>
                      <ShimmerTitle width='100%' height='40px' className='rounded-md' />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='min-h-screen h-full px-4 pt-4'>
        <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
          <div className='flex flex-col py-4'>
            <h1 className='text-xl font-semibold'>Banners</h1>
            <p className='text-[15px] text-primary font-semibold'>Thursday · August 3, 2023 · 12:51:47 PM GMT+5</p>
          </div>
          <div>
            <Btnback />
          </div>
        </div>

        <div className='flex flex-col space-y-7'>
          <div className='w-full bg-pure flex flex-col space-y-8 mt-5 px-4 py-5 rounded-[10px]'>
            <div className='flex flex-col md:flex-row gap-5'>
              <div className='w-full md:w-2/5'>
                <label className='text-center text-[#2F80ED]' htmlFor='input-file'>
                  <img src={image} alt='Selected' />
                </label>
                <input
                  type='file'
                  name='picture'
                  accept='image/jpeg, image/png ,image/jpg'
                  className='hidden'
                  id='input-file'
                  onChange={handleFileChange}
                />
              </div>
              <div className='w-full md:w-3/5 flex flex-col space-y-3'>
                <div className='flex flex-col space-y-2 w-full'>
                  <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Duration</label>
                  <div className='mb-0'>
                    <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>Start Date</label>
                    <input
                      type='date'
                      value={startDate}
                      onChange={handleStartDateChange}
                      className='border border-primary rounded-[10px] w-full outline-none py-3 px-2 my-2 text-dark'
                    />
                  </div>
                  <div>
                    <label className='text-black text-opacity-65 font-semibold text-[15px] px-1'>End Date</label>
                    <input
                      type='date'
                      value={endDate}
                      onChange={handleEndDateChange}
                      className='border border-primary rounded-[10px] w-full outline-none py-3 px-2 my-2 text-dark'
                    />
                  </div>
                </div>
                <div className='flex flex-col space-y-2 w-full'>
                  <label className='text-black font-semibold text-[15px] px-1'>Url</label>
                  <input
                    type='text'
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder='https://www.lipsum.com/feed/html'
                    className='border border-primary rounded-[10px] w-full outline-none py-3 px-2 my-2 text-black'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row justify-center'>
              <button
                className='bg-primary text-pure font-medium hover:bg-opacity-85 w-full md:w-[321px] py-3 rounded-md shadow-md'
                onClick={handleSubmit}
              >
                {createLoading ? <PulseLoader size={10} color='#fff' /> : 'Submit'}
              </button>
            </div>
          </div>

          <div className='w-full py-6'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
              {sideBanners.map((entry, index) => {
                const startDate = formatDate(entry.startDate);
                const endDate = formatDate(entry.endDate);
                const dateRange = `${startDate} - ${endDate}`;

                return (
                  <div key={entry._id} className='flex flex-col space-y-3 bg-pure shadow-md rounded-[10px] py-4'>
                    <div className='flex flex-row justify-between items-center px-3'>
                      <div className='text-sm'>{dateRange}</div>
                      <button
                        className='bg-danger text-pure w-auto py-2 lg:w-[148px] rounded-[10px] shadow-md'
                        onClick={() => handleDelete(entry._id)}
                      >
                        {deleteLoading[entry._id] ? (
                          <PulseLoader size={10} color='#fff' />
                        ) : (
                          'Delete'
                        )}
                      </button>
                    </div>
                    <div className='flex flex-col space-y-2 px-6 py-1'>
                      <p className='px-1'>Banner {index + 1}</p>
                      <div className='h-60 w-[98%] m-auto'>
                        <label className='text-center text-[#2F80ED]' htmlFor={`input-file-side-${entry._id}`}>
                          <img
                            src={`${baseURL}/${entry.image}`}
                            alt='Banner'
                            className='h-full w-full object-cover rounded-[10px]'
                          />
                        </label>
                        <input
                          type='file'
                          name='picture'
                          accept='image/jpeg, image/png ,image/jpg'
                          className='hidden'
                          ref={fileInputRef}
                          id={`input-file-side-${entry._id}`}
                          onChange={(event) => handleFileUpdate(event, entry._id)} // Update the image on file change
                        />
                      </div>
                    </div>
                    <div className='flex justify-center'>
                      <button
                        className='bg-primary text-pure font-medium hover:bg-opacity-85 w-full md:w-[321px] py-3 rounded-md shadow-md'
                        onClick={handleEditClick}
                      >

              {updateLoading[entry._id] ? <PulseLoader size={10} color='#fff' /> : 'Update'}
                      
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Sidebanner;
