import React,{useRef,useState,useEffect} from 'react'
import Logo from '../../../assets/svgs/relfliplogo.svg'
import Masseges from '../../../assets/svgs/messagenotif.svg'
import notification from '../../../assets/svgs/notificationnavbar.svg'
import users from '../../../assets/svgs/profilecircle.svg'
import { IoIosArrowForward } from "react-icons/io";
import Search from '../../../assets/svgs/search.svg'
import hambuger from '../../../assets/svgs/hamburger.svg'
import { IoSearch } from "react-icons/io5";
import useToggle from '../../../hooks/useToggle'
import useToggleOptionPervstate from '../../../hooks/usetoggleperv.js'
import { RxCross2 } from "react-icons/rx";
import './style.css'
import {usePannelContext} from '../../../context/PannelContext';
import { IoLogOutOutline } from "react-icons/io5";
import useClickOutside from '../../../utils/clickOutside.js'
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {fetchNotification} from '../../../redux/actions/notificationAction.js';
import {logout} from '../../../redux/actions/authActions.js'


const Header = () => {
  const navigate=useNavigate();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const {allnotifications,allnotificationsloading}=useSelector((state)=>state.notification);


  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

const usermenu=useRef();
const usernotifi=useRef();
  const {setShowPannel}=usePannelContext();
  const [showSearch , togglesearch] = useToggle();
  const [showLogin, toggleUser] = useToggle();
 const [showNotification,togglenotification]=useToggleOptionPervstate(false);
 const [isExpanded, setIsExpanded] = useState(false);



  useClickOutside(usermenu, () => toggleUser(false))
  useClickOutside(usernotifi, () => togglenotification(false))
  
  const logoutHandler = () => {
    dispatch(logout(navigate , toast));

  };

  useEffect(()=>{
    dispatch(fetchNotification())
  },[dispatch])

 

  const handleViewNow = () => {
    navigate('/notification'); 
    setIsExpanded(false);
  };

  return (
    <div className="w-full  py-3  px-3 lg:px-8  shadow-md  bg-white relative lg:fixed  lg:h-[70px] z-50 " >
  <div className="flex flex-row  justify-between lg:justify-normal  items-center ">
  
    <div className="flex flex-row items-center gap-10  w-[10%] lg:w-auto">
     <div className='block  lg:hidden' onClick={()=>setShowPannel(true)}>
        <img src={hambuger} alt="Hamburger" className='sm:w-[40px] w-[30px] cursor-pointer'/>
     </div>
    
        
      
      <div className='hidden lg:block   '>
       <div className='flex flex-row items-center gap-[50px] '>
           <div>
           <Link to='/'>
           <img src={Logo} alt='img' className='w-[250px] ml-1'
           />
           </Link>
           </div>

           <div className="w-[300px]  flex flex-row bg-aliceBlue rounded-lg  ">
        <div className="w-[90%]  ">
          <input
            type="text"
            placeholder="Search"
            
            className="w-full h-full bg-aliceBlue outline-none rounded-tl-lg py-2 rounded-bl-lg px-3 text-[15px]"
          />
        </div>
        <div className="w-[10%] flex justify-center items-center   pr-1">
          <img src={Search} alt="Search" className="w-4" />
        </div>
      </div>
         
       </div>


    
      </div>
    
    </div>
{  

    showSearch &&  
    <div className={`block lg:hidden w-[50%] lg:w-auto searchbox ${showSearch ? 'show':''}`}>
    <div className="w-full flex flex-row bg-aliceBlue rounded-lg  ">
        <div className="w-[90%]  ">
          <input
            type="text"
            placeholder="Search"
            className="w-full h-full bg-aliceBlue outline-none rounded-tl-lg py-2 rounded-bl-lg px-3 text-[15px]"
          />
        </div>
        <div className="w-[10%] flex justify-center items-center ">
          <img src={Search} alt="Search" className="w-4" />
        </div>
      </div>
    </div>
}

    <div className="flex flex-row items-center  justify-end  gap-2 lg:gap-5 cursor-pointer w-[40%] lg:w-full   " >
    <div className='text-2xl text-primary block lg:hidden' onClick={() => togglesearch()}>
        {    showSearch  ?  <RxCross2/>  :   <IoSearch/>} 
    </div>
    <div className='hidden lg:block '>
    <div className="flex items-center gap-1">
        <span className="font-semibold ">Documentation</span>
        <span>
          <IoIosArrowForward />
        </span>
      </div>
    </div>
      {/* <div  className='relative'  >
        <img src={notification} alt="Notification" onClick={()=>togglenotification()}  />

      
      </div> */}
{/* 
      {showNotification && (
      <div
        className={`absolute right-0 sm:right-32 top-20 bg-pure py-2 rounded-[10px] shadow-md z-30 h-[500px] overflow-auto 
        }`}
        ref={usernotifi}
      >
        <h1 className='text-dark text-xl py-2 font-semibold px-3'>Notifications</h1>

        <div className='px-3 overflow-y-auto'>
          {allnotifications.length === 0 ? (
            <p className='text-dark text-opacity-60 text-center py-2'>No notifications</p>
          ) : (
            allnotifications.map((notification) => {
              const CreatedDate = formatDate(notification.createdAt);
              return (
                <ul className='px-3 py-4 flex flex-col space-y-2' key={notification._id}>
                  <li className='flex flex-col'>
                    <p className='lg:whitespace-nowrap'>
                      {notification.description}.
                      <span className='text-primary' onClick={handleViewNow}> View Now</span>
                    </p>
                    <p className='text-dark text-opacity-35'>{CreatedDate}</p>
                  </li>
                </ul>
              );
            })
          )}
        </div>

 
      </div>
    )
  
       
        } */}



        <div>
      <Link to="/Support">
        <img src={Masseges} alt="Messages" className='' />
      </Link>
    </div>
      <div  className='relative'>
        <img src={users} alt="User" className='cursor-pointer' onClick={()=>toggleUser()}  />
        {
          showLogin &&
          <div className='absolute w-[207px] right-0 text-white top-16 bg-[--primary] rounded px-3 z-30' ref={usermenu}>
        <div className='flex flex-col  py-3'>
            <h1 className='text-sm '>{user.username}</h1>
            <p className='text-xs text-pure text-opacity-85 hover:text-opacity-100'>Manage Your Account</p>
         
             <div className='pt-2 flex flex-row items-center gap-3 border-b border-lightgary border-opacity-50  pb-3  '>
               <div>Membership</div>
               <div className='bg-[#D59B47] text-white px-1 rounded'>Basic</div>
             </div>
             <ul className='flex flex-col space-y-4 text-sm py-4'>
              <li className='text-pure text-opacity-85 hover:text-opacity-100'>Help</li>
              <li className='text-pure text-opacity-85 hover:text-opacity-100'>Terms And Conditions</li>
              <li className='text-pure text-opacity-85 hover:text-opacity-100'>Privacy Policy</li>
             </ul>

             <div className='flex flex-row items-center justify-between border-t border-lightgary border-opacity-50  pt-2'   onClick={logoutHandler} >
                   <span>LogOut</span>
                   <span className='text-2xl'><IoLogOutOutline/></span>
             </div>
            
        </div>
         </div> 

        }
     
      </div>
    </div>
  </div>
</div>


  )
}

export default Header
