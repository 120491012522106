import React, { useEffect } from 'react';
import { fetchtotalpayment } from '../../redux/actions/totalbellingpaymentAction';
import { useDispatch, useSelector } from 'react-redux';

const PaymentBillingCard = () => {
    const dispatch = useDispatch();
    const { totalpayemnt, loading, error, pageSize } = useSelector((state) => state.totalBillingPayments);


    useEffect(() => {
        dispatch(fetchtotalpayment(pageSize || 10)); 
    }, [dispatch, pageSize]);


    const totalDepositAmount = totalpayemnt.reduce((total, item) => {
      return item.action === "+" ? total + item.amount : total;
  }, 0);

  const totalWithdrawalAmount = totalpayemnt.reduce((total, item) => {
      return item.action === "-" ? total + item.amount : total;
  }, 0);

  const totalBonusAmount = 0; 
  const totalAdminProfitAmount = 0; 


const overallTotal = totalDepositAmount + totalWithdrawalAmount + totalBonusAmount + totalAdminProfitAmount;

const calculatePercentage = (amount) => {
    return overallTotal > 0 ? ((amount / overallTotal) * 100).toFixed(2) : 0;
};

const depositPercentage = calculatePercentage(totalDepositAmount);
const withdrawalPercentage = calculatePercentage(totalWithdrawalAmount);
const bonusPercentage = calculatePercentage(totalBonusAmount);
const adminProfitPercentage = calculatePercentage(totalAdminProfitAmount);
  

 


    return (
        <>
            <div className='w-full'>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-4'>
                    <div className='bg-pure rounded-[10px] border-l-[5px] border-[#0060F0] shadow-md'>
                        <div className='flex flex-col px-5 py-4'>
                            <p className='text-[#0060F0] text-sm font-semibold'>Total Deposit</p>
                            <h2 className='text-xl leading-[50px] font-semibold'>{totalDepositAmount}</h2>

                            <div className='flex flex-row gap-2'>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#0060F0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.4998 8.33333V5H14.1665" stroke="#0060F0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text-sm text-[#0060F0]'>{depositPercentage}%</span>                      
                                      </div>
                        </div>
                    </div>

                   
                    <div className='bg-pure rounded-[10px] border-l-[5px] border-[#6900C7] shadow-md'>
                        <div className='flex flex-col px-5 py-4'>
                            <p className='text-[#6900C7] text-sm font-semibold'>Total Withdraw</p>
                            <h2 className='text-xl leading-[50px] font-semibold'>{totalWithdrawalAmount}</h2>
                            <div className='flex flex-row gap-2'>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#6900C7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.4998 8.33333V5H14.1665" stroke="#6900C7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text-sm text-[#6900C7]'>{withdrawalPercentage}%</span>                            </div>
                        </div>
                    </div>

                    <div className='bg-pure rounded-[10px] border-l-[5px] border-primary shadow-md'>
                        <div className='flex flex-col px-5 py-4'>
                            <p className='text-primary text-sm font-semibold'>Total Bonus to Flip Shared</p>
                            <h2 className='text-xl leading-[50px] font-semibold'>{totalBonusAmount}</h2>
                            <div className='flex flex-row gap-2'>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#51087E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.4998 8.33333V5H14.1665" stroke="#51087E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text-sm text-[#51087E]'>{bonusPercentage}%</span>
                            </div>
                        </div>
                    </div>

                    <div className='bg-pure rounded-[10px] border-l-[5px] border-primary shadow-md'>
                        <div className='flex flex-col px-5 py-4'>
                            <p className='text-primary text-sm font-semibold'>Total Admin Profit</p>
                            <h2 className='text-xl leading-[50px] font-semibold'>{totalAdminProfitAmount}</h2>
                            <div className='flex flex-row gap-2'>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 5L11.6667 10.8333L9.16667 8.33333L2.5 15" stroke="#EA4335" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.4998 8.33333V5H14.1665" stroke="#EA4335" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className='text-sm text-primary'>{adminProfitPercentage}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentBillingCard;
