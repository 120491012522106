import React, { useEffect,useState ,useRef } from 'react'
import Layout from '../../components/global/layout'
import CurrentTime from '../../components/global/currenttime'
import Userhistory from '../../components/allhistory/userhistory'
import  Widthdrawpopup  from '../../components/allhistory/widthdrawpopup'
import usePopuptoggle from '../../hooks/popuptoggle';
import { useDispatch,useSelector } from 'react-redux'
import {fetchWithdraw,updateWithdraw} from '../../redux/actions/withdrawAction';
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import useToggle from '../../hooks/useToggle'
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import useClickOutside from '../../utils/clickOutside'



const Allhistory= () => {

  // const dateRef = useRef();
  // const selectRef=useRef();
  const dispatch=useDispatch();
  const { withdraw,loading,error,pages,currentPage } = useSelector(state => state.allwithdraw);
  const { IsPopupOpen,openPopup,closePopup,campaignId}=usePopuptoggle();
   

//date range 
// const [showDropdown, toggleDropdown] = useToggle();
// const [selectedOption, setSelectedOption] = useState('dateRange');
// const [showDateRangePicker, setShowDateRangePicker] = useState(false);
// useClickOutside(dateRef , () => setShowDateRangePicker(false));
// useClickOutside(selectRef , () => toggleDropdown(false));
// const [selectionRange, setSelectionRange] = useState({
//   startDate: new Date(),
//   endDate: addDays(new Date(), 7),
//   key: 'selection',
// });

// const handleSelect = (ranges) => {
//  const { startDate, endDate } = ranges.selection;
//  setSelectionRange({
//    startDate,
//    endDate,
//    key: 'selection',
//  });
//    dispatch(fetchWithdraw(startDate, endDate)); 
//    setShowDateRangePicker(false);

// };

// const formatDate = (date) => format(date, 'MMMM d, yyyy');


// const handleSelection = (option) => {
// setSelectedOption(option);
// toggleDropdown(false);
// if (option === 'all') {
//  dispatch(fetchWithdraw()); 
// } else {
//  dispatch(fetchWithdraw(selectionRange.startDate, selectionRange.endDate));
// }
// };
///



   useEffect(()=>{
     dispatch(fetchWithdraw());
   },[dispatch])


   
   
 if (loading) {
    return (
      <Layout>
      <div className='min-h-screen h-full px-4 pt-4 '>
      <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
    <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
  </div>
  
  
  
        <div className='w-full  py-6'>
          <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
            <table className='table-auto w-full'>
              <thead className='border-b'>
                <tr>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                  <th className='shimmer px-6 py-4'></th>
                </tr>
              </thead>
              <tbody>
                {Array(10).fill(null).map((_, index) => (
                  <tr key={index}>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                    <td className='px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                      <ShimmerTitle  />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </Layout>
    );
  }

  return (
    <Layout>
          { IsPopupOpen && (<Widthdrawpopup onClose={closePopup}   withdrawId={campaignId} />)}
   <div className='min-h-screen h-full px-3 relative   '>
        <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
            <div className='flex flex-col py-4'>
            <h1 className='text-lg font-semibold'>All withdraw History</h1>
              <CurrentTime/>
            </div>
            {/* <div className='relative '>
      <div
        className='relative flex flex-row items-center bg-white gap-2 py-2 px-3 w-[80]  sm:w-[395px] rounded-[10px] cursor-pointer'
        >
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 2V5M16 2V5M20.75 17.6H3.25M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="#51087E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z" fill="#51087E"/>
          </svg>
        </span>
        <span className=' text-[13px] sm:text-[15px] font-semibold'   onClick={() => setShowDateRangePicker(true)}>
        {selectedOption === 'all' ? 'ALL' : `${formatDate(selectionRange.startDate)} - ${formatDate(selectionRange.endDate)}`}
                </span>
        <span
           className="absolute right-4 translate-x-1/2 -translate-y-1/2 cursor-pointer"
           style={{ top: '50%' }}
         onClick={()=>toggleDropdown()} >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M14.9401 6.71289L10.0501 11.6029C9.47256 12.1804 8.52756 12.1804 7.95006 11.6029L3.06006 6.71289" fill="black"/>
          </svg>
        </span>
      </div>
    
    {

      showDropdown  &&
      <div className={`w-[80]  sm:w-[395px] absolute bg-pure top-12 shadow-lg px-1 py-2 ${selectedOption === 'all' ? 'left-0 lg:right-0' : ''} `} ref={selectRef}>
            <ul>
            <li   className='py-1 px-2 font-semibold text-xs sm:text-sm' onClick={() => handleSelection('dateRange')}>
            {`${formatDate(selectionRange.startDate)} - ${formatDate(selectionRange.endDate)}`}
            </li>
              <li className='py-1 px-2 font-semibold text-xs sm:text-sm' onClick={() => handleSelection('all')}>All</li>
            </ul>
       </div>
    }
    </div>

    {showDateRangePicker && (
        <div className='absolute top-12 left-1/2 transform -translate-x-1/2 mt-2 z-10 shadow-lg'  ref={dateRef}>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            months={1}
            direction="horizontal"
          />
        </div>
      )} */}

        </div>
     <Userhistory   withdraw={withdraw}   pages={pages}  currentPage={currentPage}   loading={loading} onopen={openPopup}/>
         </div>
    </Layout>
  )
}

export default Allhistory
