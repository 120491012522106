import { createSlice } from '@reduxjs/toolkit';

const paymentBillingSlice = createSlice({
    name: 'paymentBilling',
    initialState: {
        allPayments: [], 
        singlePayment:{},
        singleUserPayment:[],
        loading: false, 
        singlePaymentLoading:false,
        singleUserPaymentloading:false,
        error: null,
        pages: 1,
        currentPage: 1, 
        pageSize:10   
    },
    reducers: {
        setAllPayments(state, action) { 
            state.allPayments = action.payload;
        },
        setSerachAllPayments(state, action){
            state.allPayments = action.payload; 
        },
        setDateRangeAllPayments(state, action){
            state.allPayments = action.payload; 
        },
        setPageSizeAllPayments(state, action){
            state.allPayments = action.payload; 
        },
        setSinglePayment(state, action){
            state.singlePayment = action.payload;
        },

        setSingleUserPayment(state, action){
            state.singleUserPayment = action.payload;
        },

        setSinglePaymentLoading(state, action){
            state.singlePaymentLoading = action.payload;

        },
        setLoading(state, action) { 
            state.loading = action.payload;
        },
        setSingleUserPaymentLoading(state, action){
            state.singleUserPaymentloading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        },
        setPageSize(state, action){
            state.pageSize = action.payload;

        }
    },
});



export const { setAllPayments,setSinglePayment, setLoading,setSinglePaymentLoading, setError,setSingleUserPayment,setSingleUserPaymentLoading,setCurrentPage,setPages,setDateRangeAllPayments,setSerachAllPayments,setPageSizeAllPayments,setPageSize } = paymentBillingSlice.actions;
export default paymentBillingSlice.reducer;

