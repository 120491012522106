import { useState } from 'react';

const useShowSubcategories = () => {
    const [visibleSubcategoryId, setVisibleSubcategoryId] = useState(null);

    const toggleSubcategoryVisibility = (categoryId) => {
        setVisibleSubcategoryId(currentId =>
            currentId === categoryId ? null : categoryId
        );
    };

    return [visibleSubcategoryId, toggleSubcategoryVisibility];
};

export default useShowSubcategories;
