import { createSlice } from '@reduxjs/toolkit';

const activeuserSlice = createSlice({
    name: 'Activeuser',
    initialState: {
        activeuser: [],
        activeuserstats:{},
        loading: false,        
        error: null, 
        pages: 1,
        currentPage: 1,
        pageSize:10,


    },
    reducers: {
        setActiveUser(state, action) {
            state.activeuser = action.payload;
        },
        setActiveUserStats(state, action) {
            state.activeuserstats = action.payload;
        },
        setAllActivePageSize(state, action){
            state.activeuser=action.payload
        },
        setSerachAllActivePageSize(state, action){
            state.activeuser = action.payload; 
        },
        // setDateRangeActiveUser(state, action){
        //     state.activeuser = action.payload; 
        // },
        setCountryActiveUser(state, action){
            state.activeuser = action.payload; 
        },
        setLoading(state,action){
            state.loading = action.payload;
        },
        setError(state,action){
            state.error = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setPages(state, action) {
            state.pages = action.payload;
        },
        setPageSize(state, action){
            state.pageSize = action.payload;

        }
    },
});

export const { setActiveUser,setLoading,setActiveUserStats,setError,setCurrentPage,setPages,setAllActivePageSize,setPageSize,setSerachAllActivePageSize,setCountryActiveUser} = activeuserSlice.actions;
export default activeuserSlice.reducer;
