import React,{useEffect,useState} from "react"
import Layout from "../../components/global/layout"
import Btnback from "../../components/global/btnback"
import Addsubcategory from "../../components/subcategory/addsubcategory"
import {getTotalCategories} from '../../redux/actions/categoryAction'
import { useDispatch,useSelector } from "react-redux"
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import CurrentTime from '../../components/global/currenttime'



const Subcategory = () => {
  const dispatch=useDispatch();
  const { categories } = useSelector(state => state.category);
  useEffect(()=>{
    dispatch(getTotalCategories())
  },[dispatch])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); 
  }, []);
  if (loading) {
    return (
      <Layout>
        <div className='min-h-screen h-full px-4 pt-4'>
          <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
            <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
          </div>
          <div className='w-full flex flex-col lg:flex-row mt-5 gap-10'>
            <div className='w-full lg:w-2/4 h-auto bg-pure shadow-md rounded-[10px]'>
              <div className='font-semibold text-xl px-3 py-3 border-b'>
                <ShimmerTitle width='100%' height='20px' className='rounded-[10px] w-full' />
              </div>
              <div className='flex flex-col space-y-2 mt-5 px-4'>
                <h1 className='text-sm pt-4'>
                  <ShimmerTitle width='50%' height='20px' className='rounded-[10px] w-full' />
                </h1>
                <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full mt-2' />
                <div className='flex flex-col space-y-3 items-center py-4'>
                  <p>
                    <ShimmerTitle width='50%' height='20px' className='rounded-[10px] w-full' />
                  </p>
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Layout>
    );
  }

  
  return (
    <Layout>

<div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-xl font-semibold'> Sub Category</h1>
             <CurrentTime/>
               </div>
               <div>
                 <Btnback/>
               </div>
         </div>
         <Addsubcategory categories={categories}/>
         </div>
    </Layout>
  )
}

export default Subcategory