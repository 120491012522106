import React from 'react'
import calender from '../../assets/svgs/calendar.svg'


const Flipcampaignstatsdaterange = ({campaignsingle}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };
  const startDate = formatDate(campaignsingle.startDate);
  const endDate = formatDate(campaignsingle.endDate);
  const dateRange = `${startDate} - ${endDate}`;

  return (
    <div className='bg-pure w-full flex flex-col lg:flex-row gap-5  px-3 py-4 rounded-[10px] mt-5'>
    <div className='flex flex-col space-y-4 w-full lg:w-1/3'>
  <label className='font-semibold'>Date Range</label>
  <div className='border border-primary rounded-[10px] w-full h-12 relative'>
  <input type='text'
          value={dateRange}
        className=' w-full  outline-none  px-2  h-full rounded-[10px] ' readOnly/>
             <div className='absolute inset-y-0 right-3 flex items-center'>
        <img src={calender} alt='Calendar icon' />
    </div>
  </div>
        
    </div>
    <div className='flex flex-col space-y-4 w-full lg:w-1/3'>
  <label className='font-semibold'>Campaign Name</label>

  <input type='text'
   value={campaignsingle?.name} 
        className=' border border-primary rounded-[10px] outline-none w-full px-2   h-12  '/>
       
    </div>
    <div className='flex flex-col space-y-4 w-full lg:w-1/3'>
  <label className='font-semibold'>Campaign Type</label>

        <input type='text'
         placeholder='Campaign Type '
         value={campaignsingle?.category} 
        className='border border-primary rounded-[10px] w-full  outline-none  px-2  h-12  '/>
    </div>
    </div>
  )
}

export default Flipcampaignstatsdaterange