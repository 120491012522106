import { createSlice } from '@reduxjs/toolkit';

const subCategorySlice = createSlice({
    name: 'subcategory',
    initialState: {
        subCategories: [],
        loading: false,
        createLoading: false,
        updateLoading: false,
        deleteLoading: false,
    },
    reducers: {
        addSubCategory(state, action) {
            state.subCategories.unshift(action.payload);
        },
        removeSubCategory(state, action) {
            state.subCategories = state.subCategories.filter(subCategory => subCategory._id !== action.payload);
        },
        setSubCategory(state , action) {
            state.subCategories = action.payload;
        } ,
        updateSubCategory(state, action) {
            const { id, updates } = action.payload;
            const subCategory = state.subCategories.find(subCategory => subCategory.id === id);
            if (subCategory) {
                Object.assign(subCategory, updates);
            }
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setCreateLoading(state, action) {
            state.createLoading = action.payload;
        },
        setUpdateLoading(state, action) {
            state.updateLoading = action.payload;
        },
        setDeleteLoading(state, action) {
            state.deleteLoading = action.payload;
        },
    },
});

export const {
    addSubCategory,
    removeSubCategory,
    updateSubCategory,
    setSubCategory,
    setLoading,
    setCreateLoading,
    setUpdateLoading,
    setDeleteLoading,
} = subCategorySlice.actions;

export default subCategorySlice.reducer;
