import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Share from '../../assets/svgs/share.svg';
import { PulseLoader } from 'react-spinners';
import './home.css';
import { format } from 'date-fns';
import { baseURL } from '../../config/api';
import Img from '../../assets/png/img2.jpg';
import { useNavigate } from 'react-router-dom';
import { ShimmerPostItem, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';

const Dashboardflipcompaigns = ({ campaigns, loading }) => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const [statsloading, setStatsloading] = useState(false);

  const handleAccordionToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const sortedFlipscampaigns = [...campaigns].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 4);
  
  const loadMore = () => {
    setStatsloading(true);
    setTimeout(() => {
      navigate('/flipcampaigns');
      setStatsloading(false);
    }, 2000);
  };

  const handleStats = (id) => {
    navigate(`/flipcampaigns/stats/${id}`);
  };




  return (
    <>
            {loading ? (
              <div className='w-1/3'>
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              </div>
          
            ):(      <div className='font-semibold text-lg'>Latest Flip Campaign</div>
            ) }


      <div className='grid grid-cols-1 lg:grid-cols-2 gap-3 py-3'>
        {loading ? (

          [...Array(4)].map((_, index) => (
            <div key={index} className='flex flex-row items-center bg-white rounded-[10px] shadow-md py-3'>
            <div className='w-[80px] h-[80px] flex flex-row overflow-hidden px-3 rounded-md '>
              <ShimmerThumbnail className=' w-full h-full ' />
              </div>
              <div className='px-3 py-3 w-full h-24 overflow-hidden  flex flex-row justify-between  space-y-1'>
                 <div className='flex flex-col'>
                 <div>
                  <ShimmerTitle width='100%' height='20px'/>
                 </div>
                 <div>
                 <ShimmerPostItem width='100%' height='20px' />
                 </div>

                 </div>

                 <div className=' w-40 h-16 ' >
                 <ShimmerThumbnail className=' w-full h-full rounded-[10px] ' />

                      </div>
               


              </div>
            </div>
          ))
        ) : (
          sortedFlipscampaigns.map((latest, index) => (
            <div key={latest.id} className={`flex flex-col space-y-3 bg-white rounded-[10px] shadow-md `}>
              <div className='flex flex-row items-center   '>
                <div className='px-2 py-2 lg:py-0  '>
                  <img 
                    src={latest?.images.length > 0 ? baseURL + latest.images[0] : Img} 
                    alt="Campaign Cover" 
                    onError={(e) => { e.target.onerror = null; e.target.src = Img; }}
                    className='lg:w-[90px] lg:h-[90px] md:w-[80px] md:h-[80px] w-[60px] h-[60px] object-cover rounded-md'
                  />
                </div>
                <div className='px-3 py-3 w-full flex flex-col space-y-1 '>
                  <h2 className='text-base font-semibold pt-2'>{latest.name}</h2>
                    <div className='flex flex-row justify-between items-center w-full gap-1'>
                      <div className='flex flex-col gap-2 lg:gap-0 '>
                        <div className='flex flex-row items-center gap-2'>
                          <div className='text-sm'>{latest?.compaignUrl?.split('//')[1]} - {latest?.targetCountry}</div>
                          <div>
                            <img src={Share} alt='img' />
                          </div>
                        </div>
                      </div>
                      <div className='btn-primary h-9 text-sm sm:text-base sm:h-auto w-40' onClick={() => handleStats(latest._id)}>
                        View Stats
                      </div>
                    </div>
                    <div className='flex flex-row items-center justify-between w-full  '>
                      <div className='flex flex-row gap-3'>
                        <div className='text-pure bg-primary rounded-[10px] px-2 text-sm'>New Campaign</div>
                      </div>
                    </div>
                 
                </div>
              </div>

              <div className='border-t flex justify-center items-center gap-2 h-10 text-dark text-opacity-80 cursor-pointer'
              onClick={() => handleStats(latest._id)}>
              <p >View Details</p>
              <IoIosArrowDown className='text-xl'/>

              </div>

              {/* <div className='block lg:hidden'>
                   <div className='flex flex-col gap-2 w-full px-3'>
                   <div className='flex flex-row items-center gap-2'>
                          <div className='text-sm'>{latest?.compaignUrl?.split('//')[1]} - {latest?.targetCountry}</div>
                          <div>
                            <img src={Share} alt='img' />
                          </div>
                        </div>
                            
                        <div className='text-pure bg-primary rounded-[10px] px-2 text-sm w-40'>New Campaign</div>


                        <div className='btn-primary  w-40' onClick={() => handleStats(latest._id)}>
                        View Stats
                      </div>
                   </div>
              </div>

              <div className={`flex flex-col space-y-2 px-4 pt-3 ${openIndex === index ? 'block' : 'hidden'}`}>
                <div className='w-full flex flex-col space-y-3'>
                  <div className='pt-1'>
                    <h1 className='text-dark text-opacity-35 text-sm'>Campaign Duration</h1>
                    <p className='font-semibold text-sm'>
                      {format(latest?.startDate, 'dd MMM yyyy')} - {format(latest?.endDate, 'dd MMM yyyy')}
                    </p>
                  </div>
                  <div>
                    <h1 className='text-dark text-opacity-35 text-sm'>Campaign Url</h1>
                    <div className='w-full'>
                      <p className='font-semibold text-sm' style={{ wordBreak: 'break-word' }}>
                        {latest.compaignUrl}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h1 className='text-dark text-opacity-35 text-sm font-semibold'>Description</h1>
                    <p className='text-sm leading-6'>
                      {latest.description}
                    </p>
                  </div>
                  <div className='grid grid-cols-3 md:grid-cols-5 gap-3'>
                    {latest?.images?.length > 0 && latest.images.map((item, imgIndex) => (
                      <img 
                        key={imgIndex}
                        src={baseURL + item} 
                        alt='Campaign Image'
                        className='rounded-lg object-cover'
                        onError={(e) => { e.target.onerror = null; e.target.src = Img; }}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={`flex w-full justify-center items-center gap-2 text-dark text-opacity-65 pt-3 pb-2 cursor-pointer ${openIndex === index ? 'border-t-2' : ''}`}
                onClick={() => handleAccordionToggle(index)}>
                <div>View Details</div>
                <div>{openIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
              </div> */}
            </div>
          ))
        )}
      </div>

     
  {sortedFlipscampaigns.length === 0 && !loading && (
    <div className='flex justify-center items-center h-20'>
      <p className='text-gray-500'>No campaigns found.</p>
    </div>
  )}
      <div className='flex justify-center  '>

      {  loading  ?  (

        <button className='  w-full h-16 overflow-hidden flex justify-center' >
        <div className=' w-40 h-full ' >
                 <ShimmerThumbnail className=' w-full h-full rounded-[10px] ' />

                      </div>
               
        </button>
      ):
      (  <button className='btn-primary' onClick={loadMore} disabled={loading}>
          {statsloading ? <PulseLoader size={10} color='#fff' /> : 'Load More'}
        </button>
        )}
      
      </div>
    </>
  );
}

export default Dashboardflipcompaigns;
