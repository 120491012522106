// pages/cancel.js

import React from 'react';

const Cancel = () => (
  <div className="flex items-center justify-center h-screen bg-red-100">
    <h1 className="text-3xl font-bold text-red-600">Payment Cancelled</h1>
  </div>
);

export default Cancel;
