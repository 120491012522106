import React, { useEffect, useRef, useState } from 'react';
import Searchbox from '../global/searchbox';
import './style.css';
import useClickOutside from '../../utils/clickOutside';
import { baseURL } from '../../config/api';
import { fetchSingleTicket, fetchMassageTicket, fetchSendMassage ,fetchTicketsSearch} from '../../redux/actions/ticketAction';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader, PulseLoader } from 'react-spinners';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import Img from '../../assets/png/img2.jpg'


const Searchticket = ({ alltickets }) => {
  const [sender, setSender] = useState(() => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    return user ? user._id : null;
  });
  const [type, setType] = useState('text');
  const [ticketId, setTicketId] = useState('');
  const [senderType, setSenderType] = useState('Admin');
  const [message, setMessage] = useState('');
  const [selectedTicket, setSelectedTicket] = useState(null); 
  const [searchTerm, setSearchTerm] = useState('');
  const [keyword, setKeyword] = useState('');


  const chatContainerRef = useRef(null);
  const chatRef = useRef(null);
  const dispatch = useDispatch();
  const { singletickets, ticketmessage,allticketsLoading, ticketmessageLoading, postmessageLoading } = useSelector((state) => state.ticket);

  const messagesByDate = {};
  ticketmessage.forEach((msg) => {
    const formattedDate = format(new Date(msg?.ticket?.createdAt), 'dd MMM yyyy');
    if (!messagesByDate[formattedDate]) {
      messagesByDate[formattedDate] = [];
    }
    messagesByDate[formattedDate].push(msg);
  });
  const uniqueDates = Object.keys(messagesByDate);

  useClickOutside(chatRef, () => {
    setSelectedTicket(null); 
  });

  const handleChat = (Id) => {
    dispatch(fetchSingleTicket(Id));
    dispatch(fetchMassageTicket(Id));
    setTicketId(Id);
    setSelectedTicket(Id); 
  };

  useEffect(() => {
    if (selectedTicket) {
      dispatch(fetchMassageTicket(selectedTicket));
    }
  }, [dispatch, selectedTicket]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { senderType, type, ticketId, sender, message };
    console.log(data);
    
    setMessage("");
    try {
      await dispatch(fetchSendMassage(data, toast));
      dispatch(fetchMassageTicket(selectedTicket));
    } catch (error) {
      console.error(error);
    }
  };


  const filteredTickets = alltickets.filter((ticket) =>
    ticket.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [messagesByDate]); 

  //search
  const handleSearch = async () => {
    await dispatch(fetchTicketsSearch(keyword));
  };

  return (
    <div className='flex flex-col md:flex-row gap-16 w-full mt-5'>
      <div className='w-full lg:w-1/3 h-[661px] bg-pure shadow-md rounded-[10px] overflow-y-auto'>
        <div className='flex justify-center w-full py-3 shadow-bottom'>
        <Searchbox 
        keyword={keyword} 
     onSearch={handleSearch} 
      onChange={(e) => setKeyword(e.target.value)}
        />     
           </div>
        <div className='flex flex-col'>
          <ul className='secrchlist'>
          {allticketsLoading ? (
            <div className='flex justify-center items-center h-full py-4'>
              <ClipLoader size={35} color='#51087E' />
            </div>
          ) : filteredTickets.length === 0 ? (
            <div className='flex justify-center items-center h-full py-4 text-gray-500'>
              No users found
            </div>
          ) : (
            <ul className='secrchlist'>
              {filteredTickets.map((user) => (
                <li
                  className={`flex flex-row justify-between items-center border-t cursor-pointer ${selectedTicket === user._id ? 'bg-gray-100' : ''}`}
                  key={user._id}
                  onClick={() => handleChat(user._id)}
                >
                  <div className='flex flex-row items-center px-3 py-3'>
                  <div className='h-12 w-12 rounded-full overflow-hidden shadow-lg'>
  <img
    src={`${baseURL}${user.image}`}
    alt="User Avatar"
    className='h-full w-full object-cover'
    onError={(e) => { e.target.onerror = null; e.target.src = Img; }}
  />
</div>

                    <div className='flex flex-col space-y-2 px-3'>
                      <h3 className={`text-sm ${selectedTicket === user._id ? 'text-primary' : 'text-black'}`}>{user.name}</h3>
                      <p className={`text-xs ${selectedTicket === user._id ? 'text-primary' : 'text-black'}`}>Ticket #{user._id}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          </ul>
        </div>
      </div>

      {selectedTicket && singletickets && (
        <div className='w-full lg:w-2/3 h-[665px] flex flex-col space-y-8' ref={chatRef}>
          <div className='w-full bg-pure h-[480px] shadow-md rounded-[10px] overflow-auto' ref={chatContainerRef}>
            <div className='flex flex-row items-center w-full h-[62px] shadow-bottom'>
              <div className='px-4'>
                <img
                  src={`${baseURL}${singletickets.image}`}
                  alt='User'
                  className='w-[48px] h-[48px] rounded-full shadow-xl'
                  onError={(e) => { e.target.onerror = null; e.target.src = Img; }}

                />
              </div>
              <div className='flex flex-col space-y2'>
                <h3 className={`${selectedTicket === singletickets._id ? 'text-primary  ' : 'text-black'}`}>{singletickets.name || 'User Name'}</h3>
                <p className={`text-sm ${selectedTicket === singletickets._id ? 'text-primary' : 'text-dark'}`}>Ticket #{singletickets._id}</p>
              </div>
            </div>
            <div className='flex flex-col space-y-3 justify-center py-4'>
              {ticketmessageLoading ? (
                <div className='flex justify-center py-2'>
                  <ClipLoader size={35} color='#51087E' />
                </div>
              ) : (
                uniqueDates.map((date) => (
                  <div key={date} className='flex flex-col space-y-2'>
                    <div className='flex flex-row w-full justify-center'>{date}</div>
                    {messagesByDate[date].map((msg) => (
                      <div
                        key={msg._id}
                        className={`flex ${msg?.senderType === 'User' ? 'justify-start' : 'justify-end'} px-3 py-2`}
                      >
                        <div
                          className={`max-w-80 lg:max-w-[50%] text-[15px] lg:text-base h-auto rounded-[10px] py-2 bg-white ${
                            msg?.senderType === 'User' ? 'text-primary' : 'text-dark'
                          }`}
                          style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                        >
                          <p className='px-3'>{msg.message}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              )}
            </div>
          </div>

          <div className='w-full bg-pure flex flex-col space-y-3 px-5 py-3 rounded-[10px] shadow-lg'>
            <div className='flex flex-row items-center justify-between space-x-4 pt-7'>
              <div className='text-primary text-lg'>Reply</div>
              <input
                type='text'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='Write Message Here'
                className='text-primary border border-primary py-2 px-3 rounded-[10px] outline-none w-11/12'
              />
            </div>
            <div className='flex justify-end w-full'>
              <div className='w-11/12 flex items-center justify-end'>
                <button
                  className='w-[106px] bg-primary text-pure font-medium hover:bg-opacity-85 py-3 rounded-md shadow-md'
                  onClick={handleSubmit}
                >
                  {postmessageLoading ? <PulseLoader size={10} color='#fff' /> : 'SEND'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Searchticket;
