import React, { useEffect, useRef } from 'react';
import circlechart from '../../assets/svgs/circlechart.svg';
import chart from '../../assets/svgs/trendingchart.svg';
import { GoDotFill } from 'react-icons/go';
import { Chart, registerables } from 'chart.js';
import TrafficSourcesChart from './trafficsource';
import { ShimmerTitle, ShimmerPostItem } from 'react-shimmer-effects';

Chart.register(...registerables);

const DashboardChart = ({ adminDashboard, adminLoading }) => {
  const chartRef = useRef(null);

  

  useEffect(() => {
    if (chartRef.current && adminDashboard.graphData) {
      const ctx = chartRef.current.getContext('2d');
      const graphData = adminDashboard.graphData || [];
      const sortedGraphData = [...graphData].sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
      const labels = sortedGraphData.map(data => {
        const date = new Date(data.endDate);
        return date.toLocaleString('default', { month: 'short' });
      });
      const values = graphData.map(data => data.value);
      const chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [{
            label: 'Revenue Over Time',
            data: values,
            fill: false,
            backgroundColor: 'rgba(81, 8, 126, 0.2)',
            borderColor: 'rgba(81, 8, 126)',
            tension: 0.1,
          }],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
  }, [adminDashboard]);

  
  const {
    compaignsPending,
    compaignsRunning,
    compaignsExpired,
    compaignsCompleted,
    compaignsCancelled,
    compaignsDeleted,
  } = adminDashboard;

  const totalCampaigns =
    compaignsPending +
    compaignsRunning +
    compaignsExpired +
    compaignsCompleted +
    compaignsCancelled +
    compaignsDeleted;

  const calculatePercentage = (count) => ((count / totalCampaigns) * 100).toFixed(2) || 0;

 

  return (
    <div className='w-full flex flex-col md:flex-row gap-5 py-4'>
      <div className='w-full md:w-2/4 xl:w-2/5 bg-pure shadow-md rounded-md'>
      <div className='bg-grayText border-b-2 rounded-md'>
        {adminLoading ?
         (
          <ShimmerTitle width='100%' height='16px' className='mb-2' />

        ):(          
          <p className='text-lg font-semibold text-primary px-5 py-3'>Campaigns
          Stats</p>
        )}
        </div>
        <div className='flex justify-center'>
          <div className=' w-[90%] h-[400px] '>
            {adminLoading ? (
              <div className='mt-8 '>
              <ShimmerPostItem className='w-full h-full ' />
              </div>
            ) : (
            
           
              <TrafficSourcesChart data={adminDashboard} className='m-auto ' />
            
            )}
          </div>
        </div>

        <div className='bg-grayText border-b-2 rounded-md'>
        {adminLoading ? (
          <ShimmerTitle width='100%' height='16px' className='mb-2' />
        ):(
          <p className='text-lg font-semibold text-primary px-5 py-3'>Traffic Sources</p>
        )
        }
        </div>

        <div className='flex flex-col px-5 py-3'>
          {adminLoading ? (
            <>
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              <ShimmerTitle width='100%' height='16px' className='mb-2' />
              <ShimmerTitle width='100%' height='16px' />
            </>
          ) : (
            <>
              <div className='flex flex-row justify-between border-b-[1px] py-2'>
                <div className='flex flex-row items-center'>
                  <div className='text-3xl text-primary'><GoDotFill /></div>
                  <div className='text-[15px]'>Pending Compaigns</div>
                </div>
                <div>{calculatePercentage(compaignsPending)}%</div>
              </div>

              <div className='flex flex-row justify-between border-b-[1px] py-2'>
                <div className='flex flex-row items-center'>
                  <div className='text-3xl text-[#00AC69]'><GoDotFill /></div>
                  <div className='text-[15px]'>Running  Compaigns</div>
                </div>
                <div>{calculatePercentage(compaignsRunning)}%</div>
              </div>

              <div className='flex flex-row justify-between py-2 border-b-[1px]'>
                <div className='flex flex-row items-center'>
                  <div className='text-3xl text-[#0060F0]'><GoDotFill /></div>
                  <div className='text-[15px]'>Expired Compaigns</div>
                </div>
                <div>{calculatePercentage(compaignsExpired)}%</div>
              </div>


              <div className='flex flex-row justify-between py-2 border-b-[1px]'>
                <div className='flex flex-row items-center'>
                  <div className='text-3xl text-[#FF1400]'><GoDotFill /></div>
                  <div className='text-[15px]'>Completed Compaigns</div>
                </div>
                <div>{calculatePercentage(compaignsCompleted)}%</div>
              </div>


              <div className='flex flex-row justify-between py-2 border-b-[1px]'>
                <div className='flex flex-row items-center'>
                  <div className='text-3xl text-[#FFFF00]'><GoDotFill /></div>
                  <div className='text-[15px]'>Cancelled  Compaigns</div>
                </div>
                <div>{calculatePercentage(compaignsCancelled)}%</div>
              </div>

              <div className='flex flex-row justify-between py-2'>
                <div className='flex flex-row items-center'>
                  <div className='text-3xl text-[#808080]'><GoDotFill /></div>
                  <div className='text-[15px]'>Deleted Compaigns</div>
                </div>
                <div>{calculatePercentage(compaignsDeleted)}%</div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className='w-full  md:w-2/4  xl:w-3/5 bg-pure shadow-md rounded-md'>
        <div className='bg-grayText border-b-2 rounded-md'>
        {adminLoading ?
         (
          <ShimmerTitle width='100%' height='16px' className='mb-2' />

        ):(          
          <p className='text-lg font-semibold text-primary px-5 py-3'>Revenue Summary</p>
        )}
        </div>
        <div className='py-1'>
          {adminLoading ? (
            <ShimmerPostItem className='w-full h-[400px]' />
          ) : (
            <canvas ref={chartRef} className='py-1'></canvas>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardChart;
