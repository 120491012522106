import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./reducers/dashboardReducer";
import authSlice from "../redux/reducers/authReducer";
import alluserSlice from './reducers/alluserReducer'
import withdrawSlice from './reducers/withdrawReducer'
import singleUserSlice from "./reducers/singleuserReducer";
import singleUserCampaignSlice from './reducers/singleusercompaignsReducer'
import campaignSlice from './reducers/compaignsReducer'
import SingleCampaignSlice from './reducers/singlecompaigmsReducer'
import flipshareSlice from './reducers/flipshareReducer'
import SingleFlipShareSlice from './reducers/singleflipshareReducer'
import flipshareperformancestatSlice from './reducers/flipshareperformancestatsReducer'
import paymentBillingSlice from './reducers/paymentbeilingReducer'
import categorySlice from "./reducers/categoryReducer";
import subCategorySlice from "./reducers/subcategoryReducer";
import allticketSlice from './reducers/ticketReducer';
import settingSlice from "./reducers/settingReducer"
import bannerSlice from './reducers/bannerReducer';
import userManagementSlice from './reducers/usermangmentReducer';
import allnotificationSlice from './reducers/notificationReducer';
import SinglewithdrawSlice from './reducers/singlewithdraw';
import totalBillingPaymentSlice  from './reducers/totalbellingpaymentReducer';
import activeuserSlice from './reducers/activeReducer';



const store = configureStore({
    reducer: {
        auth : authSlice , 
        users:alluserSlice,
        dashboard:dashboardSlice,
        allwithdraw:withdrawSlice,
        singlewithdraw:SinglewithdrawSlice,
        singleuser:singleUserSlice,
        singleusercampaign:singleUserCampaignSlice,
        campaign:campaignSlice,
        singlecampaign:SingleCampaignSlice,
        flepshare:flipshareSlice,
        singleflipshare:SingleFlipShareSlice,
        flipshareperformstats:flipshareperformancestatSlice,
        paymentbilling:paymentBillingSlice,
        ticket:allticketSlice,
        category:categorySlice,
        subcategory:subCategorySlice,
        setting:settingSlice,
        banner:bannerSlice,
        usermangment:userManagementSlice,
        notification:allnotificationSlice,
        totalBillingPayments: totalBillingPaymentSlice,
        activeUser:activeuserSlice,
        
    },
})

export default store;