import React,{useRef} from 'react'
import Printer from '../../assets/svgs/printer.svg';
import arrow from '../../assets/svgs/tablearrow.svg';
import setting from '../../assets/svgs/tablesetting.svg';
import view from '../../assets/svgs/view.svg';
import Arrow from '../../assets/svgs/longaroow.svg';
import Searchbox from '../../components/global/searchbox';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import useClickOutside from '../../utils/clickOutside';
import useToggleOption from '../../hooks/usetoggleoption';
import remove from '../../assets/svgs/delete.svg';
import { setCurrentPage } from '../../redux/reducers/withdrawReducer';
import { fetchWithdraw } from '../../redux/actions/withdrawAction';
import { useDispatch } from 'react-redux';
import Loader from '../global/loader';

const Userhistory = ({onopen,withdraw,currentPage, pages,loading}) => {
  const dispatch=useDispatch();
    const tableRef = useRef(null);
    const [isDropdownOpen, toggleDropdown] = useToggleOption(null);
    useClickOutside(tableRef, () => handleDropdownToggle(null));
    const handleDropdownToggle = (index) => {
        toggleDropdown(index === isDropdownOpen ? null : index);
      };


      const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pages) {
            dispatch(fetchWithdraw(newPage));
            dispatch(setCurrentPage(newPage));
        }
    };
    const startIndex =  1;

  return (
    <div className='w-full bg-pure shadow-md rounded-[10px] my-7'>
        <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2'>
          <div className='flex flex-col space-y-1'>
            <h1 className='text-primary font-semibold text-lg capitalize'>All withdraw</h1>
            <p className='text-primary text-base'>Details and history</p>
          </div>

          <div className='flex flex-row gap-3'>
            <img src={Arrow} alt='Arrow' />
            <img src={Printer} alt='Printer' />
          </div>
        </div>

        <div className='w-full flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
          
        {/* <div className="flex flex-row gap-3 items-center ">
  <div className="relative  ">
    <select
      name="show-entries"
      id="show-entries"
      defaultValue="10"
  
      className="outline-none border border-primary w-20  px-2 py-1 rounded-[10px] appearance-none text-primary"
      
    >
      <option value="10" className='text-primary'>10</option>
      <option value="25" className='text-primary'>25</option>
      <option value="50" className='text-primary'>50</option>
      <option value="100" className='text-primary'>100</option>
    </select>
    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none ">
        <MdKeyboardArrowDown className='text-2xl text-primary'/>
    </span>
  </div>
  <div className='text-sm text-primary'>
  entries per page
  </div>
</div>

          <div>
            <Searchbox />
          </div> */}
        </div>

        <div className='w-full px-6 py-6'>
        {loading ? (
        <Loader />
      ) : (
            <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
              <table className="table-auto w-full">
                <thead className='border-b'>
                  <tr>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Sr</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Name</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Account Type</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Amount</th>
                    <th className="text-base font-semibold px-6 py-4 text-left border-r">Status</th>
                    <th className="text-base font-semibold px-6 py-4 text-center border-r">Country</th>
                    <th className="text-base font-semibold px-6 py-4 text-center border-r">Date</th>
                    <th className="text-base font-semibold px-6 py-4 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      withdraw && withdraw.length > 0 ? (
                      withdraw.map((user, index) => (
                            <tr key={user._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{user.accountName}</td>
                     <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">
                {(() => {
                    switch (user.accountType) {
                        case 1:
                            return 'Bank Account';
                        case 2:
                            return 'Mobile Money';
                        case 3:
                            return 'Paypal';
                        case 4:
                            return 'Cash App';
                        case 5:
                            return 'Zelle';
                        default:
                            return 'Unknown';
                    }
                })()}
            </td>
          
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{user.amount}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{user.status}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">{user.country} </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">  {new Date(user.createdAt).toLocaleDateString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <div
                            className='border border-primary flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative'
                            onClick={() => handleDropdownToggle(index)}
                          >
                            <span><img src={setting} alt='Settings' /></span>
                            <span><img src={arrow} alt='Toggle Dropdown' /></span>
                            {isDropdownOpen === index && (
                              <div
                                className='absolute top-5 w-[94px] h-auto -left-4 bg-pure rounded-md shadow-lg flex flex-col space-y-2 items-center py-3 '
                            >
                                <p className='text-primary cursor-pointer flex items-center font-semibold gap-1' onClick={() => onopen(user._id)}   >
                                  <span><img src={view} alt='View' /></span>
                                  <span>View</span>
                                </p>
                               
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>

                        ))
                      ): (
                <tr>
                  <td colSpan="8" className="px-6 py-4 text-center text-gray-500">No withdraw found</td>
                </tr>
              )
                    }
                  
                  
                </tbody>
              </table>
            </div>

      )}
        
        </div>

     

          <div className="flex flex-col space-y-2  sm:space-y-0 sm:flex-row sm:justify-between sm:items-center px-6 py-3   ">
  
  <div className='text-primary'>
  Showing {startIndex} to {withdraw.length} of {withdraw.length} entries 
   </div>
  
 
   <div className='flex flex-row items-center  rounded-md border-0 sm:border '>
   <div className='flex flex-row border rounded-md sm:border-0'>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    className='px-1 border-r-2'
  >
    <MdKeyboardArrowLeft />
  </button>

  <div className="flex">
    {pages > 10 ? (
      <>
        {currentPage > 6 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-2 rounded border-r-2 ${currentPage === 1 ? 'bg-primary text-white' : ''}`}
            >
              1
            </button>
            {currentPage > 7 && <span className="px-2">...</span>}
          </>
        )}
        {Array.from({ length: Math.min(5, pages - Math.max(currentPage - 3, 1)) }).map((_, index) => {
          const pageNumber = Math.max(currentPage - 3, 1) + index;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        {currentPage + 3 < pages && <span className="px-2">...</span>}
        {currentPage < pages - 5 && (
          <button
            onClick={() => handlePageChange(pages)}
            className={`px-2 rounded border-r-2 ${currentPage === pages ? 'bg-primary text-white' : ''}`}
          >
            {pages}
          </button>
        )}
      </>
    ) : (
      Array.from({ length: pages }).map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={`px-2 rounded border-r-2 ${currentPage === pageNumber ? 'bg-primary text-white' : ''}`}
          >
            {pageNumber}
          </button>
        );
      })
    )}
  </div>

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === pages}
    className='px-1'
  >
    <MdOutlineKeyboardArrowRight />
  </button>
  </div>
</div>
            
          </div>

      </div>
  )
}

export default Userhistory