import Axios from "../../config/api";
import { setSingleWithdraw, setSingleLoading,setUpdateLoading,updateSingleWithdraw,setSingleUserWithdraws,setSingleUserLoading, setError } from "../reducers/singlewithdraw";
// , setError } from "../reducers/singlewithdraw";

export const fetchSingleWithdraw = (id) => async (dispatch , getState) => {
    try {
        dispatch(setSingleLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : {  doc } } } = await Axios.get(`/withdraw/${id}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setSingleWithdraw(doc));
        dispatch(setSingleLoading(false));
    } catch (err) {
        dispatch(setSingleLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}
export const updateWithdraw = (id, updatedData, toast) => async (dispatch, getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : {  doc } } } = await Axios.put(`/withdraw/${id}`, updatedData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        dispatch(updateSingleWithdraw({ id, updatedData: doc}));
        dispatch(setUpdateLoading(false));
        toast.success('Updated Successfully.');
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.error('Update error:', err.response?.data);
        console.error('Full error response:', err.response);
        toast.error(err.response?.data?.message || err.message || 'Something went wrong.');
    }
};



export const fetchSingleUserWithdraw = (id) => async (dispatch , getState) => {
    try {
        dispatch(setSingleUserLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : {  docs } } } = await Axios.get(`/withdraw/user/${id}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setSingleUserWithdraws(docs));
        dispatch(setSingleUserLoading(false));
    } catch (err) {
        dispatch(setSingleUserLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}