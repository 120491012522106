import Axios from "../../config/api";
import { format } from 'date-fns';

import { setFlipshareperformance, setLoading, setError,setCurrentPage,setPages,setDateRangeShareStats,setSerachShareStats,setPageSizeShareStats,setPageSize } from '../reducers/flipshareperformancestatsReducer';

export const fetchFlipSharePerformance = (page = 1) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        console.log('Auth Token:', token); 
     
        let url = `/flip-share/stats?page=${page}`;
        const { data: { data: { docs,pages, page: currentPage } } } = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });
        dispatch(setFlipshareperformance(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(currentPage));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};
export const fetchSharedStatsDateRange  = (datestart,dateend) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/flip-share/stats?from=${datestart}&to=${dateend}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setDateRangeShareStats(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};


export const fetchSharedStatsSearch = (keyword) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data: { data: { docs } } } = await Axios.get(`/flip-share/stats?keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        dispatch(setSerachShareStats(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};




export const fetchSharedStatsSize = (pageSize) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios.get(`/flip-share/stats?pageSize=${pageSize}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setPageSizeShareStats(docs));
        dispatch(setLoading(false));
        dispatch(setPageSize(pageSize));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
};




