import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const TrafficSourcesChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && data) {
      const ctx = chartRef.current.getContext('2d');



   

      const chartData = {
        labels: [
          'Pending', 
          'Running', 
          'Expired', 
          'Completed', 
          'Cancelled', 
          'Deleted'
        ],
        datasets: [{
          data: [
            data.compaignsPending,
            data.compaignsRunning,
            data.compaignsExpired,
            data.compaignsCompleted,
            data.compaignsCancelled,
            data.compaignsDeleted,
          ],
          backgroundColor: [
            'rgba(81, 8, 126)',
            'rgb(16, 210, 68)',
            'rgb(0, 96, 240)',
            'rgba(255, 20, 0, 1)',
            'rgba(255, 255, 0, 1)',
            'rgba(128, 128, 128, 1)',
          ],
          borderWidth: 1,
        }],
      };

      const chartOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
        },
        cutout: '70%',
      };

      const chart = new Chart(ctx, {
        type: 'doughnut',
        data: chartData,
        options: chartOptions,
      });

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return <canvas ref={chartRef} className='w-full m-auto  md:m-0 xl:m-auto'></canvas>;
};

export default TrafficSourcesChart;
