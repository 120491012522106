import React from 'react'
import { useNavigate } from 'react-router-dom'

const Btnback = () => {
    const navigate=useNavigate()
  return (
    <div className='btn-submit' onClick={() => navigate(-1)}>
     back
    </div>
       
  )
}

export default Btnback
