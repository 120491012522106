import React, { useRef, useState, useEffect } from 'react';
import deletecategory from '../../assets/svgs/deletecategory.svg';
import { FaPlus } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCat, deleteCategory, getTotalCategories } from '../../redux/actions/categoryAction';
import { toast } from 'react-toastify';
import { baseURL } from '../../config/api';
import { convertToDataURL } from "../../utils/convertToDataURL";
import { PulseLoader } from 'react-spinners';
import Parentsubcategory from '../subcategory/parentsubcategory';
import useShowSubcategories from '../../hooks/useShowSubcategories';
import Img from '../../assets/png/img2.jpg'



const Addcategory = ({ categories, createLoading, updateLoading,getLoading,error} ) => {
    const dispatch = useDispatch();
    const [visibleSubcategoryId, toggleSubcategoryVisibility] = useShowSubcategories();
    const [editItem, setEditItem] = useState(null);
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (editItem) {
            setName(editItem.name);
            setImage(`${baseURL}/${editItem.image}`);
        } else {
            setName('');
            setImage('');
        }
    }, [editItem]);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const dataURL = await convertToDataURL(file);
                setImage(dataURL);
            } catch (error) {
                console.error("Error converting file to Data URL", error);
            }
        }
    };

    const handleEdit = (category) => {
        setEditItem(category);
    };

    const handleDelete = (id) => {
        dispatch(deleteCategory(id, toast));
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        let data = { name };
        if (editItem) {
            await dispatch(updateCat(editItem._id, data, toast));
        } else {
            if (image) {
                data.image = image;
            }
            await dispatch(createCategory(data, toast));
        }
        setName('');
        setImage('');
        setEditItem(null);
    };





    return (
        <div className='w-full flex flex-col lg:flex-row mt-5 gap-10'>
            <div className="w-full lg:w-2/4 h-[610px] bg-pure shadow-md rounded-[10px] overflow-x-scroll ">
                <div className="font-semibold text-xl px-3 py-3 w-full border-b">
                    Category List
                </div>
                <ul className="py-2 flex flex-col space-y-3">
               {
                
                    categories.map((category) => (
          <li key={category._id} className="flex flex-col space-y-2 border-b">
            <div className="flex flex-row justify-center items-center gap-3 py-3 px-5">
              <div className="w-[10%] flex justify-center">
                <img
                  src={`${baseURL}${category.image}`}
                  alt="Category Icon"
                  className="w-8 h-8 object-cover"
                  onError={(e) => { e.target.onerror = null; e.target.src = Img; }}

                />
              </div>
              <div className="w-4/5 border border-primary h-12 rounded-[10px] relative">
                <input
                  type="text"
                  value={editItem && editItem._id === category._id ? name : category.name}
                  className="w-full h-full rounded-[10px] px-3 outline-none"
                  readOnly={!(editItem && editItem._id === category._id)}
                  onClick={() => handleEdit(category)}
                  onChange={(e) => setName(e.target.value)}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={() => toggleSubcategoryVisibility(category._id)}
                >
                  {visibleSubcategoryId === category._id ? (
                    <IoIosArrowUp className="text-lg" />
                  ) : (
                    <IoIosArrowDown className="text-lg" />
                  )}
                </div>
              </div>
              <div className="w-[10%] flex justify-center cursor-pointer">
                <img
                  src={deletecategory}
                  alt="Delete"
                  className="w-8 h-8 "
                  onClick={() => handleDelete(category._id)}
                />
              </div>
            </div>
            {visibleSubcategoryId === category._id && (
              <div className="w-full mt-2">
                <Parentsubcategory parentId={category._id} />
              </div>
            )}
          </li>
        ))
                

       
     }
    </ul>
            </div>

            <div className='w-full lg:w-2/4 h-[464px] bg-pure shadow-md rounded-[10px]'>
                <div className='font-semibold text-xl px-3 py-3 border-b'>
                    Add Category
                </div>
                <form onSubmit={submitHandler} className='flex flex-col space-y-2 mt-5 px-4'>
                    <h1 className='text-sm pt-4'>Category Name</h1>
                    <input 
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='category 1' 
                        className='w-full outline-none border border-primary rounded-[10px] py-2 px-3'
                    />
                    <div className='flex flex-col space-y-3 items-center py-4'>
                        <p>Category Icon</p>
                        <div>
                            <div 
                                className='w-[100px] h-[100px] flex justify-center items-center border text-5xl rounded-md shadow-md cursor-pointer overflow-hidden'
                                onClick={handleClick}
                            >
                                {image ? (
                                    <img 
                                        src={image} 
                                        alt="Selected Icon" 
                                        className='w-full h-full object-cover rounded-md'
                                    />
                                ) : (
                                    <FaPlus className='text-5xl' />
                                )}
                            </div>
                            <input 
                                type='file' 
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div className='flex justify-center w-full mt-8'>
                        <button 
                            type='submit' 
                            className='btn-primary w-11/12 sm:w-[321px] text-center shadow-md'
                        >
                            {createLoading || updateLoading ? (
                                <PulseLoader size={10} color='#fff' />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Addcategory;
