import { useState } from 'react';

const useToggleOption = () => {
  const [showOption, setShowOption] = useState(null);

  const toggleOption = (index) => {
    setShowOption(showOption === index ? null : index);
  };

  return [showOption, toggleOption];
};

export default useToggleOption;

