import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { addSetting, fetchSetting } from '../../redux/actions/settingAction';

const Flipssetting = ({ userSettings,addUserLoading }) => {


    const [minCampaignBudget, setMinCampaignBudget] = useState(userSettings.minCompaignBudget);
    const [minPayPerClick, setMinPayPerClick] = useState(userSettings.minPayPerClick);
    const [referralCommission, setReferralCommission] = useState(userSettings.referralCommission);
    const [adminCommission,SetAdminCommission]=useState(userSettings.adminCommission);

    const dispatch = useDispatch();

    useEffect(() => {
        setMinCampaignBudget(userSettings.minCompaignBudget || '');
        setMinPayPerClick(userSettings.minPayPerClick || '');
        setReferralCommission(userSettings.referralCommission || '');
        SetAdminCommission(userSettings.adminCommission || '');
    }, [userSettings]);

    const handleInputChange = (e) => setMinCampaignBudget(e.target.value);
    const handleInputShared = (e) => setMinPayPerClick(e.target.value);
    const handleInputReferral = (e) => setReferralCommission(e.target.value);
    const handleInputAdmin=(e)=>SetAdminCommission(e.target.value);

    const handleSubmit = (type, value) => {
        if (!value || isNaN(value)) {
            toast.error('Please enter a valid amount.');
            return;
        }
        dispatch(addSetting({ [type]: parseFloat(value) }, type, toast));
    };

    return (
        <div className='w-full mt-5'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-8'>
                <div className='bg-pure rounded-[10px] shadow-md px-4'>
                    <h1 className='text-xl font-semibold pt-3'>Flip Campaign Budget</h1>
                    <div className='flex flex-col space-y-3 mt-7'>
                        <p className='text-[15px]'>Add Flip Campaign Budget</p>
                        <input
                            type='text'
                            onChange={handleInputChange}
                            value={minCampaignBudget}
                            className='outline-none border border-primary py-2 rounded-md px-2'
                        />
                    </div>
                    <div className='flex justify-center mt-14 pb-6'>
                        <button
                            className='bg-primary text-pure font-medium hover:bg-opacity-85 w-11/12 sm:w-[321px] py-3 rounded-md shadow-md'
                            onClick={() => handleSubmit('minCompaignBudget', minCampaignBudget)}
                        >
                            {addUserLoading === 'minCompaignBudget' ? <PulseLoader size={10} color='#fff' /> : 'Submit'}
                        </button>
                    </div>
                </div>

                <div className='bg-pure rounded-[10px] shadow-md px-4'>
                    <h1 className='text-xl font-semibold pt-3'>Flip Shared Profit</h1>
                    <div className='flex flex-col space-y-3 mt-7'>
                        <p className='text-[15px]'>Add Flip Shared Profit</p>
                        <input
                            type='text'
                            onChange={handleInputShared}
                            value={minPayPerClick}
                            className='outline-none border border-primary py-2 rounded-md px-2'
                        />
                    </div>
                    <div className='flex justify-center mt-14 pb-6'>
                        <button
                            className='bg-primary text-pure font-medium hover:bg-opacity-85 w-11/12 sm:w-[321px] py-3 rounded-md shadow-md'
                            onClick={() => handleSubmit('minPayPerClick', minPayPerClick)}
                        >
                            {addUserLoading === 'minPayPerClick' ? <PulseLoader size={10} color='#fff' /> : 'Submit'}
                        </button>
                    </div>
                </div>

                <div className='bg-pure rounded-[10px] shadow-md px-4'>
                    <h1 className='text-xl font-semibold pt-3'>Flip Referral Budget</h1>
                    <div className='flex flex-col space-y-3 mt-7'>
                        <p className='text-[15px]'>Add Flip Referral Budget</p>
                        <input
                            type='text'
                            onChange={handleInputReferral}
                            value={referralCommission}
                            className='outline-none border border-primary py-2 rounded-md px-2'
                        />
                    </div>
                    <div className='flex justify-center mt-14 pb-6'>
                        <button
                            className='bg-primary text-pure font-medium hover:bg-opacity-85 w-11/12 sm:w-[321px] py-3 rounded-md shadow-md'
                            onClick={() => handleSubmit('referralCommission', referralCommission)}
                        >
                            {addUserLoading === 'referralCommission' ? <PulseLoader size={10} color='#fff' /> : 'Submit'}
                        </button>
                    </div>
                </div>

                <div className='bg-pure rounded-[10px] shadow-md px-4'>
                    <h1 className='text-xl font-semibold pt-3'>Admin Commission</h1>
                    <div className='flex flex-col space-y-3 mt-7'>
                        <p className='text-[15px]'>Add Admin Commission</p>
                        <input
                            type='text'
                            className='outline-none border border-primary py-2 rounded-md px-2'
                            onChange={handleInputAdmin}
                            value={adminCommission}
                        />
                    </div>
                    <div className='flex justify-center mt-14 pb-6'>
                        <button
                            className='bg-primary text-pure font-medium hover:bg-opacity-85 w-11/12 sm:w-[321px] py-3 rounded-md shadow-md'
                            onClick={() => handleSubmit('adminCommission', adminCommission)}
                        >
                        {addUserLoading === 'adminCommission' ? <PulseLoader size={10} color='#fff' /> : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Flipssetting;
