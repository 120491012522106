import Axios from "../../config/api";
import {  setFlipsharesingle,updateFlipsharesingle, setUpdateLoading,setLoading, setError } from '../../redux/reducers/singleflipshareReducer';
export const fetchSingleFlipShare = (id) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.get(`/flip-share/${id}` , {
            headers : {
                Authorization: `Bearer ${token}`,
            }
        }) ;
        dispatch(setFlipsharesingle(doc));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}
export const fetchupdateSingleFlipShare = (id , data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`/flip-share/${id}` , data  , 
        { headers : { Authorization : `Bearer ${token}`} });
        dispatch(updateFlipsharesingle(doc));
        dispatch(setUpdateLoading(false));
        toast.success('Updated  Successfully.');
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update  error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}
