import { createSlice } from '@reduxjs/toolkit';

const SinglewithdrawSlice = createSlice({
    name: 'withdraw',
    initialState: {
        singlewithdraws: {},  
        singleuserwithdraws:[],
         singleloading: false,
         singleuserloading:false,
         updateloading:false,
       
        error: null,
    },
    reducers: {
        setSingleWithdraw(state, action) {
            state.singlewithdraws = action.payload; 
        },
        setSingleUserWithdraws(state, action) {
            state.singleuserwithdraws = action.payload; 
        },
        updateSingleWithdraw(state, action) {
            const { id, updatedData } = action.payload;
            if (state.singlewithdraws[id]) {
                state.singlewithdraws[id] = {
                    ...state.singlewithdraws[id],
                    ...updatedData,
                };
            }
        },
        setUpdateLoading(state, action){
            state.updateloading = action.payload; 
        },
        setSingleLoading(state, action) {
            state.singleloading = action.payload; 
        },
        setSingleUserLoading(state, action) {
            state.singleuserloading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
      
    },
});



export const { setSingleWithdraw, setSingleLoading,setUpdateLoading,updateSingleWithdraw, setError ,setSingleUserWithdraws,setSingleUserLoading} = SinglewithdrawSlice.actions;
export default SinglewithdrawSlice.reducer;