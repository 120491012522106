import React,{useEffect,useState} from 'react'
import Frontbanner from '../../components/banner/frontbanner'
import { useNavigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import {getTotalBanners} from '../../redux/actions/bannerAction'
import { ShimmerCard, ShimmerTitle } from 'react-shimmer-effects'; 
import Layout from '../../components/global/layout';
import CurrentTime from '../../components/global/currenttime';
const Banners = () => {
  const dispatch =useDispatch()
const {banners ,loading,createLoading,deleteLoading,singlebanner}=useSelector((state)=>state.banner)
  const  navigate=useNavigate();
const handletoNaviagte=()=>{
      navigate('/sidebanner');
}
useEffect(()=>{
   dispatch(getTotalBanners())
},[dispatch])


if (loading) {
  return (
    <Layout>
      <div className='min-h-screen h-full px-4 pt-4'>
        <div className='w-full bg-pure px-3 py-6 rounded-[10px] my-5'>
          <ShimmerTitle width='100%' height='40px' className='rounded-[10px] w-full md:w-2/4 lg:w-[330px] my-2' />
        </div>
        
        <div className='flex flex-col space-y-7'>
          <div className='w-full bg-pure flex flex-col space-y-8 mt-5 px-4 py-5 rounded-[10px]'>
            
            <div className='flex flex-col md:flex-row gap-5'>
              <div className='w-full md:w-2/5'>
                <div className='flex flex-col items-center'>
                  <ShimmerTitle width='100%' height='200px' className='rounded-[10px]' />
                </div>
              </div>
              <div className='w-full md:w-3/5 flex flex-col space-y-3'>
                <div className='flex flex-col space-y-2 w-full'>
                  <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                  <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                </div>
                <div className='flex flex-col space-y-2 w-full'>
                  <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                  <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                  <div className='flex flex-col space-y-2 w-full'>
                    <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                    <ShimmerTitle width='100%' height='50px' className='rounded-[10px]' />
                  </div>
                </div>
              </div>
            </div>
            
            <div className='flex flex-col items-center'>
              <ShimmerTitle width='100%' height='40px' className='rounded-[10px]' />
            </div>
          </div>

          <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5'>
            <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2'>
              <div className='flex flex-col space-y-1'>
                <ShimmerTitle width='100%' height='20px' className='rounded-[10px]' />
                <ShimmerTitle width='100%' height='15px' className='rounded-[10px]' />
              </div>
            </div>
            
            <div className='w-full px-6 py-6'>
              <div className='border rounded-[10px] pb-[3px] w-full overflow-auto custom-scrollbar'>
                <table className='table-auto w-full'>
                  <thead className='border-b'>
                    <tr>
                      <th scope='col' className='text-base font-semibold px-6 py-4 text-left border-r'>
                        <ShimmerTitle width='40px' height='20px' className='rounded-[10px]' />
                      </th>
                      <th scope='col' className='text-base font-semibold px-6 py-4 text-left border-r'>
                        <ShimmerTitle width='100px' height='20px' className='rounded-[10px]' />
                      </th>
                      <th scope='col' className='text-base font-semibold px-6 py-4 text-left border-r'>
                        <ShimmerTitle width='80px' height='20px' className='rounded-[10px]' />
                      </th>
                      <th scope='col' className='text-base font-semibold px-6 py-4 text-left border-r'>
                        <ShimmerTitle width='120px' height='20px' className='rounded-[10px]' />
                      </th>
                      <th scope='col' className='text-base font-semibold px-6 py-4 text-center border-r cursor-pointer'>
                        <ShimmerTitle width='80px' height='20px' className='rounded-[10px]' />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle width='40px' height='20px' className='rounded-[10px]' />
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle width='100px' height='20px' className='rounded-[10px]' />
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle width='80px' height='20px' className='rounded-[10px]' />
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle width='120px' height='20px' className='rounded-[10px]' />
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300'>
                        <ShimmerTitle width='80px' height='20px' className='rounded-[10px]' />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

  return (
    <Layout>
         <div className='min-h-screen h-full px-4 pt-4   '>
       <div className='flex flex-col lg:flex-row lg:justify-between  lg:items-center'>
             <div className='flex flex-col py-4'>
             <h1 className='text-xl font-semibold'>Banners</h1>
               <CurrentTime/>          
             </div>
             <div className=' text-sm bg-primary text-pure text-center  py-2 w-[150px] rounded-lg cursor-pointer shadow-md ' onClick={handletoNaviagte}>
             Create Side Banner
               </div>
         </div>
           <Frontbanner banners={banners}  createLoading={createLoading} singlebanner={singlebanner}/>
         </div>
    </Layout>
  )
}
export  default  Banners
