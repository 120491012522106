import React,{useRef} from 'react'
import Printer from '../../assets/svgs/printer.svg';
import Arrow from '../../assets/svgs/longaroow.svg'
import Searchbox from '../../components/global/searchbox'
import arrow from '../../assets/svgs/tablearrow.svg'
import setting from '../../assets/svgs/tablesetting.svg'
import useToggleOption from '../../hooks/usetoggleoption';
import thumb from '../../assets/svgs/icon _thumbs-up_.svg'
import eye from '../../assets/svgs/eye.svg'
import view from '../../assets/svgs/view.svg'
import remove from '../../assets/svgs/delete.svg'
import useClickOutside from '../../utils/clickOutside';
const Flipreferralstable = () => {
    const tableRef = useRef(null);
    const [showOption,toggleOption]=useToggleOption();
    useClickOutside(tableRef, () => toggleOption(false));
  return (
    <div className='w-full bg-pure shadow-md rounded-[10px] pt-2 mt-5' >
    <div className='flex flex-row justify-between items-center px-6 py-3 border-b-2 '>
    <div className='flex flex-col space-y-1'>
    <h1 className='text-primary font-semibold text-lg'>Flip Referral</h1>
    <p className='text-primary text-base'>Details and history</p>
    </div>

    <div className='flex flex-row gap-3 ' >
        <div>

        <img src={Arrow} alt='img'/>
        </div>
        <div>
         <img src={Printer} alt='img'/>
        </div> 
    </div>

    </div>

    <div className=' w-full flex flex-col gap-3 sm:gap-0  sm:flex-row sm:justify-between sm:items-center px-6 py-3'>
    <div className='order-2 sm:order-1'>
        <p className='text-primary'>entries per page</p> 
    </div>
    <div className='order-1 sm:order-2'>
    <Searchbox/>
    </div>

   
              
          
    </div>

      <div className='w-full px-6 py-6  '>
        <div className='border rounded-[10px] pb-[3px]  w-full overflow-auto custom-scrollbar   '>
        <table className="  table-auto  w-full  ">
      <thead className='border-b ' >
          <tr>
              <th scope="col" className="text-base  font-semibold  px-6 py-4 text-left border-r   ">
              Sr
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              User Name
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Total Referral
              </th>
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-left border-r">
              Earning By Referral         
                   </th> 
              <th scope="col" className="text-base font-semibold  px-6 py-4 text-center">
              Action                   
              </th>
          </tr>
      </thead>
      <tbody>
       

        <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">1</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">@m.ahmad</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900  ">
          <div className='border border-primary  flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={()=>toggleOption(1)}>

             <span>
               <img src={setting} alt='img'/>
             </span>
             <span>
             <img src={arrow} alt='img'/>
             </span>
             {
          showOption===1 &&
         <div className='bg-pure  h-auto py-1 px-3 left-[-5px] top-10  text-[15px] font-semibold rounded-[10px] absolute z-50  ' ref={tableRef}>
               <div className='flex flex-col space-y-[6px] w-full '>
                  <div className='flex flex-row items-center text-primary gap-1  border-b px-2 py-1 '>
                  <span><img src={view} alt='img'/></span>
                    <span>view</span>
                  </div>
                  <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1'>
                  <span><img src={eye} alt='img'/></span>  
                    <span>stats</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#00AC69] border-b px-2 py-1'>
                  <span><img src={thumb} alt='img'/></span>  
                  <span>Approve</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#FF1400]  px-2 py-1'>
                     <span><img src={remove} alt='img'/></span>  
                     <span>Delete</span>
                  </div>
               </div>
            </div>
          } 
          </div>
          
        </td>

        </tr>

        <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">1</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">@m.ahmad</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900  ">
          <div className='border border-primary  flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={()=>toggleOption(2)}>

             <span>
               <img src={setting} alt='img'/>
             </span>
             <span>
             <img src={arrow} alt='img'/>
             </span>
             {
          showOption===2 &&
         <div className='bg-pure  h-auto py-1 px-3 left-[-5px] top-10  text-[15px] font-semibold rounded-[10px] absolute z-50  ' ref={tableRef}>
               <div className='flex flex-col space-y-[6px] w-full '>
                  <div className='flex flex-row items-center text-primary gap-1  border-b px-2 py-1 '>
                  <span><img src={view} alt='img'/></span>
                    <span>view</span>
                  </div>
                  <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1'>
                  <span><img src={eye} alt='img'/></span>  
                    <span>stats</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#00AC69] border-b px-2 py-1'>
                  <span><img src={thumb} alt='img'/></span>  
                  <span>Approve</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#FF1400]  px-2 py-1'>
                     <span><img src={remove} alt='img'/></span>  
                     <span>Delete</span>
                  </div>
               </div>
            </div>
          } 
          </div>
          
        </td>
  

        </tr>
        <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">1</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">@m.ahmad</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900  ">
          <div className='border border-primary  flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={()=>toggleOption(3)}>

             <span>
               <img src={setting} alt='img'/>
             </span>
             <span>
             <img src={arrow} alt='img'/>
             </span>
             {
          showOption===3 &&
         <div className='bg-pure  h-auto py-1 px-3 left-[-5px] top-10  text-[15px] font-semibold rounded-[10px] absolute z-50  ' ref={tableRef}>
               <div className='flex flex-col space-y-[6px] w-full '>
                  <div className='flex flex-row items-center text-primary gap-1  border-b px-2 py-1 '>
                  <span><img src={view} alt='img'/></span>
                    <span>view</span>
                  </div>
                  <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1'>
                  <span><img src={eye} alt='img'/></span>  
                    <span>stats</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#00AC69] border-b px-2 py-1'>
                  <span><img src={thumb} alt='img'/></span>  
                  <span>Approve</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#FF1400]  px-2 py-1'>
                     <span><img src={remove} alt='img'/></span>  
                     <span>Delete</span>
                  </div>
               </div>
            </div>
          } 
          </div>
          
        </td>
      

        </tr>
        <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300 ">1</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">@m.ahmad</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">15</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900  ">
          <div className='border border-primary  flex flex-row justify-between rounded-[10px] py-1 px-1 cursor-pointer relative' onClick={()=>toggleOption(4)}>

             <span>
               <img src={setting} alt='img'/>
             </span>
             <span>
             <img src={arrow} alt='img'/>
             </span>
             {
          showOption===4 &&
         <div className='bg-pure  h-auto py-1 px-3 left-[-5px] top-10  text-[15px] font-semibold rounded-[10px] absolute z-50  ' ref={tableRef}>
               <div className='flex flex-col space-y-[6px] w-full '>
                  <div className='flex flex-row items-center text-primary gap-1  border-b px-2 py-1 '>
                  <span><img src={view} alt='img'/></span>
                    <span>view</span>
                  </div>
                  <div className='flex flex-row items-center text-primary gap-1 border-b px-2 py-1'>
                  <span><img src={eye} alt='img'/></span>  
                    <span>stats</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#00AC69] border-b px-2 py-1'>
                  <span><img src={thumb} alt='img'/></span>  
                  <span>Approve</span>
                  </div>
                  <div className='flex flex-row items-center gap-1 text-[#FF1400]  px-2 py-1'>
                     <span><img src={remove} alt='img'/></span>  
                     <span>Delete</span>
                  </div>
               </div>
            </div>
          } 
          </div>
          
        </td>

        </tr>
      

      </tbody>

      </table>
        </div>
     
      </div>

   

   
  </div>
  )
}

export default Flipreferralstable
